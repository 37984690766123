import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Container from 'react-bootstrap/Container';
import EmptyState from '../../components/EmptyState/EmptyState';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const FourOhFour = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Container className={'text-center'}>
        <EmptyState
          header={t('404.title')}
          message={t('404.body')}
          icon={<FontAwesomeIcon icon={faExclamation} size={'5x'} />}
        />
      </Container>
    </PageWrapper>
  );
};

export default FourOhFour;
