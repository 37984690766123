import { forwardRef } from 'react';
import {
  Button as BootstrapButton,
  ButtonProps as BootstrapButtonProps,
} from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';

export interface ButtonProps extends BootstrapButtonProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'hollow'
    | 'dark'
    | 'danger'
    | 'success'
    | 'outline-primary'
    | 'outline-danger'
    | 'outline-success'
    | 'link'
    // Pass blank string to have no btn-variant class. Only receives the .btn class.
    | '';
  loading?: boolean;
  to?: string;
  // This prop is meant as a stable hook for analytics or other third party scripts to hook into.
  // Because we are using CSS modules, our classnames are generated randomly every build, so
  // we need to provide something that won't change. Should express the semantic meaning of the button.
  // For instance, something like "registration-form-submit".
  semantic: string;
}

const Button = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const { loading, disabled, children, semantic, ...bootstrapButtonProps } =
    props;
  return (
    <BootstrapButton
      ref={ref}
      {...bootstrapButtonProps}
      // disable if in loading state to prevent onClick from firing if
      // we forget the disabled attribute. Especially important if type='submit'
      // in a form.
      disabled={disabled || loading}
      data-semantic={semantic}
    >
      {props.loading ? (
        <Spinner style={{ height: '1em', width: '1em' }} />
      ) : (
        props.children
      )}
    </BootstrapButton>
  );
});

export default Button;
