import { useEffect } from 'react';
import {
  HomeErrorQueryParams,
  HomeErrorOptions,
} from '../config/Enums/QueryParams';
import Routes from '../config/Routes/Routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import { useToasts } from '../components/Toasts/ToastContext';

const useHandleErrorQueryParams = () => {
  const { t } = useTranslation();
  const { showErrorNotification } = useToasts();
  const history = useHistory();
  const [urlQuery] = useQueryParams(HomeErrorQueryParams);

  useEffect(() => {
    const handleErrorParams = () => {
      const { error: errorURLQuery } = urlQuery;

      if (errorURLQuery) {
        history.replace(Routes.home);

        let errorHeading: string = '';
        let errorBody: string = '';

        const errorMatch = Object.values(HomeErrorOptions).find(
          (value) => value.toLowerCase() === errorURLQuery
        );

        switch (errorMatch) {
          case HomeErrorOptions.SESSION_EXPIRED:
            errorHeading = t(
              'app.home-error-notification.session-expired.title'
            );
            errorBody = t('app.home-error-notification.session-expired.body');
            break;
          case HomeErrorOptions.NETWORK_ERROR:
            errorHeading = t('app.home-error-notification.network-error.title');
            errorBody = t('app.home-error-notification.network-error.body');
            break;
          default:
            errorHeading = 'An unexpected error occurred';
            errorBody = 'Please try again later.';
        }

        showErrorNotification({ heading: errorHeading, body: errorBody });
      }
    };

    handleErrorParams();
  }, [t, urlQuery, history, showErrorNotification]);
};

export default useHandleErrorQueryParams;
