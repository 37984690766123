import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import LanguageService, {
  ClientLanguage,
} from '../../services/LanguageService';
import { useFeatureFlagsContext } from '../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../config/FeatureFlags/FeaturesEnum';

export interface LanguageContextInterface {
  hasMultipleLanguageSupport: boolean;
  supportedLanguages: ClientLanguage[];
  initialLanguageSelection: string;
}

const LanguageContext = createContext<
  | [
      LanguageContextInterface,
      Dispatch<SetStateAction<LanguageContextInterface>>
    ]
  | undefined
>(undefined);

export const LanguageContextProvider = ({ children }: any) => {
  const languageContext = useState<LanguageContextInterface>({
    hasMultipleLanguageSupport:
      LanguageService.getSupportedLanguages().length > 1,
    initialLanguageSelection: LanguageService.getInitialLanguage(),
    supportedLanguages: LanguageService.getSupportedLanguages(),
  });

  const [, setLanguageContextState] = languageContext;
  const { checkFeatureFlags } = useFeatureFlagsContext();

  useEffect(() => {
    if (checkFeatureFlags(FeaturesEnum.RemoteLocalizations)) {
      LanguageService.getRemoteSupportedLanguages()
        .then((res) => {
          setLanguageContextState({
            hasMultipleLanguageSupport: res.languages.length > 1,
            supportedLanguages: res.languages,
            initialLanguageSelection: LanguageService.getInitialLanguage(),
          });
        })
        .catch(() => {
          console.log('Error getting supported languages from remote');
        });
    }
  }, [setLanguageContextState, checkFeatureFlags]);

  return (
    <LanguageContext.Provider value={languageContext}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error(
      'useLanguageContext must be used within a <LanguageContextProvider />'
    );
  }

  return context;
};
