import { Fragment } from 'react';
import styles from './NotificationBanner.module.scss';
import { CSSTransition } from 'react-transition-group';
import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button/Button';
import { useNotificationBannerContext } from '../Dashboard/NotificationBannerContext';
import { Link } from 'react-router-dom';

type BannerTypes = 'warning';

type Icons = {
  [key in BannerTypes]: JSX.Element;
};

const icons: Icons = {
  warning: (
    <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />
  ),
};

export interface NotificationBannerProps {
  type: BannerTypes;
  message: string | JSX.Element;
  onClick?: () => void;
  onClose?: () => void;
  href?: string;
  external?: boolean;
}

const NotificationBanner = () => {
  const [bannerInfo, setBannerInfo] = useNotificationBannerContext();

  if (!bannerInfo) {
    return null;
  }

  const Wrapper = ({ children }: { children: JSX.Element }) => {
    if (bannerInfo.onClick) {
      return (
        <Button
          semantic={'notification-banner-action'}
          variant={'hollow'}
          onClick={bannerInfo.onClick}
          className={'p-0 d-flex align-items-center'}
        >
          {children}
        </Button>
      );
    }

    if (bannerInfo.href) {
      if (bannerInfo.external) {
        return (
          <a
            href={bannerInfo.href}
            target={'_blank'}
            rel={'noreferrer'}
            className={'d-flex align-items-center'}
          >
            {children}
          </a>
        );
      } else {
        return (
          <Link
            to={bannerInfo.href}
            className={'d-flex align-items-center'}
            data-semantic={'notification-banner-link'}
          >
            {children}
          </Link>
        );
      }
    }
    return <div className={'d-flex align-items-center'}>{children}</div>;
  };

  return (
    <CSSTransition
      in={!!bannerInfo}
      appear={!!bannerInfo}
      timeout={300}
      unmountOnExit
      classNames={{
        appear: styles.bannerTransitionAppear,
        exitActive: styles.bannerTransitionExit,
      }}
    >
      <div
        className={[
          styles.banner,
          styles[bannerInfo.type],
          bannerInfo.onClick ? styles.clickable : '',
        ].join(' ')}
      >
        <Wrapper>
          <Fragment>
            {icons[bannerInfo.type]}
            <div className={styles.message}>{bannerInfo.message}</div>
          </Fragment>
        </Wrapper>
        <button
          onClick={() => {
            setBannerInfo(null);
            bannerInfo.onClose?.();
          }}
          className={styles.close}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </CSSTransition>
  );
};

export default NotificationBanner;
