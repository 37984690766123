import { ErrorResponse, onError } from '@apollo/client/link/error';
import { setAuthorizationHeaderWithAccessToken } from './ClientUtils';
import {
  addTolocalStorage,
  getFromlocalStorage,
} from '../../utilities/Utilities';
import AuthenticationService, {
  TokenResponse,
} from '../../pages/Authentication/AuthenticationService';
import { StorageKey } from '../Enums/App';
import { FetchResult, NextLink, Observable, Operation } from '@apollo/client';
import { encodeQueryParams } from 'serialize-query-params';
import { HomeErrorOptions, HomeErrorQueryParams } from '../Enums/QueryParams';
import { handleSignOut } from '../../App';
import Routes from '../Routes/Routes';
import { objectToSearchString } from 'use-query-params';

const isTokenExpired = (graphQLErrors: any) =>
  // todo need to figure out if there is a better way for apollo or the backend to handle this
  graphQLErrors.some((error: any) => {
    // If an expired token error comes through gateway, it's part of the extensions object of the GraphQLError type.
    // If an expired token error comes from notifications service (or maybe any service when called outside gateway?)
    // it's returned as just {message: "Token is expired"}

    // Rather than deal with narrowing these types, I'm just declaring as 'any' and making everything conditional, because
    // we're in some non-standard responses.
    const isExpired =
      error?.extensions?.response?.body?.data?.errors?.some?.(
        (error: any) => error?.message === 'Token is expired'
      ) || error?.message === 'Token is expired';

    return isExpired;
  });

export const refreshAccessTokenAndRetryFailedCall = (
  operation: Operation,
  forward: NextLink,
  authToken: TokenResponse
): Observable<FetchResult> | void =>
  new Observable((observer) => {
    AuthenticationService.refreshToken({
      accountId: authToken.accountId,
      refreshToken: authToken.refreshToken.token,
    })
      .then((response) => {
        if (response) {
          addTolocalStorage(StorageKey.AuthToken, response);

          setAuthorizationHeaderWithAccessToken(
            operation,
            response.accessToken
          );
        } else {
          throw new Error('Your session has expired');
        }
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        // Retry last failed request
        forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        if (error?.message === 'Your session has expired') {
          const encodedQuery = encodeQueryParams(HomeErrorQueryParams, {
            error: HomeErrorOptions.SESSION_EXPIRED,
          });
          handleSignOut(`${Routes.home}?${objectToSearchString(encodedQuery)}`);
        } else {
          observer.error(error);
        }
      });
  });

const createOnErrorLink = () =>
  onError(
    ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
      if (graphQLErrors) {
        if (isTokenExpired(graphQLErrors)) {
          const authToken = getFromlocalStorage<TokenResponse>(
            StorageKey.AuthToken
          );

          if (authToken) {
            return refreshAccessTokenAndRetryFailedCall(
              operation,
              forward,
              authToken
            );
          }
        }
      }
    }
  );

export default createOnErrorLink;
