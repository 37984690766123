import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const useScrollToTop = () => {
  const history = useHistory();
  let previousLocation = useRef(history.location.pathname);

  // Scroll to top on pathname. Don't scroll to top if hash changes.
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (previousLocation.current !== location.pathname) {
        previousLocation.current = location.pathname;
        // todo add this back in. We need a way to perhaps pass this into as a context so specific routes can disable this.
        // window.scrollTo(0, 0);
      }
    });

    return unlisten;
  }, [history, previousLocation]);
};

export default useScrollToTop;
