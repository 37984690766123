import styles from './HorizontalNav.module.scss';
import HeaderNav from './HeaderNav/HeaderNav';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

interface Props {
  preventNavigation: boolean;
  UserNavigation: JSX.Element;
  mainLogo: JSX.Element;
  className?: string;
}

const HorizontalNav = (props: Props) => (
  <div className={[styles.navContainer, props.className].join(' ')}>
    <div className={styles.nav}>
      {GlobalClientConfig.nav.orientation === 'horizontal' && (
        <HeaderNav
          preventNavigation={props.preventNavigation}
          logo={props.mainLogo}
        />
      )}
      <div className={'ms-auto'}>{props.UserNavigation}</div>
    </div>
  </div>
);

export default HorizontalNav;
