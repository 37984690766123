import { createContext, useContext, useRef, useState } from 'react';
import { IToastProps } from './Toasts';

export interface IToastContext {
  toasts: IToastProps[];
}

interface IShowNotificationProps {
  heading: string;
  body: string;
  navigation?: {
    label: string;
    url: string;
  };
}
const ToastContext = createContext<
  | {
      state: IToastContext;
      showErrorNotification: (props: IShowNotificationProps) => void;
      showSuccessNotification: (props: IShowNotificationProps) => void;
      removeToast: (toastId: number) => void;
    }
  | undefined
>(undefined);

export const ToastContextProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<IToastProps[]>([]);
  const keyRef = useRef(0);

  const showSuccessNotification = (props: IShowNotificationProps) => {
    const toast: IToastProps = {
      id: keyRef.current,
      type: 'success',
      ...props,
    };

    keyRef.current++;

    setToasts((prevState) => [...prevState, toast]);
  };

  const showErrorNotification = (props: IShowNotificationProps) => {
    const toast: IToastProps = {
      id: keyRef.current,
      type: 'danger',
      ...props,
    };

    keyRef.current++;

    setToasts((prevState) => [...prevState, toast]);
  };

  const removeToast = (toastId: number) => {
    setToasts((prevState) =>
      prevState.filter((prevToast) => prevToast.id !== toastId)
    );
  };

  return (
    <ToastContext.Provider
      value={{
        state: { toasts },
        showSuccessNotification,
        showErrorNotification,
        removeToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToasts = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToasts must be used within a ToastProvider');
  }

  return context;
};

export default ToastContext;
