import { createContext, useContext } from 'react';
import { createContextualCan } from '@casl/react';
import ability from './AbilityBuilder';

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbilityContext = () => {
  const context = useContext(AbilityContext);

  return context;
};

export const AbilityContextProvider = ({ children }: any) => (
  <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
);
