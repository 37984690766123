import { Fragment } from 'react';

import Spinner from 'react-bootstrap/Spinner';

import EmptyState from '../EmptyState/EmptyState';

type Props = {
  loading: boolean;
  error?: {
    heading: string;
    message: string;
    icon?: JSX.Element;
  };
  fallback?: JSX.Element;
  children: JSX.Element | JSX.Element[];
  clearMenu?: boolean;
};

const AsyncView = ({
  loading = true,
  error,
  fallback,
  children,
  clearMenu,
}: Props) => {
  const renderFallback = (): JSX.Element => {
    if (!fallback) {
      return (
        <div
          className={[
            'col mt-4 mb-4 text-center justify-content-center',
            clearMenu ? 'padding-top-menu-height' : null,
          ].join(' ')}
        >
          <Spinner
            as={'span'}
            animation='border'
            role='status'
            aria-hidden='true'
          />
        </div>
      );
    }

    return fallback;
  };

  const render = (): JSX.Element => {
    if (loading) return renderFallback();

    if (error) {
      return (
        <div className={'col mt-4 mb-4 text-center text-danger'}>
          <EmptyState
            header={error.heading}
            message={error.message}
            icon={error.icon}
          />
        </div>
      );
    }

    return <Fragment>{children}</Fragment>;
  };

  return render();
};

export default AsyncView;
