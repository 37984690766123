import Card from 'react-bootstrap/Card';

import ProfileAvatar from '../Avatar/ProfileAvatar';
import { AvatarSizes } from '../Avatar/Avatar';
import DisplayName from '../DisplayName/DisplayName';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import styles from './ProfileCard.module.scss';

type Props = {
  avatarPhoto?: string | null;
  fallbackAvatarIcon: IconDefinition;
  name?: string;
  isVIP?: boolean;
  children: JSX.Element | JSX.Element[];
  headerChildren?: JSX.Element | JSX.Element[] | null;
  footerChildren?: JSX.Element | JSX.Element[] | null;
  className?: string;
};

const ProfileCard = (props: Props) => (
  <Card className={`mb-4 ${props.className || ''}`} data-cy={'profileCard'}>
    {props.headerChildren && <Card.Header>{props.headerChildren}</Card.Header>}
    <Card.Body className={'text-center'}>
      <ProfileAvatar
        size={AvatarSizes.lg}
        photo={props.avatarPhoto}
        fallbackIcon={props.fallbackAvatarIcon}
        className={['d-md-none', 'mx-auto', styles.ProfileCardAvatarSm].join(
          ' '
        )}
      />
      <ProfileAvatar
        size={AvatarSizes.xl}
        photo={props.avatarPhoto}
        fallbackIcon={props.fallbackAvatarIcon}
        className={['mx-auto', styles.ProfileCardAvatarLg].join(' ')}
      />
      <div className={'mt-3'}>
        <h1 className={'h2'}>
          <DisplayName name={props.name ?? ''} isVIP={props.isVIP ?? false} />
        </h1>
        {props.children}
      </div>
    </Card.Body>
    {props.footerChildren && <Card.Footer>{props.footerChildren}</Card.Footer>}
  </Card>
);

export default ProfileCard;
