import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';
import { QueryParamProvider } from 'use-query-params';

import { BrowserRouter } from 'react-router-dom';
import initi18n from './config/Internationalization/i18n';

import { intercomInit } from './config/Intercom/intercomConfig';

import './scss/theme.scss';
import 'core-js/stable';
import App from './App';
import createClient from './config/Client/Client';

import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import initCookieBanner from './config/GDPRCookieBanner/GDPRCookieBannerConfig';
import envVariables from './config/EnvVariables/EnvVariables';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ToastContextProvider } from './components/Toasts/ToastContext';
import { AuthContextProvider } from './pages/Authentication/AuthContext';
import { TwitchAuthContextProvider } from './pages/WatchStreams/TwitchAuthContext';
import { NotificationsContextProvider } from './components/Notifications/NotificationsContext';
import { AbilityContextProvider } from './config/Casl/Can';
import { NotificationBannerContextProvider } from './components/Dashboard/NotificationBannerContext';
import featureFlagConfigService from './services/FeatureFlagConfigService/FeatureFlagConfigService';
import { FeatureFlagsContextProvider } from './config/FeatureFlags/FeatureFlagsContext';
import { PostHogProvider } from 'posthog-js/react';
import { ThemeProvider } from './hooks/useTheme';

const posthogOptions = {
  api_host: 'https://app.posthog.com',
};

if (envVariables.gtmId) {
  TagManager.initialize({ gtmId: `GTM-${envVariables.gtmId}` });
}

const client = createClient();

intercomInit();
initCookieBanner();

// if there is a cookie named "PassedAuthorization" set, read in the value and print it to console
const passedAuthorization = document.cookie
  .split('; ')
  .find((row) => row.startsWith('PassedAuthorization='))
  ?.split('=')[1];

if (passedAuthorization) {
  console.log(`PassedAuthorization Cookie: ${passedAuthorization}`);
} else {
  console.log('PassedAuthorization Cookie not found');
}

featureFlagConfigService.init(client).then((featureFlagConfig) =>
  initi18n(import.meta.env.VITE_APP_CLIENT_NAME ?? 'default').then(() => {
    ReactDOM.render(
      <ThemeProvider>
        <FeatureFlagsContextProvider
          value={{ featureFlags: featureFlagConfig }}
        >
          <PostHogProvider
            apiKey='phc_azbAuxj7nnnQ4TqJ2BIfLyJmyvmyVFK06dXS86pwJOp'
            options={posthogOptions}
          >
            <HelmetProvider>
              <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <ApolloProvider client={client}>
                    <AuthContextProvider>
                      <TwitchAuthContextProvider>
                        <NotificationsContextProvider>
                          <AbilityContextProvider>
                            <NotificationBannerContextProvider>
                              <ToastContextProvider>
                                <App />
                              </ToastContextProvider>
                            </NotificationBannerContextProvider>
                          </AbilityContextProvider>
                        </NotificationsContextProvider>
                      </TwitchAuthContextProvider>
                    </AuthContextProvider>
                  </ApolloProvider>
                </QueryParamProvider>
              </BrowserRouter>
            </HelmetProvider>
          </PostHogProvider>
        </FeatureFlagsContextProvider>
      </ThemeProvider>,
      document.getElementById('app')
    );
  })
);
