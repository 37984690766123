import { useEffect } from 'react';
import { useAuthContext } from '../pages/Authentication/AuthContext';
import { State as AppState } from 'src/App';
import { intercomBoot } from 'src/config/Intercom/intercomConfig';
import envVariables from '../config/EnvVariables/EnvVariables';
import i18next from 'i18next';

const useIntercomBoot = (appState: AppState) => {
  const [authContextState] = useAuthContext();

  useEffect(() => {
    const APP_ID = envVariables.intercomId;
    const languageDir = i18next.dir();
    const alignment = languageDir === 'ltr' ? 'right' : 'left';

    if (APP_ID) {
      if (appState.email && authContextState.userId) {
        intercomBoot(
          APP_ID,
          alignment,
          appState.email,
          authContextState.userId
        );
      } else {
        intercomBoot(APP_ID, alignment);
      }
    }
  }, [appState.email, authContextState.userId]);
};

export default useIntercomBoot;
