import { Fragment, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PublicEventParticipationFragment } from 'src/generated/graphql';
import DataTable from 'src/components/DataTable/DataTable';
import {
  formatNumberForLocale,
  formatPlacement,
  formatToLocalTimezone,
} from 'src/utilities/Utilities';
import { isTypename } from 'src/utilities/TSUtilities';
import Routes from 'src/config/Routes/Routes';
import EmptyState from '../EmptyState/EmptyState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from 'HiveClient/components/Icons/Icons';
import LanguageService from '../../services/LanguageService';

type Props = {
  events: PublicEventParticipationFragment[];
  accountId?: string;
};

const EventHistory = (props: Props) => {
  const { t } = useTranslation();
  // fake pagination since this list is overwhelming for the user profile
  const [paginationOffset, setPaginationOffset] = useState(0);
  const totalEventsCount = props.events.length;
  const paginationLimit = 5;

  return (
    <Fragment>
      <h3 className={'my-3'}>{t('event-history.title')}</h3>
      {props.events.length > 0 ? (
        <DataTable
          handlePageChange={
            totalEventsCount > paginationLimit ? setPaginationOffset : undefined
          }
          paginationData={
            totalEventsCount > paginationLimit
              ? {
                  limit: paginationLimit,
                  totalCount: totalEventsCount,
                  offset: paginationOffset,
                }
              : undefined
          }
          columns={[
            t('event-history.column-title.name'),
            t('event-history.column-title.game'),
            t('event-history.column-title.competition'),
            t('event-history.column-title.start-date'),
            t('event-history.column-title.end-date'),
            t('event-history.column-title.placement'),
            t('event-history.column-title.prize'),
          ]}
          data={props.events.slice(
            paginationOffset,
            paginationOffset + paginationLimit
          )}
          renderRow={(eventParticipation) => {
            // use accountId find the team event participant (can be team id or team member id)
            const eventParticipant = eventParticipation.participants.find(
              (participant) =>
                (isTypename('TeamParticipant', participant) &&
                  (participant.teamId === props.accountId ||
                    participant.teamMembers.some(
                      (teamMember) => teamMember.person?.id === props.accountId
                    ))) ||
                ((isTypename('ReadyIndividualParticipant', participant) ||
                  isTypename('UnreadyIndividualParticipant', participant)) &&
                  participant.person?.id === props.accountId)
            );

            let eventPlacementDisplay: string | undefined = undefined;
            let eventPrizeDisplay: string | undefined = undefined;

            if (
              eventParticipant &&
              (isTypename('TeamParticipant', eventParticipant) ||
                isTypename('ReadyIndividualParticipant', eventParticipant) ||
                isTypename('UnreadyIndividualParticipant', eventParticipant))
            ) {
              if (typeof eventParticipant.result?.placement === 'number') {
                eventPlacementDisplay = formatPlacement(
                  eventParticipant.result.placement
                );
              }

              const prizeTier =
                eventParticipation.eventCurrencyExchanges.prizeList?.prizeTiers.find(
                  (prizeTier) =>
                    (isTypename('TeamParticipant', eventParticipant) &&
                      eventParticipant.teamMembers.find(
                        (individualParticipant) =>
                          individualParticipant.result?.prizePayoutReceipt
                            ?.prizeTierId === prizeTier.id
                      )) ||
                    ((isTypename(
                      'ReadyIndividualParticipant',
                      eventParticipant
                    ) ||
                      isTypename(
                        'UnreadyIndividualParticipant',
                        eventParticipant
                      )) &&
                      eventParticipant.result?.prizePayoutReceipt
                        ?.prizeTierId === prizeTier.id)
                );

              if (prizeTier) {
                if (isTypename('SwarmioCurrencyPayoutPrize', prizeTier.prize)) {
                  eventPrizeDisplay = formatNumberForLocale(
                    prizeTier.prize.amount
                  );
                } else {
                  eventPrizeDisplay = t('event-history.see-event-details');
                }
              }
            }

            // If there's no end date, display "TBA", otherwise just show dashes... in case of cancelled events and edge cases.
            if (!eventPlacementDisplay) {
              eventPlacementDisplay = eventParticipation.eventTiming.completedAt
                ? '--'
                : t('event-history.tba');
            }
            if (!eventPrizeDisplay) {
              eventPrizeDisplay = eventParticipation.eventTiming.completedAt
                ? '--'
                : t('event-history.tba');
            }

            return (
              <tr key={`${eventParticipation.id}`}>
                <td>
                  <Link
                    data-semantic={'event-history-link'}
                    to={generatePath(Routes.eventOverview, {
                      id: eventParticipation.id,
                    })}
                  >
                    {LanguageService.getLocaleObject(
                      eventParticipation.localizedNames
                    )}
                  </Link>
                </td>
                <td>{eventParticipation.game.prettyName}</td>
                <td>{eventParticipation.category}</td>
                <td>
                  {formatToLocalTimezone(
                    eventParticipation.eventTiming.startTime,
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }
                  )}
                </td>
                <td>
                  {eventParticipation.eventTiming.completedAt
                    ? formatToLocalTimezone(
                        eventParticipation.eventTiming.completedAt,
                        {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }
                      )
                    : t('event-history.tba')}
                </td>
                <td>{eventPlacementDisplay}</td>
                <td>{eventPrizeDisplay}</td>
              </tr>
            );
          }}
        />
      ) : (
        <div className={'text-center my-3'}>
          <EmptyState
            icon={
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ fontSize: '5em' }}
              />
            }
            header={t('event-history.no-events.title')}
            message={t('event-history.no-events.body')}
          />
        </div>
      )}
    </Fragment>
  );
};

export default EventHistory;
