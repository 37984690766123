import { Fragment } from 'react';

type Props = {
  header: string;
  message?: string | JSX.Element;
  icon?: JSX.Element;
  callToAction?: JSX.Element;
};

const EmptyState = (props: Props) => (
  <Fragment>
    {props.icon ? props.icon : null}
    <h5 className={'mt-4 mb-2'}>{props.header}</h5>
    <p className={'mb-4'}>{props.message}</p>
    {props.callToAction ? props.callToAction : null}
  </Fragment>
);

export default EmptyState;
