import Container from 'react-bootstrap/Container';

import Nav from '../Nav/Nav';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { LanguageContextProvider } from '../LanguageMenu/LanguageContext';
import { ToastContainer } from '../Toasts/Toasts';

type Props = {
  children: JSX.Element;
  handleSignOut: () => void;
  preventNavigation: boolean;
};

const Dashboard = (props: Props): JSX.Element => (
  <section className={'d-flex flex-column min-vh-100'}>
    {!props.preventNavigation && <NotificationBanner />}
    <LanguageContextProvider>
      <Nav
        handleSignOut={props.handleSignOut}
        preventNavigation={props.preventNavigation}
      />
    </LanguageContextProvider>
    {/** Main page content */}
    <Container
      fluid
      className={'px-0 d-flex flex-column flex-grow-1'}
      as={'main'}
    >
      {props.children}
    </Container>
    <ToastContainer />
  </section>
);

export default Dashboard;
