import { Spinner as BootstrapSpinner, SpinnerProps } from 'react-bootstrap';

const Spinner = ({
  size = 'sm',
}: Omit<SpinnerProps, 'animation' | 'variant'>) => (
  <BootstrapSpinner
    as={'span'}
    animation='border'
    size={size}
    role='status'
    aria-hidden='true'
  />
);

export default Spinner;
