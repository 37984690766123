import { RefObject } from 'react';
import styles from './NotificationsToggle.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from 'HiveClient/components/Icons/Icons';
import { AccountNotificationFragment } from '../../../../generated/graphql';
import Button from '../../../Button/Button';
import useNotificationsToggle from '../../../../hooks/useNotificationsToggle';

interface Props {
  userId: string;
  notifToggleRef: RefObject<HTMLButtonElement>;
  toggleNotificationsMenu: () => void;
  setPopupNotification: (
    notification: AccountNotificationFragment | null
  ) => void;
}

const NotificationsToggle = (props: Props) => {
  const { unreadNotifications, markNotificationsAsRead } =
    useNotificationsToggle({ userId: props.userId });

  return (
    <Button
      semantic={'notifications-toggle'}
      variant={'hollow'}
      className={styles.notificationToggle}
      ref={props.notifToggleRef}
      onClick={() => {
        props.toggleNotificationsMenu();
        props.setPopupNotification(null);
        markNotificationsAsRead();
      }}
      data-testid={'notifications-dropdown-toggle'}
    >
      <FontAwesomeIcon icon={faBell} className={styles.menuIcon} />
      {Boolean(unreadNotifications.length) && (
        <span className={styles.unreadNotification} />
      )}
    </Button>
  );
};

export default NotificationsToggle;
