import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';

import NavDropdown from 'react-bootstrap/NavDropdown';
import { AccountNotificationFragment } from '../../generated/graphql';
import useGetMinimalUserQuery from '../../hooks/useGetMinimalUserQuery';
import { formatDateFromNow } from '../../utilities/Utilities';
import { getNotificationDisplay } from './NotificationHelpers';
import useNotifications from '../../hooks/useNotifications';
import Notification from './Notification/Notification';

interface Props {
  onClick: () => void;
  setShowNotifications: () => void;
  setPopupNotification: (
    notification: AccountNotificationFragment | null
  ) => void;
  show: boolean;
  popupNotification: AccountNotificationFragment | null;
}

const Notifications = (props: Props) => {
  const { t } = useTranslation();
  const getUserQuery = useGetMinimalUserQuery();

  const { unexpiredNotifications } = useNotifications({
    newSubscriptionCallback: (notification) => {
      props.setPopupNotification(notification);
      props.setShowNotifications();
    },
  });

  const notificationListDisplay = () =>
    unexpiredNotifications.length ? (
      unexpiredNotifications.map((notification) => {
        const notificationDetails = getNotificationDisplay({
          notification,
          t,
          userData: getUserQuery.data,
        });

        if (!notificationDetails) {
          return null;
        }

        return (
          <NavDropdown.Item
            key={notification.id}
            onClick={props.onClick}
            className={'w-100'}
            as={'div'}
          >
            <Notification
              key={notification.id}
              unread={!notification.read}
              hidePopupNotification={() => props.setPopupNotification(null)}
              timestamp={formatDateFromNow(notification.created)}
              tabIndex={props.show ? 0 : -1}
              {...notificationDetails}
            />
          </NavDropdown.Item>
        );
      })
    ) : (
      <div className={'d-flex justify-content-center w-100'}>
        <p className={['mt-3 mb-0'].join(' ')}>
          {t('dashboard.notifications.no-notifications')}
        </p>
      </div>
    );

  const renderNotifications = () => {
    if (props.popupNotification) {
      const notificationDetails = getNotificationDisplay({
        notification: props.popupNotification,
        t,
        userData: getUserQuery.data,
      });

      if (!notificationDetails) {
        return null;
      }

      return (
        <NavDropdown.Item as={'div'} style={{ width: '100%' }}>
          <Notification
            key={props.popupNotification.id}
            timestamp={formatDateFromNow(props.popupNotification.created)}
            unread={!props.popupNotification.read}
            hidePopupNotification={() => props.setPopupNotification(null)}
            tabIndex={0}
            {...notificationDetails}
          />
        </NavDropdown.Item>
      );
    } else {
      return notificationListDisplay();
    }
  };

  return <Nav data-testid={'notifications'}>{renderNotifications()}</Nav>;
};

export default Notifications;
