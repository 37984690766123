/**
 * A level of indirection is required to prevent terser-webpack-plugin from
 * evaluating our env variables at compile time and stripping the conditionals
 * that check if the env variables exist.
 *
 * Because we use placeholder values that later get replaced in the compiled files,
 * we need terser to keep the conditional in place in case the placeholder gets replaced
 * with an empty value.
 *
 * This isn't documented, exporting the env variables as an object seems to prevent terser
 * from being able to run dead code elimination on these.
 */
const EnvVariables = {
  gtmId: import.meta.env.VITE_APP_GTM,
  logTracker: import.meta.env.VITE_APP_LOG_TRACKER,
  intercomId: import.meta.env.VITE_APP_INTERCOM_APPID,
  loadLocalizationsRemotelyOverride: import.meta.env
    .VITE_APP_LOAD_LOCALIZATIONS_REMOTELY_OVERRIDE
    ? import.meta.env.VITE_APP_LOAD_LOCALIZATIONS_REMOTELY_OVERRIDE.toLowerCase() ===
      'true'
    : false,
  showSubscriptions: import.meta.env.VITE_APP_SHOW_SUBSCRIPTIONS
    ? import.meta.env.VITE_APP_SHOW_SUBSCRIPTIONS.toLowerCase() === 'true'
    : false,
  cdnServerUrl: import.meta.env.VITE_APP_GATEWAY_CDN_URL,
  storePartnerId: import.meta.env.VITE_APP_STORE_PARTNER_ID,
  applicationConfigName: import.meta.env.VITE_APP_APPLICATION_CONFIG_NAME,
};

export default EnvVariables;
