import { Link } from 'react-router-dom';

import styles from '../Notifications.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  icon: IconProp;
  label: string;
  timestamp: string;
  link: string;
  unread: boolean;
  hidePopupNotification: () => void;
  tabIndex?: number;
};

const Notification = (props: Props) => (
  <Link
    data-semantic={'navbar-notification-link'}
    tabIndex={props.tabIndex}
    to={props.link}
    onClick={() => {
      props.hidePopupNotification();
    }}
    className={['py-1', styles.notificationDropdownItem].join(' ')}
    data-testid={'notification-item'}
  >
    <span className={styles.notificationDropdownItemContent}>
      <span className={'ms-1 me-4 position-relative'}>
        <FontAwesomeIcon icon={props.icon} />
        <span className={props.unread ? styles.unreadNotification : ''} />
      </span>
      {props.label}
    </span>
    <small>{props.timestamp}</small>
  </Link>
);

export default Notification;
