export const isTypename = <T extends string, N extends { __typename: string }>(
  typename: T,
  node: N
): node is Extract<N, { __typename: T }> => node.__typename === typename;

export const isMultipleTypename = <
  T extends string,
  N extends { __typename: string }
>(
  typename: T[],
  node: N
): node is Extract<N, { __typename: T }> =>
  typename.includes(node.__typename as T);

export const isTypenameCallback =
  <T extends string, N extends { __typename: string }>(typename: T) =>
  (node: N): node is Extract<N, { __typename: T }> =>
    node.__typename === typename;

export const isMultipleTypenameCallback =
  <T extends string, N extends { __typename: string }>(typename: T[]) =>
  (node: N): node is Extract<N, { __typename: T }> =>
    typename.includes(node.__typename as T);

/**
 * Sets all properties and nested properties as optional.
 *
 */
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
