import { useTranslation } from 'react-i18next';

export function StoreItemDetailDescription({
  description,
}: {
  description: string;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation('store');
  const displayDescription =
    description.length <= 1
      ? 'This store item does not have a description set. It would be great if it did, but you know... it does not at this moment. This store item does not have a description set. It would be great if it did, but you know... it does not at this moment.'
      : description;
  return (
    <div className={['text-dark tw-text-sm text-left'].join(' ')}>
      <span title='description'>{displayDescription}</span>
    </div>
  );
}
