import { Link } from 'react-router-dom';
import Button from 'src/components/Button/Button';
import styles from './ImageTile.module.scss';
import { Fragment, ReactNode } from 'react';

export interface Props {
  label?: ReactNode | null;
  labelIcon?: JSX.Element | null;
  image: JSX.Element;
  id: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: (id: string) => void;
  className?: string;
  semantic: string;
  fullWidth?: boolean;
  responsive?: boolean;
  href?: string;
  'data-cy'?: string;
}

interface IButtonImageTileWrapperProps {
  id: string;
  onClick: (id: string) => void;
  children: JSX.Element;
  className: string;
  semantic: string;
  'data-cy'?: string;
}

const ButtonImageTile = (props: IButtonImageTileWrapperProps) => (
  <Button
    semantic={`image-tile-${props.semantic}`}
    variant={'hollow'}
    className={['d-block p-0', props.className].join(' ')}
    onClick={() => props.onClick(props.id)}
    data-cy={props['data-cy']}
  >
    {props.children}
  </Button>
);

interface IDivImageTileWrapperProps {
  children: JSX.Element;
  className: string;
}

const DivImageTileWrapper = (props: IDivImageTileWrapperProps) => (
  <div className={props.className}>{props.children}</div>
);

interface ILinkImageTileWrapperProps {
  href: string;
  children: JSX.Element;
  className: string;
  semantic: string;
}

const LinkImageTileWrapper = (props: ILinkImageTileWrapperProps) => (
  <Link
    to={props.href}
    className={['d-block', props.className].join(' ')}
    data-semantic={`image-tile-${props.semantic}-link`}
  >
    {props.children}
  </Link>
);

interface IImageTileContent {
  label?: ReactNode | null;
  labelIcon?: JSX.Element | null;
  image: JSX.Element;
  responsive: boolean;
}

const ImageTileContentWrapper = (props: IImageTileContent) => (
  <div
    className={
      props.responsive
        ? 'max-sm:tw-flex max-sm:tw-flex-row max-sm:tw-w-full max-sm:tw-gap-x-2'
        : ''
    }
  >
    <div
      className={[
        props.label ? styles.imageWithLabel : styles.imageWithoutLabel,
        ...(props.responsive ? ['max-sm:tw-min-w-[4rem]'] : []),
      ].join(' ')}
    >
      {props.image}
    </div>
    {props.label && (
      <div
        className={[
          styles.label,
          ...(props.responsive
            ? ['max-sm:tw-max-h-[4rem] max-sm:tw-mr-9']
            : []),
        ].join(' ')}
      >
        {props.labelIcon} {props.label}
      </div>
    )}
  </div>
);

const ImageTile = ({ responsive = false, ...props }: Props) => {
  const Content = () => (
    <ImageTileContentWrapper
      image={props.image}
      label={props.label}
      labelIcon={props.labelIcon}
      responsive={responsive}
    />
  );

  const className = [
    styles.imageTile,
    props.className ?? '',
    props.fullWidth ? styles.fullWidth : '',
    props.isSelected ? styles.isSelected : '',
    props.isDisabled ? styles.isDisabled : '',
    'bg-dark',
    ...(responsive ? ['max-sm:tw-w-full'] : []),
  ].join(' ');

  return (
    <Fragment>
      {/* We wrap in either a button, Link, or div, depending if an onClick or href was passed in. */}
      {props.onClick && !props.isDisabled && (
        <ButtonImageTile
          className={className}
          id={props.id}
          onClick={props.onClick}
          data-cy={props['data-cy']}
          semantic={props.semantic}
        >
          <Content />
        </ButtonImageTile>
      )}
      {props.href && !props.isDisabled && (
        <LinkImageTileWrapper
          href={props.href}
          className={className}
          semantic={props.semantic}
        >
          <Content />
        </LinkImageTileWrapper>
      )}
      {((!props.onClick && !props.href) || props.isDisabled) && (
        <DivImageTileWrapper className={className}>
          <Content />
        </DivImageTileWrapper>
      )}
    </Fragment>
  );
};

export default ImageTile;
