import { ComponentProps, forwardRef } from 'react';
import {
  FloatingLabel,
  Form,
  FormControl as BootstrapFormControl,
} from 'react-bootstrap';

interface Props extends ComponentProps<typeof BootstrapFormControl> {
  feedback?: typeof Form.Control.Feedback;
  labelClassName?: string;
  placeholder?: string;
}

const Input = forwardRef((props: Props, ref) => {
  const { feedback, label, labelClassName, placeholder, ...controlProps } =
    props;

  return (
    <FloatingLabel
      className={'w-100'}
      label={
        <>
          {label}
          {placeholder && (
            <span className='tw-opacity-60'> ({placeholder})</span>
          )}
        </>
      }
      controlId={props.name}
    >
      <Form.Control {...controlProps} placeholder={' '} ref={ref} />
      {feedback ? feedback : null}
    </FloatingLabel>
  );
});

export default Input;
