import { ApolloLink, split } from '@apollo/client';
import { getFromlocalStorage } from '../../utilities/Utilities';
import { TokenResponse } from '../../pages/Authentication/AuthenticationService';
import { StorageKey } from '../Enums/App';
import {
  AUTHORIZATION_HEADER,
  setAuthorizationHeaderWithAccessToken,
} from './ClientUtils';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SUBSCRIPTIONS_SERVICE_URL } from '../Endpoints/Endpoints';
import posthog from 'posthog-js';

interface ConnectionParams {
  [AUTHORIZATION_HEADER]: string | null;
}

const createWebsocketLink = () =>
  new WebSocketLink({
    uri: SUBSCRIPTIONS_SERVICE_URL,
    options: {
      reconnect: true,
      connectionParams: () => {
        const authToken = getFromlocalStorage<TokenResponse>(
          StorageKey.AuthToken
        );

        let connectionParams: ConnectionParams = {
          [AUTHORIZATION_HEADER]: null,
        };

        if (authToken) {
          connectionParams[
            AUTHORIZATION_HEADER
          ] = `Bearer ${authToken.accessToken}`;
          return connectionParams;
        }

        return connectionParams;
      },
    },
  });

const createApolloLink = () =>
  new ApolloLink((operation, forward) => {
    const authToken = getFromlocalStorage<TokenResponse>(StorageKey.AuthToken);
    setAuthorizationHeaderWithAccessToken(operation, authToken?.accessToken);

    // Add PostHog ID to all requests
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-posthog-id': posthog.get_distinct_id(),
      },
    }));

    return forward(operation);
  });

const createLink = () =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    createWebsocketLink(),
    createApolloLink()
  );

export default createLink;
