import { Fragment } from 'react';

import Logo from 'HiveClient/images/logo.png';
import LogoIcon from 'HiveClient/images/logo-icon.png';

export interface Props {
  atPageTop: boolean;
}

const NavLogo = (props: Props) => (
  <Fragment>
    <img
      src={LogoIcon}
      alt={'hive logo'}
      className={[
        'd-lg-none',
        'ms-2',
        'tw-max-w-[100px] tw-max-h-[100px]',
      ].join(' ')}
    />
    <span className={'d-block me-3'}>
      <img
        src={Logo}
        alt={'hive logo'}
        className={[
          'd-none',
          'd-lg-inline-block',
          'ms-2',
          'tw-max-w-[100px] tw-max-h-[100px]',
        ].join(' ')}
      />
    </span>
  </Fragment>
);

export default NavLogo;
