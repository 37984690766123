import { Fragment, ElementType } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TitleWithIcon.module.scss';

interface Props {
  icon: IconProp;
  iconSize?: '1rem' | '1.5rem' | '2rem';
  title: string;
  className?: string;
  as?: ElementType;
}
const TitleWithIcon = ({
  icon,
  iconSize,
  title,
  className,
  as = 'h1',
}: Props) => {
  const Component = as;
  return (
    <Fragment>
      <Component className={[styles.container, className].join(' ')}>
        <FontAwesomeIcon
          style={iconSize ? { fontSize: iconSize } : {}}
          className={styles.icon}
          icon={icon}
        />
        {title}
      </Component>
    </Fragment>
  );
};

export default TitleWithIcon;
