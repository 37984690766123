import { Fragment, useRef, useState } from 'react';
import styles from './MobileNav.module.scss';
import { NavLink } from 'react-router-dom';
import Routes from '../../../config/Routes/Routes';
import LogoIcon from 'HiveClient/images/logo-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faLanguage,
  faSignOutAlt,
  faUser,
} from 'HiveClient/components/Icons/Icons';
import ProfileAvatar from '../../Avatar/ProfileAvatar';
import { AvatarSizes } from '../../Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../../../utilities/useOnClickOutside';
import Notifications from '../../Notifications/Notifications';
import Nav from 'react-bootstrap/Nav';
import { AccountNotificationFragment } from '../../../generated/graphql';
import Spinner from 'react-bootstrap/Spinner';
import useGetMinimalUserQuery from '../../../hooks/useGetMinimalUserQuery';
import Button from '../../Button/Button';
import LanguageMenu from '../../LanguageMenu/LanguageMenu';
import { useAuthContext } from '../../../pages/Authentication/AuthContext';
import useGetNavLinks from '../useGetNavLinks';
import NotificationsToggle from '../../Dashboard/UserNavigation/NotificationsToggle/NotificationsToggle';
import { useLanguageContext } from '../../LanguageMenu/LanguageContext';
import useGetUserMenuLinks from '../../Dashboard/UserNavigation/UserMenu/useGetUserMenuLinks';
import { useFeatureFlagsContext } from '../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../config/FeatureFlags/FeaturesEnum';

interface Props {
  preventNavigation: boolean;
  logoAlt: string;
  handleSignOut: () => void;
}

const MobileNav = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [authContextState] = useAuthContext();
  const [languageContextState] = useLanguageContext();

  const [popupNotification, setPopupNotification] =
    useState<AccountNotificationFragment | null>(null);

  const notifToggleRef = useRef<HTMLButtonElement>(null);
  const notifRef = useRef<HTMLDivElement>(null);
  const userDropdownToggleRef = useRef<HTMLSpanElement>(null);
  const userDropdownRef = useRef<HTMLDivElement>(null);
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showNestedMenu, setShowNestedMenu] = useState<boolean>(false);
  const navLinks = useGetNavLinks();
  const userMenuNavLinks = useGetUserMenuLinks();

  const { checkFeatureFlags } = useFeatureFlagsContext();

  const dismissMenu = () => {
    setShowNotifications(false);
    setShowUserDropdown(false);
  };

  useOnClickOutside([notifRef, notifToggleRef], () => {
    setShowNotifications(false);
  });

  useOnClickOutside([userDropdownRef, userDropdownToggleRef], () => {
    setShowUserDropdown(false);
  });

  const userQuery = useGetMinimalUserQuery();

  const renderAuthenticatedMenu = () =>
    showNestedMenu ? (
      <Fragment>
        <Button
          semantic={'language-menu-back'}
          variant={'hollow'}
          className={'d-flex lh-1 ps-0'}
          onClick={() => setShowNestedMenu(false)}
        >
          <span className={'me-2'}>
            <FontAwesomeIcon icon={faChevronLeft} className={'mb-3'} />
          </span>
          <span>{t('dashboard.back.label')}</span>
        </Button>
        <LanguageMenu />
      </Fragment>
    ) : (
      <Fragment>
        <div className={styles.separator} />
        {!props.preventNavigation &&
          userMenuNavLinks.map((navLink) => (
            <NavLink
              key={navLink.title}
              onClick={dismissMenu}
              to={navLink.url}
              className={[styles.dropdownItem, 'btn btn-secondary'].join(' ')}
              data-semantic={`navbar-mobile-${navLink.semantic}-link`}
            >
              <FontAwesomeIcon icon={navLink.icon} className={'me-2 ms-1'} />
              <span>{navLink.title}</span>
            </NavLink>
          ))}

        {languageContextState.hasMultipleLanguageSupport && (
          <Button
            semantic={'language-menu-toggle'}
            onClick={() => setShowNestedMenu(true)}
            variant={'secondary'}
            className={[styles.dropdownItem, 'justify-content-between'].join(
              ' '
            )}
          >
            <span>
              <FontAwesomeIcon icon={faLanguage} className={'me-2'} />
              <span>{t('dashboard.nav-links.language')}</span>
            </span>
            <span>
              <FontAwesomeIcon
                className={'align-self-end'}
                icon={faChevronRight}
              />
            </span>
          </Button>
        )}

        <Button
          semantic={'sign-out'}
          onClick={() => props.handleSignOut()}
          className={[styles.dropdownItem, 'mb-1'].join(' ')}
        >
          <FontAwesomeIcon icon={faSignOutAlt} className={'me-2'} />
          {t('authenticated-nav.log-out')}
        </Button>
      </Fragment>
    );

  const renderUnauthenticatedMenu = () =>
    showNestedMenu ? (
      <Fragment>
        <Button
          semantic={'language-menu-back'}
          variant={'hollow'}
          className={'d-flex lh-1 ps-0'}
          onClick={() => setShowNestedMenu(false)}
        >
          <span className={'me-2'}>
            <FontAwesomeIcon icon={faChevronLeft} className={'mb-3'} />
          </span>
          <span>{t('dashboard.back.label')}</span>
        </Button>
        <LanguageMenu />
      </Fragment>
    ) : (
      <Nav>
        {languageContextState.hasMultipleLanguageSupport && (
          <Button
            semantic={'language-menu-toggle'}
            onClick={() => setShowNestedMenu(true)}
            variant={'secondary'}
            className={[
              styles.dropdownItem,
              'mb-2 justify-content-between',
            ].join(' ')}
          >
            <span>
              <FontAwesomeIcon icon={faLanguage} className={'me-2'} />
              <span>{t('dashboard.nav-links.language')}</span>
            </span>
            <span>
              <FontAwesomeIcon
                className={'align-self-end'}
                icon={faChevronRight}
              />
            </span>
          </Button>
        )}
        <NavLink
          onClick={dismissMenu}
          to={Routes.signIn}
          className={'btn btn-outline-primary w-100 mb-3'}
          data-semantic={'navbar-mobile-sign-in-link'}
        >
          {t('dashboard.nav-links.login')}
        </NavLink>
        <NavLink
          onClick={dismissMenu}
          to={Routes.createAccount}
          className={'btn btn-primary w-100'}
          data-semantic={'navbar-mobile-create-account-link'}
        >
          {t('dashboard.nav-links.register')}
        </NavLink>
      </Nav>
    );

  if (props.preventNavigation) {
    return (
      <div className={[styles.mobileNavigationContainer].join(' ')}>
        <div className={styles.mobileNavigation}>
          <img
            className={styles.mobileLogo}
            src={LogoIcon}
            alt={props.logoAlt}
          />
          <Button
            semantic={'user-menu-toggle'}
            ref={userDropdownToggleRef}
            variant={'hollow'}
            className={styles.userMenuToggle}
            onClick={() => {
              setShowUserDropdown((prevState) => !prevState);
            }}
          >
            {userQuery.loading ? (
              <Spinner animation={'border'} />
            ) : (
              <Fragment>
                <ProfileAvatar
                  className={styles.avatar}
                  size={AvatarSizes.xs}
                  photo={userQuery.data?.user?.avatarURL}
                  fallbackIcon={faUser}
                  borderClassName={styles.avatarBorder}
                />
                <FontAwesomeIcon
                  className={styles.arrow}
                  icon={faChevronDown}
                />
              </Fragment>
            )}
          </Button>
        </div>
        <div
          ref={userDropdownRef}
          className={[
            styles.userDropdown,
            showUserDropdown ? styles.show : '',
          ].join(' ')}
        >
          {authContextState.userId
            ? renderAuthenticatedMenu()
            : renderUnauthenticatedMenu()}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {/* todo need to handle banner based on the design it should probably be on top so when the menu expands it wont conflict */}
      <div className={styles.mobileNavigationContainer}>
        <div className={styles.mobileNavigation}>
          <NavLink to={Routes.home} data-semantic={'navbar-mobile-home-link'}>
            <img
              className={styles.mobileLogo}
              src={LogoIcon}
              alt={props.logoAlt}
            />
          </NavLink>
          {checkFeatureFlags(FeaturesEnum.HeaderIcons) &&
            navLinks
              .filter((link) => !link.hideOnMobile)
              .map((link) => (
                <NavLink
                  to={link.url}
                  title={link.title}
                  key={link.title}
                  data-semantic={`navbar-mobile-${link.semantic}-link`}
                >
                  <FontAwesomeIcon icon={link.icon} />
                </NavLink>
              ))}

          {userQuery.loading && <Spinner animation={'border'} />}

          {checkFeatureFlags(FeaturesEnum.HeaderIcons) &&
          authContextState.userId &&
          userQuery.data ? (
            // todo ask design if this should perhaps be a hamburger menu icon instead as when the user is not authenticated it is confusing
            <NotificationsToggle
              userId={authContextState.userId}
              notifToggleRef={notifToggleRef}
              toggleNotificationsMenu={() =>
                setShowNotifications((prevState) => !prevState)
              }
              setPopupNotification={() => setPopupNotification(null)}
            />
          ) : null}
          <Button
            semantic={'user-menu-toggle'}
            ref={userDropdownToggleRef}
            variant={'hollow'}
            className={styles.userMenuToggle}
            onClick={() => {
              setShowUserDropdown((prevState) => !prevState);
            }}
          >
            {userQuery.loading ? (
              <Spinner animation={'border'} />
            ) : (
              <Fragment>
                <ProfileAvatar
                  className={styles.avatar}
                  size={AvatarSizes.xs}
                  photo={userQuery.data?.user?.avatarURL}
                  fallbackIcon={faUser}
                  borderClassName={styles.avatarBorder}
                />
                <FontAwesomeIcon
                  className={styles.arrow}
                  icon={faChevronDown}
                />
              </Fragment>
            )}
          </Button>
        </div>
        <div
          ref={notifRef}
          className={[
            styles.notificationsMenu,
            showNotifications ? styles.show : '',
          ].join(' ')}
        >
          <div className={styles.separator} />
          <Notifications
            setShowNotifications={() => setShowNotifications(true)}
            show={showNotifications}
            onClick={dismissMenu}
            popupNotification={popupNotification}
            setPopupNotification={setPopupNotification}
          />
        </div>
        <div
          ref={userDropdownRef}
          className={[
            styles.userDropdown,
            showUserDropdown ? styles.show : '',
          ].join(' ')}
        >
          {authContextState.userId
            ? renderAuthenticatedMenu()
            : renderUnauthenticatedMenu()}
        </div>
      </div>
    </Fragment>
  );
};

export default MobileNav;
