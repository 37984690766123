import { useEffect, useRef } from 'react';
import {
  routesToIgnore,
  setRedirectDetails,
} from '../services/RedirectPathService';
import { useHistory } from 'react-router-dom';

export interface IRouteTracking {
  path: string;
  title: string;
}

interface IRouteTrackingRef {
  previousRoute: IRouteTracking | null;
}

const useTrackPreviousRoute = () => {
  const history = useHistory();

  const routeTracking = useRef<IRouteTrackingRef>({
    previousRoute: routesToIgnore.includes(history.location.pathname)
      ? null
      : { path: history.location.pathname, title: '' },
  });

  useEffect(() => {
    if (
      history.location.pathname !== routeTracking.current.previousRoute?.path &&
      !routesToIgnore.includes(history.location.pathname)
    ) {
      setRedirectDetails({ path: history.location.pathname, title: '' });

      routeTracking.current = {
        previousRoute: { path: history.location.pathname, title: '' },
      };
    }
  }, [history.location.pathname]);

  return routeTracking.current;
};

export default useTrackPreviousRoute;
