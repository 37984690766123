import { useForm } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { useTranslation } from 'react-i18next';
import { FloatingLabel, Form, FormGroup } from 'react-bootstrap';
import Button from '../../../../components/Button/Button';
import FormControl from 'react-bootstrap/FormControl';
import { useStoreContext } from '../../../../pages/Store/StoreContext';
import { getDisplayPriceForItem } from '../../../../pages/Store/StoreHelpers';
import { PaymentMethod, ProductType } from 'src/generated/graphql';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { useCallback, useMemo, useState } from 'react';
import { camelCaseToNormal } from 'src/utilities/Utilities';

type FieldType = 'string' | 'number' | 'select' | 'email';

export interface IField {
  name: string;
  label?: string;
  validation_regex?: string | RegExp;
  sort_order?: number;
  placeholder?: string;
  type: FieldType;
  options?: {
    label: string;
    value: string;
  }[];
  data?: { name: string; value: string }[];
}

interface IProps {
  currencyName: string;
  price: number;
  fields?: IField[];
  onHide: () => void;
  onConfirm: (fields: { [key: string]: string | number }) => void;
  loading: boolean;
  disableButton: boolean;
  productType?: ProductType | null;
  onBack?: () => void;
}

const StoreItemRedemptionDetails = (props: IProps) => {
  const { t } = useTranslation('store');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { state: storeState } = useStoreContext();

  const getFieldName = useCallback(
    (fieldName: string, label?: string) => {
      if (label) {
        return label;
      }

      const statements = {
        userId: t<string>('item-redemption-form.field.possible-names.userid'),
        accountId: t<string>(
          'item-redemption-form.field.possible-names.accountid'
        ),
        playerid: t<string>(
          'item-redemption-form.field.possible-names.playerid'
        ),
        zoneid: t<string>('item-redemption-form.field.possible-names.zoneid'),
        region: t<string>('item-redemption-form.field.possible-names.region'),
        server: t<string>('item-redemption-form.field.possible-names.server'),
        email: t('email'),
      };

      return (
        statements[fieldName as keyof typeof statements] ||
        camelCaseToNormal(fieldName)
      );
    },
    [t]
  );

  const {
    register,
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
  } = useForm();

  const renderInputByType = (field: IField) => {
    switch (field.type) {
      case 'select':
        return (
          <FormGroup>
            <FloatingLabel label={getFieldName(field.name, field.label)}>
              <Form.Select
                key={`${field.name}`}
                className={'mb-3'}
                {...register(`${field.name}`, {
                  pattern: {
                    value: new RegExp(field.validation_regex ?? ''),
                    message: `Please enter a valid ${getFieldName(
                      field.name,
                      field.label
                    )}`,
                  },
                  required: t<string>('item-redemption-form.field.isRequired', {
                    fieldName: getFieldName(field.name, field.label),
                  }),
                })}
                defaultValue={field.options && field.options[0].value}
                placeholder={field.placeholder}
              >
                {field.options?.map((options) => (
                  <option value={options.value}>{options.label}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FormControl.Feedback type={'invalid'}>
              <span>{errors[field.name] && errors[field.name].message}</span>
            </FormControl.Feedback>
          </FormGroup>
        );
      case 'number':
        return (
          <FormGroup>
            <Input
              className={'mb-3'}
              key={`${field.name}`}
              {...register(`${field.name}`, {
                pattern: {
                  value: new RegExp(field.validation_regex ?? ''),
                  message: `Please enter a valid ${getFieldName(
                    field.name,
                    field.label
                  )}`,
                },
                valueAsNumber: true,
                required: t<string>('item-redemption-form.field.isRequired', {
                  fieldName: getFieldName(field.name, field.label),
                }),
              })}
              label={getFieldName(field.name, field.label)}
              isInvalid={Boolean(errors[field.name])}
              feedback={
                <FormControl.Feedback type={'invalid'}>
                  <span>
                    {errors[field.name] && errors[field.name].message}
                  </span>
                </FormControl.Feedback>
              }
              type={'number'}
              placeholder={field.placeholder}
            />
          </FormGroup>
        );
      case 'email':
        return (
          <FormGroup>
            <Input
              className={'mb-3'}
              key={`${field.name}`}
              {...register(`${field.name}`, {
                pattern: {
                  value: new RegExp(field.validation_regex ?? ''),
                  message: `Please enter a valid ${getFieldName(
                    field.name,
                    field.label
                  )}`,
                },

                required: t<string>('item-redemption-form.field.isRequired', {
                  fieldName: getFieldName(field.name, field.label),
                }),
              })}
              label={getFieldName(field.name, field.label)}
              isInvalid={Boolean(errors[field.name])}
              defaultValue={field.data && field.data[0].value}
              feedback={
                <FormControl.Feedback type={'invalid'}>
                  <span>
                    {errors[field.name] && errors[field.name].message}
                  </span>
                </FormControl.Feedback>
              }
              type={'email'}
              placeholder={field.placeholder}
            />
          </FormGroup>
        );
      case 'string':
        return (
          <FormGroup>
            <Input
              className={'mb-3'}
              key={`${field.name}`}
              {...register(`${field.name}`, {
                pattern: {
                  value: new RegExp(field.validation_regex ?? ''),
                  message: `Please enter a valid ${getFieldName(
                    field.name,
                    field.label
                  )}`,
                },
                required: t<string>('item-redemption-form.field.isRequired', {
                  fieldName: getFieldName(field.name, field.label),
                }),
              })}
              label={getFieldName(field.name, field.label)}
              isInvalid={Boolean(errors[field.name])}
              feedback={
                <FormControl.Feedback type={'invalid'}>
                  <span>
                    {errors[field.name] && errors[field.name].message}
                  </span>
                </FormControl.Feedback>
              }
              type={'text'}
              placeholder={field.placeholder}
            />
          </FormGroup>
        );
    }
  };

  const confirmationPrice = useMemo(() => {
    const paymentMethodType =
      storeState.selectedPaymentOption?.method.paymentMethodType;

    return `${
      paymentMethodType === PaymentMethod.Dcb ||
      paymentMethodType === PaymentMethod.Points
        ? storeState.selectedPaymentOption?.option.price.toLocaleString('en-US')
        : storeState.selectedPaymentOption?.option.pspPrice.toLocaleString(
            'en-US'
          )
    } ${storeState.selectedPaymentOption?.option.currency}`;
  }, [storeState.selectedPaymentOption]);

  const prepareAndGetInputObject = (): { [key: string]: string | number } =>
    props.fields?.reduce((result: any, field: IField) => {
      result[field.name] = getValues(`${field.name}`);
      return result;
    }, {});

  const redeemItem = () => {
    props.onConfirm(prepareAndGetInputObject());
  };

  const onSubmitForm = () => {
    if (props.productType === 'TOPUP') {
      setShowConfirmation(true);
    } else {
      redeemItem();
    }
  };

  return (
    <Form className={'tw-w-full'} onSubmit={handleSubmit(onSubmitForm)}>
      <div className='tw-SelectPaymentMethod tw-w-full tw-text-black dark:tw-text-zinc-100 tw-text-2xl tw-font-bold tw-leading-7'>
        {t('confirm-details')}
      </div>

      {props.fields && (
        <section className={'pb-3'}>
          <p className={'text-dark'}>
            {props.productType === ProductType.Voucher
              ? t('item-redemption-form.heading.voucher')
              : t('item-redemption-form.heading.top-up')}
          </p>
          {props.fields
            .sort((a, b) => {
              if (a.type === 'email' && b.type !== 'email') {
                return -1;
              } else if (b.type === 'email' && a.type !== 'email') {
                return 1;
              }
              if (a.sort_order && b.sort_order) {
                return a.sort_order - b.sort_order;
              }
              return a.name.localeCompare(b.name);
            })
            .map(renderInputByType)}
        </section>
      )}
      <ConfirmationModal
        title={''}
        confirmAction={() => redeemItem()}
        onHide={() => setShowConfirmation(false)}
        show={showConfirmation}
      >
        <p className='tw-text-black dark:tw-text-zinc-100 tw-text-2xl tw-font-bold tw-leading-7'>
          {t('purchase-topup-confirm-box.title')}
        </p>
        <p className='tw-mt-7 tw-mb-7 tw-text-black dark:tw-text-zinc-100'>
          {t('purchase-topup-confirm-box.subTitle')}
        </p>

        <div className='tw-flex tw-flex-col tw-gap-4 tw-text-black dark:tw-text-zinc-100'>
          {Object.entries(prepareAndGetInputObject()).map(([key, value]) => (
            <div key={key} className='tw-flex tw-flex-row tw-justify-between'>
              <div>{`${props.fields
                ?.filter((field) => field.name === key)
                .map((field) => field.label ?? getFieldName(key))}`}</div>
              <div className='tw-break-all'>{value}</div>
            </div>
          ))}
          <div className='tw-flex tw-flex-row tw-justify-between'>
            <div>{t('purchase-topup-confirm-box.label.item')}: </div>
            <div className='tw-break-all'>
              {' '}
              {storeState.selectedItem?.itemName}
            </div>
          </div>

          <div className='tw-flex tw-flex-row tw-justify-between'>
            <div>{t('purchase-topup-confirm-box.label.paymentMethod')}:</div>
            <div>{storeState.selectedPaymentOption?.method.name}</div>
          </div>

          <div className='tw-flex tw-flex-row tw-justify-between tw-mt-10 tw-text-lg tw-font-bold'>
            <div>{t('purchase-topup-confirm-box.label.totalPayment')}:</div>
            <div>{confirmationPrice}</div>
          </div>
        </div>
      </ConfirmationModal>

      <StoreItemDetailCheckoutButton
        currencyName={props.currencyName}
        loading={props.loading}
        disabled={props.disableButton || props.loading || isSubmitting || false}
      />
    </Form>
  );
};

function StoreItemDetailCheckoutButton(props: {
  loading: boolean | undefined;
  disabled: boolean;
  currencyName: string;
}) {
  const { state } = useStoreContext();
  const { t } = useTranslation('store');
  return (
    <>
      {state.selectedPaymentOption && (
        // pin to bottom on smaller screens
        <div
          className='tw-CheckoutButton 
        tw-fixed tw-w-full tw-bottom-0 tw-left-0 tw-h-20 tw-px-4
        lg:tw-relative lg:tw-w-96  lg:tw-top-2 lg:tw-px-0
        tw-border-t tw-border-zinc-900 tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-inline-flex'
        >
          <Button
            variant='primary'
            type={'submit'}
            loading={props.loading}
            disabled={props.disabled || props.loading || false}
            semantic={'store-item-modal-confirm'}
            className='tw-BuyFooter tw-w-full lg:tw-p-8 tw-bg-rose-600 lg:tw-rounded-2xl tw-justify-center tw-items-center tw-gap-4 tw-inline-flex tw-h-full'
          >
            <div className='tw-BuyNow tw-text-zinc-100 dark:tw-text-zinc-100 tw-text-lg tw-font-medium tw-leading-snug'>
              {t('buy-now')}
            </div>
            <div className='tw-1249 tw-text-zinc-100 dark:tw-text-zinc-100 tw-text-xl tw-font-bold tw-leading-7'>
              {getDisplayPriceForItem(
                state.selectedPaymentOption.option,
                state.selectedPaymentOption.method
              )}
            </div>
          </Button>
        </div>
      )}
    </>
  );
}

export default StoreItemRedemptionDetails;
