import { useTranslation } from 'react-i18next';

export type Props = {
  className?: string;
};

const LearnLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <nav className={props.className}>
      <h6>{t('footer.learn-more.heading', 'Learn More')}</h6>
      <a
        href={'https://arenaesports.ae/terms-and-conditions/'}
        data-semantic={'footer-tos-link'}
      >
        {t('footer.learn-more.tos-link-label')}
      </a>
      <a
        href={'https://www.etisalat.ae/en/footer/about-us.html'}
        target='blank'
      >
        {t('footer.learn-more.about-link-label', 'About')}
      </a>
    </nav>
  );
};

export default LearnLinks;
