interface IProps {
  isExpanded: boolean;
  icon: JSX.Element;
  title?: string | JSX.Element;
}

const VerticalNavItem = ({ isExpanded, icon, title }: IProps) => (
  <div className={'d-flex align-items-center'}>
    {icon}
    {isExpanded && title && <span className={'ms-3 small'}>{title}</span>}
  </div>
);

export default VerticalNavItem;
