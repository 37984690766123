import { useApolloClient } from '@apollo/client';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { formatNumberForLocale } from 'src/utilities/Utilities';
import {
  CatalogItemFragment,
  GetMyOrdersDocument,
  GetMyOrdersQuery,
  GetMyOrdersQueryVariables,
  GetStoreMerchantPaymentMethodsQuery,
  CatalogItemProductPriceFragment,
  PurchaseStoreItemWithPointsMutation,
  SortOrder,
  useGetStoreMerchantPaymentMethodsQuery,
} from '../../generated/graphql';
import { useAuthContext } from '../Authentication/AuthContext';
import { DEFAULT_SWARMIO_CURRENCY_ID } from '../Wallet/CurrencyId';
import { StoreItemSortingOptions } from './StoreFilters/StoreItemSort';
import { IField } from '../../clients/default/components/StoreItem/StoreItemRedemptionDetails';
import { StoreItemData } from './useStoreReducer';

export function transformCatalogItemToStoreItemData(
  fields: CatalogItemFragment['fields']
): IField[] {
  return (
    fields?.map((field: IField) => {
      if (field.type === 'string' || field.type === 'number') {
        return {
          name: field.name,
          type: field.type,
          label: field.label,
          options: field.options,
          sort_order: field.sort_order,
          validation_regex: field.validation_regex,
          placeholder: field.placeholder,
        };
      }

      return {
        name: field.name,
        type: field.type,
        label: field.label,
        options: field.options,
        sort_order: field.sort_order,
        validation_regex: field.validation_regex,
        data: field.data,
        placeholder: field.placeholder,
      };
    }) ?? null
  );
}
export const getSortArgs = (itemSorting: StoreItemSortingOptions | null) => {
  switch (itemSorting) {
    case StoreItemSortingOptions.PRICE_HIGH_TO_LOW:
      return {
        sortField: 'pointsValue',
        order: SortOrder.Descending,
      };
    case StoreItemSortingOptions.PRICE_LOW_TO_HIGH:
      return {
        sortField: 'pointsValue',
        order: SortOrder.Ascending,
      };
    case StoreItemSortingOptions.ALPHABETIC:
      return { sortField: 'itemName', order: SortOrder.Ascending };
    case StoreItemSortingOptions.ALPHABETIC_REVERSE:
      return {
        sortField: 'itemName',
        order: SortOrder.Descending,
      };
    default:
      return {
        sortField: 'itemName',
        order: SortOrder.Descending,
      };
  }
};

export const useUpdateMyOrdersCache = () => {
  const client = useApolloClient();
  const [authContextState] = useAuthContext();

  return (res: PurchaseStoreItemWithPointsMutation) => {
    const myOrdersCache = client.readQuery<
      GetMyOrdersQuery,
      GetMyOrdersQueryVariables
    >({
      query: GetMyOrdersDocument,
      variables: {
        accountId: authContextState.userId!,
      },
    });

    if (myOrdersCache?.account?.purchaseTransactions) {
      const updatedMyOrders = {
        account: {
          ...myOrdersCache.account,
          purchaseTransactions:
            res.purchaseExternalItemWithPoints.purchaseTransactions,
        },
      };

      client.writeQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>({
        query: GetMyOrdersDocument,
        variables: {
          accountId: authContextState.userId!,
        },
        data: updatedMyOrders,
      });
    }
  };
};

export const sortByWeight = (
  a: { weight?: number | null },
  b: { weight?: number | null }
) => {
  if (typeof a.weight !== 'number') {
    return -1;
  }
  if (typeof b.weight !== 'number') {
    return 1;
  }
  return a.weight - b.weight;
};

export interface PriceOption {
  id?: number;
  name: string;
  price: number;
  currency: string;
  currencyId: string;
  tagId: string;
  pspPrice: number;
  pspTagId: string;
  isPointsPrice: boolean;
  paymentOptions?: MerchantPaymentOption[] | undefined;
}

export interface MerchantPaymentOptionsParams {
  price: number;
  currency: string;
  skip?: boolean;
  onCompleted?: (data: GetStoreMerchantPaymentMethodsQuery) => void;
}

export interface MerchantPaymentOption {
  id: string;
  name: string;
  imageUrl?: string;
  disabled: boolean;
  paymentMethodType: string;
  refundSupported: boolean;
}
/**
 * Returns the display price for a store item based on its points price and product prices.
 * @param pointsPrice - The points price of the store item.
 * @param productPrices - The array of product prices for the store item.
 * @returns The display price of the store item as a string.
 */
export function getDisplayPriceForItem(
  targetPrice: PriceOption,
  targetPaymentMethod?: MerchantPaymentOption
): string {
  return targetPrice.currency.includes('Points')
    ? formatNumberForLocale(targetPrice.price)
    : targetPrice.currency &&
        (targetPaymentMethod?.paymentMethodType === 'DCB'
          ? targetPrice.price
          : targetPrice.pspPrice
        ).toLocaleString('en-US', {
          style: 'currency',
          currency: targetPrice.currency,
        });
}
export function getDefaultDisplayPriceForItem(
  options: PriceOption[],
  pointsPrice?: number
): string {
  if (pointsPrice && GlobalClientConfig.currency?.showOnlyPoints) {
    return formatNumberForLocale(pointsPrice);
  } else {
    // take the first as default
    return getDisplayPriceForItem(options[0]);
  }
}

function getLowestPriceInPoints(catalogItems: CatalogItemFragment[]) {
  let lowestPrice: number | undefined;

  catalogItems.forEach((catalogItem) => {
    if (
      catalogItem.pointsValue &&
      catalogItem.pointsValue < (lowestPrice ?? Number.MAX_VALUE)
    ) {
      lowestPrice = catalogItem.pointsValue;
    }
  });

  return lowestPrice;
}

export function getLowestFiatPrice(
  catalogItems: CatalogItemFragment[]
): CatalogItemProductPriceFragment | undefined {
  let lowestPrice: CatalogItemProductPriceFragment | undefined;

  if (Array.isArray(catalogItems)) {
    catalogItems.forEach((catalogItem) => {
      catalogItem.productPrices
        ?.filter((priceOption) => !priceOption?.currency?.includes('Points')) // TODO: Should I really remove points ?
        .forEach((priceOption) => {
          if (
            priceOption?.price &&
            priceOption.price < (lowestPrice?.price ?? Number.MAX_VALUE)
          ) {
            lowestPrice = priceOption;
          }
        });
    });
  }

  return lowestPrice;
}

export function getDefaultDisplayPriceForProductGroup(
  items: CatalogItemFragment[]
): string {
  if (GlobalClientConfig.currency?.showOnlyPoints) {
    const lowestPriceInPoints = getLowestPriceInPoints(items);
    return lowestPriceInPoints
      ? formatNumberForLocale(lowestPriceInPoints)
      : '-';
  }
  const lowestFiatPrice = getLowestFiatPrice(items);
  return lowestFiatPrice
    ? getDisplayPriceForItem(ProductPriceToPriceOption(lowestFiatPrice))
    : '-';
}

export function getProductTypeFromGroup(items: CatalogItemFragment[]): string {
  if (Array.isArray(items)) {
    return items.find((item) => !!item.productType)?.productType ?? '';
  }
  return '';
}

export function useMerchantPaymentOptions({
  price,
  currency,
  skip,
  onCompleted,
}: MerchantPaymentOptionsParams) {
  const merchantPaymentOptions = useGetStoreMerchantPaymentMethodsQuery({
    variables: {
      amount: price || 0,
      currency: currency || '',
      country: GlobalClientConfig.countryCode,
    },
    skip,
    onCompleted,
  });

  return merchantPaymentOptions.data?.getPaymentMethodsAvailableForMerchant as
    | MerchantPaymentOption[]
    | undefined;
}

export function ProductPriceToPriceOption(
  price: CatalogItemProductPriceFragment
): PriceOption {
  return {
    // TODO: Why keeping price options that doesn't have the correct data ? We could remove those and log the error to track it.
    name: price.currency ?? 'error',
    price: price.price ?? -1,
    currency: price.currency ?? '',
    currencyId: price.currency ?? 'error',
    tagId: price.tagId ?? 'error',
    pspPrice: price.pspPrice ?? -1,
    pspTagId: price.pspTagId ?? 'error',
    isPointsPrice: false,
  };
}

export function useFormattedPriceOptions(
  currencyName?: string,
  pointsValue?: number,
  currentItem?: StoreItemData | null
): PriceOption[] {
  const priceOptions: PriceOption[] = [];
  // pointsPrice is passed through props and should always be available
  const pointsPrice: PriceOption = {
    name: currencyName ?? 'bean wallet',
    price: pointsValue ?? -1,
    currency: currencyName ?? 'beans',
    currencyId: DEFAULT_SWARMIO_CURRENCY_ID,
    pspPrice: -1,
    pspTagId: 'INVALID',
    tagId: currentItem?.tagId ?? '',
    isPointsPrice: true, // TODO: Confirm that this is the only option possible in points
    paymentOptions: [
      {
        id: DEFAULT_SWARMIO_CURRENCY_ID,
        name: currencyName ?? 'points',
        paymentMethodType: 'POINTS',
        refundSupported: true,
        disabled: false,
      },
    ],
  };

  // if we're auto-selecting a currency, it's not going to be points.
  if (!GlobalClientConfig.currency?.hideCurrencySelectOnStore) {
    priceOptions.push(pointsPrice);
  }

  // and the rest of the options
  if (currentItem?.productPrices) {
    currentItem.productPrices.forEach((productPrice, index) => {
      if (productPrice && productPrice.price != null) {
        priceOptions.push(ProductPriceToPriceOption(productPrice));
      }
    });
  }
  return priceOptions;
}
