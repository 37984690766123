import { Fragment, useMemo } from 'react';

import Container from 'react-bootstrap/Container';

import PageWrapper from 'src/components/PageWrapper/PageWrapper';
import Background from 'src/components/Background/Background';

import ClientBackgrounds from 'HiveClient/components/Backgrounds/Backgrounds';

import { useImage } from 'react-image';
import { DEFAULT_BACKGROUND } from 'src/config/Backgrounds/Backgrounds';
import { JoinTeam } from './components/JoinTeam';
import {
  useGetEventToInvitePageQuery,
  useGetTeamForInvitePageQuery,
} from '../../generated/graphql';
import AsyncView from '../../components/AsyncView/AsyncView';
import { isTypename } from '../../utilities/TSUtilities';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import LanguageService from '../../services/LanguageService';
import { formatToLocalTimezone } from '../../utilities/Utilities';
import styles from './styles.module.scss';
import { useAuthContext } from '../Authentication/AuthContext';
import { useTranslation } from 'react-i18next';
import Routes from 'src/config/Routes/Routes';

const StartsOn = ({ startsAt }: { startsAt: string }) => (
  <div>
    <span>
      Starts on:{' '}
      <strong>
        {formatToLocalTimezone(startsAt, {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        })}
      </strong>
    </span>
    <div className={`tw-w-full tw-h-[0.1rem] ${styles.bgPrimary}`} />
  </div>
);

export type EventTeamInvitePageParamMatch = {
  eventId: string;
  teamId: string;
  code: string;
};

const EventTeamInviteLink = () => {
  const { t } = useTranslation('team');
  const history = useHistory();
  const {
    params: { code, eventId, teamId },
  } = useRouteMatch<EventTeamInvitePageParamMatch>();
  const [{ userId }] = useAuthContext();

  const eventQuery = useGetEventToInvitePageQuery({
    variables: {
      eventId: eventId,
    },
  });

  const eventData = eventQuery.data?.event;

  const teamQuery = useGetTeamForInvitePageQuery({
    variables: {
      id: teamId,
    },
  });

  const teamData = useMemo(() => {
    if (teamQuery.data?.account && isTypename('Team', teamQuery.data.account)) {
      return teamQuery.data.account;
    }
  }, [teamQuery]);

  const isTeamMember = useMemo(() => {
    if (teamData && eventData) {
      const teamParticipant = eventData.participants.find(
        (participant) =>
          isTypename('TeamParticipant', participant) &&
          participant.teamId === teamData.id
      );
      if (teamParticipant && isTypename('TeamParticipant', teamParticipant)) {
        return teamParticipant.teamMembers.some(
          (member) => member.accountId === userId
        );
      }
    }
    return false;
  }, [teamData, userId, eventData]);

  const { src } = useImage({
    useSuspense: false,
    srcList: [DEFAULT_BACKGROUND, ClientBackgrounds.storeBackground],
  });

  return (
    <Fragment>
      <Background background={src}>
        <div className={styles.profileBackground} />
      </Background>
      <PageWrapper clearMenu noBackgroundImage>
        <AsyncView loading={eventQuery.loading || teamQuery.loading}>
          {eventData && teamData ? (
            <Container className='tw-flex tw-flex-col tw-gap-y-14'>
              <div className='tw-flex tw-flex-row tw-justify-between'>
                <div className='tw-flex tw-flex-col'>
                  <span>{eventData.game.prettyName}</span>
                  <span className='tw-text-5xl'>
                    {LanguageService.getLocaleObject(eventData.localizedNames)}
                  </span>
                </div>
                <StartsOn startsAt={eventData.eventTiming.startTime} />
              </div>
              <JoinTeam
                title={
                  isTeamMember ? (
                    <span className='tw-text-4xl'>
                      {t('invite-link.already-member-event')}
                    </span>
                  ) : (
                    <span className='tw-text-4xl'>
                      {t('invite-link.invited-to-team')}
                      <strong>{' ' + teamData.name + ' '}</strong>{' '}
                      {t('invite-link.as-part-event')}
                    </span>
                  )
                }
                description={
                  !isTeamMember && (
                    <span className='tw-text-sm'>
                      {t('invite-link.invited-to-team-description')}
                    </span>
                  )
                }
                variant='event'
                isTeamMember={isTeamMember}
                inviteCode={code}
                onSuccess={() =>
                  history.push(
                    generatePath(Routes.eventDashboard, {
                      id: eventData.id,
                    })
                  )
                }
              />
            </Container>
          ) : (
            <Container>
              <h1>{t('invite-link.no-data-found')}</h1>
            </Container>
          )}
        </AsyncView>
      </PageWrapper>
    </Fragment>
  );
};

export default EventTeamInviteLink;
