import { PAYMENTS_URL } from '../config/Endpoints/Endpoints';
import i18next from 'i18next';

export const createRedirectURL = (id: string) =>
  `${PAYMENTS_URL}/payments/${id}?lang=${i18next.language}`;

const SwarmioPayService = {
  createRedirectURL,
};

export default SwarmioPayService;
