import {
  Dispatch,
  createContext,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface TwitchAuthContextInterface {
  clientID: string | null;
  token: string | null;
  hasError: boolean;
}

const TwitchAuthContext = createContext<
  | [
      TwitchAuthContextInterface,
      Dispatch<SetStateAction<TwitchAuthContextInterface>>
    ]
  | undefined
>(undefined);

export const TwitchAuthContextProvider = ({ children }: any) => {
  const twitchAuthContext = useState<TwitchAuthContextInterface>({
    clientID: null,
    token: null,
    hasError: false,
  });

  return (
    <TwitchAuthContext.Provider value={twitchAuthContext}>
      {children}
    </TwitchAuthContext.Provider>
  );
};

export const useTwitchAuthContext = () => {
  const context = useContext(TwitchAuthContext);

  if (!context) {
    throw new Error(
      'useTwitchAuthContext must be used inside of a <TwitchAuthContextProvider />'
    );
  }
  return context;
};
