import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { faSadTear } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'debounce-promise';
import { useCallback, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import EnvVariables from 'src/config/EnvVariables/EnvVariables';
import Routes from 'src/config/Routes/Routes';
import { useScreenSizeCheck } from 'src/hooks/useScreenSizeCheck';
import StoreItem from 'src/pages/Store/StoreItem/StoreItem';
import {
  CatalogItemFragment,
  PartnerProductGroupsDocument,
  PartnerProductGroupsQuery,
  PartnerProductGroupsQueryVariables,
  SortOrder,
} from '../../../generated/graphql';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

interface SearchItem {
  id: string;
  productGroupId: string;
  name: string;
  displayName: string;
  bannerImage: string;
  thumbnail: string;
  items: (CatalogItemFragment | null)[];
}

const StoreGroupSearch = () => {
  const { t } = useTranslation('store');
  const client = useApolloClient();
  const history = useHistory();
  const isMobileScreen = useScreenSizeCheck('mobile');
  const isTabletScreen = useScreenSizeCheck('tablet');

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [isFocus, setIsFocus] = useState(false);
  const [isResultsFocus, setIsResultsFocus] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = useCallback(
    (inputValue: string) => {
      client
        .query<PartnerProductGroupsQuery, PartnerProductGroupsQueryVariables>({
          query: PartnerProductGroupsDocument,
          variables: {
            limit: 5,
            offset: 0,
            partnerId: EnvVariables.storePartnerId ?? '',
            search: {
              searchFields: ['displayName'],
              searchText: inputValue,
            },
            sort: {
              order: SortOrder.Ascending,
              sortField: 'id',
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          const productGroups =
            res.data.partnerProductGroups?.productGroups ?? [];
          const processedItems = productGroups.map((productGroup) => ({
            id: productGroup?.id ?? '',
            productGroupId: productGroup?.productGroupId ?? '',
            name: productGroup?.name ?? '',
            displayName: productGroup?.displayName ?? '',
            bannerImage: productGroup?.bannerImage ?? '',
            thumbnail: productGroup?.thumbnail ?? '',
            items: productGroup?.items ?? [],
          }));
          setSearchResults(processedItems);
          setIsFetchingData(false);
          setTotalItems(res.data.partnerProductGroups?.totalProductGroups ?? 0);
          return productGroups;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsFetchingData(false);
        });
    },
    [client]
  );

  const debouncedSuggestions = useMemo(
    () =>
      debounce((value) => {
        fetchData(value.trim());
      }, 1000),
    [fetchData]
  );

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    if (inputValue.length > 3 && inputValue.trim() !== '') {
      setIsFetchingData(true);
      debouncedSuggestions(inputValue);
    } else {
      setSearchResults([]);
    }
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchTerm.trim() === '') {
      //prevent from searching empty strings
      return;
    }

    setIsFocus(false);
    setIsResultsFocus(false);
    history.push(`${Routes.storeItemSearchResults}?query=${searchTerm.trim()}`);
  };

  const viewMoreOnclick = () => {
    setIsFocus(false);
    setIsResultsFocus(false);
    history.push(`${Routes.storeItemSearchResults}?query=${searchTerm.trim()}`);
  };

  return (
    <div
      className={`tw-sticky ${
        GlobalClientConfig.nav.orientation === 'hidden'
          ? 'tw-top-2'
          : 'tw-top-16'
      } tw-z-10`}
      onBlur={() => setIsFocus(false)}
    >
      <div
        className={`tw-relative tw-w-full tw-h-min tw-flex tw-flex-col tw-rounded-lg ${
          isFocus && 'tw-outline tw-outline-2 tw-outline-[#504A53]'
        } ${isMobileScreen || isTabletScreen ? 'tw-mb-4' : ''}`}
      >
        <form
          className='tw-relative tw-z-20'
          onSubmit={submitHandler}
          autoComplete='off'
        >
          <input
            type='text'
            id='search-bar'
            placeholder={t('item-search.placeholder')}
            onChange={searchHandler}
            value={searchTerm}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            className='tw-w-full tw-flex tw-outline-none tw-border-none tw-rounded-lg focus:tw-rounded-b-none tw-bg-neutral-800 tw-text-white tw-text-sm tw-py-3 tw-px-5 '
          />
          {isMobileScreen && isFocus && searchTerm.length > 3 ? (
            <FontAwesomeIcon
              icon={faX}
              className='tw-w-4 tw-h-4 tw-absolute tw-top-3 tw-right-4 tw-cursor-pointer'
              onClick={() => setIsFocus(false)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faSearch}
              className='tw-w-4 tw-h-4 tw-absolute tw-top-3 tw-right-4'
            />
          )}
        </form>
        {(isFocus || isResultsFocus) &&
          searchTerm.length > 3 &&
          searchTerm.trim() !== '' && (
            <div
              className='tw-absolute tw-top-[42px] tw-z-10 tw-inline-block tw-w-full tw-outline tw-outline-2 tw-outline-[#504A53] tw-bg-neutral-800 tw-rounded-b-lg tw-pt-3 tw-pb-5 tw-px-5 tw-transition tw-duration-300 tw-ease-in-out tw-overscroll-y-auto'
              onFocus={() => setIsResultsFocus(true)}
              onMouseOver={() => setIsResultsFocus(true)}
              onMouseOut={() => setIsResultsFocus(false)}
              onBlur={() => setIsResultsFocus(false)}
            >
              {isFetchingData ? (
                <div
                  key='loader'
                  className='col mt-4 mb-4 text-center justify-content-center'
                >
                  <Spinner
                    as={'span'}
                    animation='border'
                    role='status'
                    aria-hidden='true'
                  />
                </div>
              ) : searchResults.length > 0 ? (
                <div className='tw-flex tw-flex-col'>
                  <div className='tw-grid tw-grid-cols-1 tw-gap-7'>
                    {searchResults.map((result) => (
                      <StoreItem
                        key={`${result.productGroupId}-${result.id}`}
                        displayType='search'
                        name={result.displayName}
                        image={result.bannerImage}
                        onClick={() =>
                          history.push(
                            `${Routes.store}/results/${result.productGroupId}`
                          )
                        }
                      />
                    ))}
                  </div>
                  {totalItems > 5 && (
                    <div className='tw-pt-3'>
                      <button
                        className='tw-text-neutral-400 tw-text-lg tw-block tw-text-center tw-rounded-lg tw-border-none tw-border-neutral-600 tw-bg-neutral-800 hover:tw-bg-neutral-800 focus:tw-outline-none tw-flex tw-items-center tw-content-center tw-justify-center tw-w-full'
                        onClick={viewMoreOnclick}
                      >
                        <div>{t('item-search.view-more-results')}</div>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='tw-w-4 tw-h-4 tw-text-gray-500 tw-dark:text-gray-400 tw-ml-2'
                        />
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className='tw-flex tw-items-center tw-content-center tw-justify-center gap-2'>
                  <div className='tw-text-lg tw-block tw-text-center tw-text-neutral-400'>
                    {t('item-search.no-results')}
                  </div>
                  <FontAwesomeIcon
                    icon={faSadTear}
                    className='tw-w-4 tw-h-4 tw-text-neutral-400 tw-ml-2'
                  />
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default StoreGroupSearch;
