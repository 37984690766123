interface IEmberLogo {
  className?: string;
}
const EmberLogo = (props: IEmberLogo) => (
  <svg
    className={props.className}
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M14.9014 18.4982L13.3963 19.9991L8.72711 15.336V9.32842L13.0176 13.6133C13.2489 12.84 13.2666 12.0186 13.0688 11.2361C12.871 10.4536 12.465 9.73902 11.8939 9.16805L8.72711 6.00627L5.55988 9.16892C4.72112 10.0085 4.25007 11.146 4.25007 12.332C4.25007 13.518 4.72112 14.6555 5.55988 15.4951L7.06364 16.9969L4.05787 20L2.55281 18.4982C-0.850936 15.0989 -0.850936 9.56419 2.55281 6.16491L8.72711 0L14.9014 6.16491C18.3056 9.56419 18.3056 15.0989 14.9014 18.4982Z' />
  </svg>
);

export default EmberLogo;
