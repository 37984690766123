import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StoreProductGroupSearch from './StoreSearch/StoreProductGroupSearch';
import {
  StoreCategoryFragment,
  useCategoryCatalogQuery,
} from 'src/generated/graphql';
import EnvVariables from 'src/config/EnvVariables/EnvVariables';
import { useFeatureFlagsContext } from '../../config/FeatureFlags/FeatureFlagsContext';
import { Spinner } from 'react-bootstrap';
import { useScreenSizeCheck } from 'src/hooks/useScreenSizeCheck';
import Routes from 'src/config/Routes/Routes';
import { FeaturesEnum } from 'src/config/FeatureFlags/FeaturesEnum';

interface INavItem {
  path: string;
  name: string;
}

const initialNavList: INavItem[] = [
  {
    name: 'Store Home',
    path: Routes.store,
  },
  {
    name: 'Browse',
    path: Routes.storeCategoryList,
  },
];

const StoreNavigation = () => {
  const location = useLocation();
  const isLaptopScreenSize = useScreenSizeCheck('laptop');
  const isSmallScreenSize = useScreenSizeCheck(768);
  const { checkFeatureFlags } = useFeatureFlagsContext();

  const storeCategoriesRes = useCategoryCatalogQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
    },
  });

  const navLinks: INavItem[] = useMemo(() => {
    const availableCategories =
      storeCategoriesRes.data?.categoryCatalog?.categories?.filter(
        (category): category is StoreCategoryFragment => category !== null
      ) ?? [];

    const formattedNavCategories: INavItem[] = availableCategories
      .slice(0, isLaptopScreenSize ? 1 : 3) //slice is used here to get the Featured categories from the list based on index
      .map((object) => ({
        name: object.categoryName ?? '',
        path: `${Routes.store}/${object.id}`,
      }));

    const combinedNavLinks: INavItem[] = initialNavList.concat(
      formattedNavCategories
    );
    return combinedNavLinks;
  }, [storeCategoriesRes.data, isLaptopScreenSize]);

  const isActiveNavItem = (navPath: string): boolean =>
    navPath === location.pathname;

  // If the storeNavigation feature flag is disabled, return an empty fragment
  if (checkFeatureFlags(FeaturesEnum.StoreNavigation) === false) {
    return <></>;
  } else if (isSmallScreenSize) {
    return <StoreProductGroupSearch />;
  } else {
    return (
      <div className='tw-StoreNavigation tw-hidden md:tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-4 tw-gap-8'>
        <div className='tw-flex-grow tw-max-w-[360px]'>
          <StoreProductGroupSearch />
        </div>
        {navLinks.map((nav, index) => (
          <Link
            key={index}
            to={nav.path}
            className={[
              'hover:tw-opacity-50 dark:tw-text-white tw-text-black tw-leading-normal tw-font-inter tw-font-normal tw-text-base tw-no-underline',
              !isActiveNavItem(nav.path) ? 'tw-opacity-50' : '', //to filter out non-active links
            ].join(' ')}
          >
            {nav.name}
          </Link>
        ))}

        {storeCategoriesRes.loading ? (
          <Spinner size='sm' animation='border' role='status'></Spinner>
        ) : null}
      </div>
    );
  }
};

export default StoreNavigation;
