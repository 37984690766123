export enum StorageKey {
  AuthToken = 'authToken',
  PasswordResetToken = 'passwordResetToken',
  VerificationToken = 'verificationToken',
  VerifiedEmail = 'verifiedEmail',
  VerificationType = 'verificationType',
  PaymentStatus = 'paymentStatus',
  SupportedLanguages = 'supportedLanguages',
  UtmSource = 'utmSource',
  UtmCampaign = 'utmCampaign',
}

export enum AuthenticationFlowTypes {
  StandAlone,
  Carousel,
}

// These pull double-duty: The alternate homepage and events page carousel slider are "Centered",
// the default is "FullScreen". Can come up with better names if more alternates are added,
// or
export enum FeaturedEventsCarouselType {
  FullScreen,
  Centered,
}
