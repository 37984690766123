import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from 'HiveClient/components/Icons/Icons';
import { useTranslation } from 'react-i18next';

interface Props {
  message: string;
}

const StoreItemDetailFailure = (props: Props) => {
  const { t } = useTranslation('store');

  return (
    <div className={'d-flex flex-column align-items-center py-3'}>
      <FontAwesomeIcon
        icon={faTimesCircle}
        size={'4x'}
        className={'text-danger'}
      />
      <h2 className={'mb-2 mt-3 fw-bold'}>
        {t('modal.error.heading', 'Error')}
      </h2>
      <p className={'text-dark'}>{props.message}</p>
    </div>
  );
};

export default StoreItemDetailFailure;
