import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  className?: string;
};

const ContactLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <Fragment>
      <section className={'mx-3 mb-3'}>
        <nav className={props.className}>
          <h6>{t('footer.contact-us.heading', 'Contact Us')}</h6>
          <a href={'https://help.arenaesports.ae/'} target='blank'>
            {t('footer.contact-us.help-center-link-label', 'Help Center')}
          </a>
          <a href={'https://discord.gg/HpGTYa3tCC'} target='blank'>
            {t('footer.contact-us.discord-link-label', 'Discord')}
          </a>
          <a
            href={
              'https://www.facebook.com/people/ArenaEsports/100091666666490/?mibextid=LQQJ4d'
            }
            target='blank'
          >
            {t('footer.contact-us.facebook-link-label', 'Facebook')}
          </a>
          <a href={'https://www.instagram.com/arenaesportsae/'} target='blank'>
            {t('footer.contact-us.instagram-link-label', 'Instagram')}
          </a>
        </nav>
      </section>
      <section className={'mx-3 mb-3'}>
        <nav className={props.className}>
          <h6>&nbsp;</h6>
          <a href={'https://twitter.com/ArenaEsportsAE'} target='blank'>
            {t('footer.contact-us.twitter-link-label', 'Twitter')}
          </a>
          <a href={'https://www.youtube.com/@ArenaEsportsAE'} target='blank'>
            {t('footer.contact-us.youtube-link-label', 'YouTube')}
          </a>
          <a href={'https://www.tiktok.com/@arenaesportsae'} target='blank'>
            {t('footer.contact-us.tiktok-link-label', 'Tiktok')}
          </a>
        </nav>
      </section>
    </Fragment>
  );
};

export default ContactLinks;
