import { Fragment } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button/Button';
import ProfileTile from 'src/components/ImageTile/ProfileTile';

import Routes from 'src/config/Routes/Routes';
import { useAbilityContext } from 'src/config/Casl/Can';
import { isTypenameCallback } from 'src/utilities/TSUtilities';
import { TeamProfileFragment } from 'src/generated/graphql';

import { faCog } from 'HiveClient/components/Icons/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  team: TeamProfileFragment;
  noManageRooster?: boolean;
};

const TeamRoster = ({ team, noManageRooster = false }: Props) => {
  const { t } = useTranslation('team');
  const history = useHistory();
  const ability = useAbilityContext();

  return (
    <Fragment>
      <div className={'d-flex justify-content-between'}>
        <h2 className={'my-3'}>{t('players.title')}</h2>
        {ability.can('manage', team) && !noManageRooster && (
          <div className={'d-flex flex-column justify-content-center'}>
            <Button
              semantic={'manage-team'}
              variant={'outline-primary'}
              onClick={() =>
                history.push(
                  generatePath(Routes.manageTeam, {
                    id: team.id,
                  })
                )
              }
            >
              {t('manage-roster.button.label')}
              <FontAwesomeIcon icon={faCog} className={'ms-2'} />
            </Button>
          </div>
        )}
      </div>
      <div className={'tw-flex tw-flex-wrap tw-items-start tw-mb-3 tw-gap-3'}>
        {team.groupMembers
          .map((player) => player.account)
          .filter(isTypenameCallback('Person'))
          .map((account) => (
            <ProfileTile
              responsive
              id={account.id}
              profileType={'user'}
              key={account.id}
              name={account.displayName || ''}
              avatar={account.avatarURL}
              href={generatePath(Routes.profile, {
                idOrPublicURL: account.publicURL
                  ? account.publicURL
                  : account.id,
              })}
            />
          ))}
      </div>
    </Fragment>
  );
};

export default TeamRoster;
