import { ReactNode, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  useJoinEventByInviteMutation,
  useJoinTeamByInviteMutation,
  useUpdatedEventSubscription,
} from '../../../generated/graphql';
import { useAuthContext } from '../../Authentication/AuthContext';
import AsyncView from '../../../components/AsyncView/AsyncView';
import { useToasts } from '../../../components/Toasts/ToastContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from 'HiveClient/components/Icons/Icons';
import { ClientNotificationType } from '../../../components/Notifications/NotificationTypes';

interface JoinTeamProps {
  inviteCode: string;
  title: ReactNode;
  description: ReactNode;
  variant: 'team' | 'event';
  isTeamMember?: boolean;
  onSuccess?: () => void;
}

export const JoinTeam = (props: JoinTeamProps) => {
  const { t } = useTranslation();
  const { variant, isTeamMember, onSuccess } = props;
  const { showErrorNotification, showSuccessNotification } = useToasts();
  const [{ userId }] = useAuthContext();
  const [joinEventByInvite, joinEventByInviteState] =
    useJoinEventByInviteMutation({
      onError: (error) => {
        showErrorNotification({
          heading: 'We could not join the team event due to an error.',
          body: error.message,
        });
      },
    });

  const [joined, setJoined] = useState(isTeamMember);

  useUpdatedEventSubscription({
    variables: {
      targetId: userId,
      notificationType: ClientNotificationType.JoinedEvent,
    },
    onSubscriptionData(subscription) {
      const { data } = subscription.subscriptionData;

      if (
        data?.ClientNotification.notificationType ===
        ClientNotificationType.JoinedEvent
      ) {
        showSuccessNotification({
          heading: 'Successfully entered the event',
          body: `You have successfully entered the event: ${
            (data.ClientNotification as any).fields?.eventName
          }`,
        });
        setJoined(true);
        onSuccess?.();
      }
    },
  });

  useUpdatedEventSubscription({
    variables: {
      targetId: userId,
      notificationType: ClientNotificationType.DidNotJoinEvent,
    },
    onSubscriptionData(subscription) {
      const { data } = subscription.subscriptionData;

      if (
        data?.ClientNotification.notificationType ===
        ClientNotificationType.DidNotJoinEvent
      ) {
        showErrorNotification({
          heading: 'Cannot enter the event due to an error',
          body: `Fail to enter on the event: ${
            (data.ClientNotification as any).fields?.eventName
          }`,
        });
        setJoined(false);
      }
    },
  });

  const [joinTeamByInvite, joinTeamByInviteState] = useJoinTeamByInviteMutation(
    {
      onError: (error) => {
        showErrorNotification({
          heading: 'We could not join the team due to an error.',
          body: error.message,
        });
      },
    }
  );

  const { inviteCode } = props;

  const handleJoinTeam = useCallback(() => {
    const variants = {
      event: () =>
        joinEventByInvite({ variables: { inviteCode, userId: userId! } }),
      team: () =>
        joinTeamByInvite({ variables: { inviteCode, accountId: userId! } }),
    };

    variants[variant]();
    setJoined(true);
  }, [inviteCode, joinEventByInvite, userId, joinTeamByInvite, variant]);

  return (
    <div className='tw-flex tw-flex-col tw-gap-y-5 tw-mb-4'>
      <div className='tw-flex tw-flex-col tw-gap-y-3'>
        {props.title}
        {props.description}
      </div>

      <AsyncView
        loading={
          joinEventByInviteState.loading || joinTeamByInviteState.loading
        }
      >
        <Button
          disabled={
            joinEventByInviteState.loading ||
            joinTeamByInviteState.loading ||
            joined
          }
          className='tw-max-w-[10rem]'
          onClick={() => handleJoinTeam()}
        >
          {joined ? (
            <div className='tw-flex tw-gap-x-1 tw-items-center tw-justify-center'>
              <FontAwesomeIcon icon={faCheck} />
              <span>{t('event-join.joined.label')}</span>
            </div>
          ) : (
            t('event-join.join.label')
          )}
        </Button>
      </AsyncView>
    </div>
  );
};
