import Routes from '../../config/Routes/Routes';
import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faMedal,
  faShoppingCart,
  faSignalStream,
  faHeadset,
  faHome,
  faTrophy,
  faJoystick,
  faCloud,
} from 'HiveClient/components/Icons/Icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useFeatureFlagsContext } from '../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../config/FeatureFlags/FeaturesEnum';

interface INavLink {
  url: string;
  title: string;
  icon: IconDefinition;
  semantic: string;
  external?: boolean;
  hideOnMobile?: boolean;
  hideOnHorizontal?: boolean;
  hideOnVertical?: boolean;
}

const useGetNavLinks = () => {
  const { t } = useTranslation();

  const { checkFeatureFlags } = useFeatureFlagsContext();

  let navLinks: INavLink[] = [
    {
      url: Routes.home,
      title: t('dashboard.nav-links.home'),
      icon: faHome,
      hideOnMobile: true,
      hideOnHorizontal: true,
      semantic: 'home',
    },
    {
      url: t('dashboard.nav-links.support-url'),
      external: true,
      title: t('dashboard.nav-links.support'),
      hideOnMobile: true,
      icon: faHeadset,
      semantic: 'support',
    },
  ];

  if (checkFeatureFlags(FeaturesEnum.Arcade)) {
    navLinks.push({
      url: Routes.arcade,
      title: t('dashboard.nav-links.arcade'),
      icon: faJoystick,
      semantic: 'arcade',
    });
  }

  if (!GlobalClientConfig.navigation.hideGameServers) {
    navLinks.push({
      url: Routes.serverList,
      title: t('dashboard.nav-links.game-servers'),
      hideOnMobile: true,
      icon: faCloud,
      semantic: 'servers',
    });
  }

  if (GlobalClientConfig.navigation.discordURL) {
    navLinks.push({
      url: GlobalClientConfig.navigation.discordURL,
      external: true,
      title: t('dashboard.nav-links.discord'),
      hideOnMobile: true,
      icon: faDiscord,
      semantic: 'discord',
    });
  }

  if (checkFeatureFlags(FeaturesEnum.Events)) {
    if (!GlobalClientConfig.navigation.eventCategories) {
      navLinks.push({
        url: Routes.events,
        title: t('dashboard.nav-links.events'),
        icon: faTrophy,
        semantic: 'events',
      });
    } else {
      navLinks.push(
        {
          url: Routes.tournaments,
          title: t('dashboard.nav-links.tournaments'),
          icon: faTrophy,
          semantic: 'tournaments',
        },
        {
          url: Routes.challenges,
          title: t('dashboard.nav-links.challenges', 'Challenges'),
          icon: faMedal,
          semantic: 'challenges',
        }
      );
    }
  }

  if (checkFeatureFlags(FeaturesEnum.Streams)) {
    navLinks.push({
      url: Routes.watchStreams,
      title: t('dashboard.nav-links.watch'),
      icon: faSignalStream,
      semantic: 'watch',
    });
  }

  if (checkFeatureFlags(FeaturesEnum.Store)) {
    navLinks.push({
      url: Routes.store,
      title: t('dashboard.nav-links.store'),
      icon: faShoppingCart,
      semantic: 'store',
    });
  }

  return navLinks;
};

export default useGetNavLinks;
