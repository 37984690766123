import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faImages } from '@fortawesome/pro-regular-svg-icons';
import styles from './Avatar.module.scss';
import FallbackIcon from './FallbackIcon/FallbackIcon';

export enum AvatarSizes {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type Props = {
  photo?: string | null;
  altText?: string;
  size?: AvatarSizes;
  className?: string;
  fallbackIcon?: IconDefinition;
  fullWidth?: boolean;
};

export const getSize = (size: AvatarSizes) => {
  let dimension = 8;

  switch (size) {
    case AvatarSizes.xxs:
      dimension = 1.5;
      break;
    case AvatarSizes.xs:
      dimension = 1.75;
      break;
    case AvatarSizes.sm:
      dimension = 2.625;
      break;
    case AvatarSizes.md:
      dimension = 3.5;
      break;
    case AvatarSizes.lg:
      dimension = 8;
      break;
    case AvatarSizes.xl:
      dimension = 12.8;
      break;
  }

  return dimension;
};

export const convertREMToPixelValue = (remValue: number): number =>
  // todo this currently naively assumes that the rem root is 16px this should be made more flexible
  remValue * 16;

const Avatar = ({
  photo,
  altText = 'profile avatar',
  size = AvatarSizes.lg,
  className,
  fallbackIcon,
  fullWidth,
}: Props) => (
  // Need an extra div wrapping the actual Avatar, because the padding-bottom trick doesn't
  // work as a direct child of a flex container, and if the className adds margins those shouldn't
  // be part of the padding-bottom: 100% calculation.
  <div className={[className, styles.avatarContainer].join(' ')}>
    <div
      style={{
        width: fullWidth ? '100%' : convertREMToPixelValue(getSize(size)),
        // We want to keep the aspect ratio as 1:1, so we're a padding-bottom: 100% trick.
        height: 0,
      }}
      className={`${photo ? styles.imageContainer : styles.iconContainer}`}
    >
      {photo ? (
        <img className={styles.image} src={photo} alt={altText} />
      ) : (
        <FallbackIcon
          size={getSize(size) * 0.6}
          icon={fallbackIcon ?? faImages}
        />
      )}
    </div>
  </div>
);

export default Avatar;
