import { useApolloClient } from '@apollo/client';
import {
  GetMinimalUserDocument,
  GetMinimalUserQuery,
  GetMinimalUserQueryVariables,
  useRemoveMemberFromGroupMutation,
} from '../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../pages/Authentication/AuthContext';
import { isTypename } from '../utilities/TSUtilities';
import { useToasts } from '../components/Toasts/ToastContext';

function useRemoveMemberFromTeam(
  memberId: string,
  onCompleteCallback?: () => void
) {
  const client = useApolloClient();
  const { showErrorNotification, showSuccessNotification } = useToasts();
  const [authContextState] = useAuthContext();
  const userId = authContextState.userId!;

  // // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();
  return useRemoveMemberFromGroupMutation({
    onCompleted(res) {
      // If logged in user is removing themselves, we need to remove the team from their cached user object.
      if (userId === memberId) {
        const cachedUser = client.readQuery<
          GetMinimalUserQuery,
          GetMinimalUserQueryVariables
        >({
          query: GetMinimalUserDocument,
          variables: {
            id: userId,
          },
        });

        if (cachedUser?.user && isTypename('Person', cachedUser.user)) {
          const user = cachedUser.user;

          const groupMembershipsMinusRemoved = user.groupMemberships.filter(
            (groupMembership) =>
              groupMembership.group.id !== res.removeMemberFromGroup.id
          );

          client.writeQuery<GetMinimalUserQuery, GetMinimalUserQueryVariables>({
            query: GetMinimalUserDocument,
            data: {
              user: {
                ...cachedUser.user,
                groupMemberships: groupMembershipsMinusRemoved,
              },
            },
            variables: { id: userId },
          });
        }
      }

      showSuccessNotification({
        heading: t('manage-team.remove-member-success.title'),
        body: t('manage-team.remove-member-success.body'),
      });

      if (onCompleteCallback) {
        onCompleteCallback();
      }
    },
    onError: () => {
      showErrorNotification({
        heading: t('manage-team.remove-member-failure.title'),
        body: t('manage-team.remove-member-failure.body'),
      });
    },
  });
}

export default useRemoveMemberFromTeam;
