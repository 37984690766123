import Cookies from 'js-cookie';
import i18next from 'i18next';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { CalendarSystem, Settings } from 'luxon';
import { TRANSLATIONS_SERVICE_URL } from '../config/Endpoints/Endpoints';
import {
  addToSessionStorage,
  getFromSessionStorage,
} from '../utilities/Utilities';
import { StorageKey } from '../config/Enums/App';
import TagManager from 'react-gtm-module';
import { Locale } from '../generated/graphql';

const DIRECTION_COOKIE = 'i18next_dir';
const LANGUAGE_COOKIE = 'i18next_lng';

export interface ClientLanguage {
  id: string;
  name: string;
  code: string;
  // Needs to be one of the strings in the Locale enum. We can't use the enum here directly as the value is set
  // in supported-languages.js and gets widened to 'string'.
  backendCode: string;
  // Browsers (or luxon, not sure where it's actually happening) will output dates
  // based on the language and region selected. For instance, ar-BH was outputting dates
  // using the Gregorian calendar, where ar-SA was outputting using the Islamic calendar.

  // If left undefined, the default behaviour will be used. If you need to override the
  // output for a specific language for a specific client, supply whichever CalendarSystem
  // you want for that language code.

  // This will rarely need to be set, only needed if the default calendar being used
  // isn't what the client wants.
  outputCalendar?: CalendarSystem;
  // Allows overriding the locale used to format various internationalizations.

  // If left undefined, dates will be formatted using the language code and region combination,
  // which ideally is how it should always work.

  // This override has been added because we are using `en` for english without a region, which is defaulting
  // to format dates as en-US, which is not what clients wants. Rather than rename their language pack
  // en-GB (or what-have-you) and change the BACKEND_LANGUAGE_HEADER to accept that same code and update the existing
  // backend-services-managed translations, we leave it as `en` and override the locale directly.

  // I think this is probably the wrong approach, as in a perfect world we would always be defining a region for every
  // language provided, and that region would determine date / currency / number formatting - but this is the least disruptive,
  // and does provide some flexibility whether we need it or not to format things differently than the language region
  // would.
  localeOverride?: string;
}

export interface RemoteLanguagesResponse {
  languages: ClientLanguage[];
}

export interface ILanguageService {
  switchLanguage: (lng: string) => void | Promise<void>;
  getSupportedLanguages: () => ClientLanguage[];
  getRemoteSupportedLanguages: () => Promise<RemoteLanguagesResponse>;
  getInitialLanguage: () => string;
  setInitialLanguage: () => void;
  userInitiatedLanguageSwitch: (lng: string) => void;
  getLocaleObject: <T>(fieldWithLocalizations: ILocaleObject<T>[]) => T | null;
}

const updateHTMLTag = (lng: string, direction: string) => {
  document.documentElement.setAttribute('dir', direction);
  document.documentElement.setAttribute('lang', lng);

  // Fontawesome class needed to mirror icons on rtl.
  if (direction === 'rtl') {
    document.documentElement.classList.add('fa-dir-flip');
  } else {
    document.documentElement.classList.remove('fa-dir-flip');
  }
};

// The DIRECTION_COOKIE is what nginx is looking for to serve index.rtl.html
// instead of index.ltr.html
const setLanguageCookies = (lng: string, direction: string) => {
  Cookies.set(DIRECTION_COOKIE, direction, { expires: 365 });
  Cookies.set(LANGUAGE_COOKIE, lng, { expires: 365 });
};

const removeLanguageCookies = () => {
  Cookies.remove(DIRECTION_COOKIE);
  Cookies.remove(LANGUAGE_COOKIE);
};

const getClientLanguageObjectByCode = (lng: string) =>
  GlobalClientConfig.internationalization.supportedLngs.find(
    (supportedLng) => supportedLng.code === lng
  );

const setOutputCalendar = (lng: string) => {
  const clientLanguageObject = getClientLanguageObjectByCode(lng);

  if (clientLanguageObject?.outputCalendar) {
    Settings.defaultOutputCalendar = clientLanguageObject.outputCalendar;
  }
};

// Set the luxon date locale to either the localeOverride - if provided by the selected language object - or the
// selected language code.
const setDateLocale = (lng: string) => {
  const clientLanguageObject = getClientLanguageObjectByCode(lng);

  if (clientLanguageObject?.localeOverride) {
    Settings.defaultLocale = clientLanguageObject.localeOverride;
  } else {
    Settings.defaultLocale = lng;
  }
};

const switchLanguage = (lng: string) => {
  const direction = i18next.dir(lng);
  const currentLanguage = i18next.language;

  /**
   * This happens on the initial visit.
   */
  if (lng === currentLanguage) {
    updateHTMLTag(lng, direction);
    setLanguageCookies(lng, direction);
    setDateLocale(lng);
    setOutputCalendar(lng);

    return Promise.resolve();
  }

  /**
   * We need to do a hard refresh when the language is manually changed for a couple reasons.
   *
   * 1. We need to send a language header to the backend on all requests. ApolloClient has
   *    a way to reset the cache and refetch all active queries (client.resetStore()) but
   *    I'm not sure if this works for polling / subscriptions, where we'd need the language
   *    header to change and start repolling / resubscribing. Could certainly dive into it
   *    but for now, it's much much simpler to just refresh the page so that all queries / subscriptions
   *    are kicked off with the correct language header.
   *
   * 2. If the user has selected a language read in the opposite direction from their current
   *    selection, we need to hard refresh to that the correct index.ltr.html or index.rtl.html is requested.
   */
  setLanguageCookies(lng, direction);
  window.location.reload();
};

const getDefaultLanguage = () =>
  GlobalClientConfig.internationalization.defaultLng;

const getSupportedLanguages = () =>
  GlobalClientConfig.internationalization.supportedLngs;

const getRemoteSupportedLanguages = () => {
  const supportedLanguages: ClientLanguage[] | null = getFromSessionStorage<
    ClientLanguage[]
  >(StorageKey.SupportedLanguages);

  if (supportedLanguages) {
    return Promise.resolve({
      languages: supportedLanguages,
    });
  } else {
    return fetch(
      `${TRANSLATIONS_SERVICE_URL}/clients/${
        import.meta.env.VITE_APP_CLIENT_NAME
      }/languages`,
      {
        headers: {
          method: 'GET',
        },
      }
    )
      .then((rawRes): Promise<RemoteLanguagesResponse> => {
        if (rawRes.ok) {
          return rawRes.json();
        } else {
          return Promise.reject();
        }
      })
      .then((res) => {
        addToSessionStorage(StorageKey.SupportedLanguages, res.languages);
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log('Error getting supported client languages: ', err);
        return Promise.reject();
      });
  }
};

const getLanguageFromURLParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlLanguage = urlParams.get('lng');

  if (urlLanguage) {
    return urlLanguage;
  }

  return undefined;
};

// Language cookie is source of truth if it's present.
const getInitialLanguage = () => {
  const languageCookie = Cookies.get(LANGUAGE_COOKIE);

  // If the user has a language in the URL, we use that.
  const urlLanguage = getLanguageFromURLParameters();
  if (urlLanguage) {
    return urlLanguage;
  }

  // If no cookie is set, they've never visited, so we serve the defaultLng for the client.
  if (!languageCookie) {
    return getDefaultLanguage();
  }

  // If the cookie is set, but the client no longer supports that language,
  // remove the cookie and return the client's defaultLng
  if (
    !GlobalClientConfig.internationalization.supportedLngs
      .map((lng) => lng.code)
      .includes(languageCookie)
  ) {
    removeLanguageCookies();
    return getDefaultLanguage();
  }

  // Otherwise return their selected language cookie.
  return languageCookie;
};

// Should only be called once when entering the app.
const setInitialLanguage = () => switchLanguage(getInitialLanguage());

const userInitiatedLanguageSwitch = (lng: string) => {
  if (getInitialLanguage() !== lng) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'manual_language_change',
        from_language: getInitialLanguage(),
        to_language: lng,
      },
    });

    LanguageService.switchLanguage(lng);
  }
};

export interface ILocaleObject<T> {
  locale: Locale;
  value: T;
}

// This maybe shouldn't be in the LanguageService considering it's more a utility function,
// but since it relies so much on the service methods, I'm leaving it here for now.
const getLocaleObject = <T>(fieldWithLocalizations: ILocaleObject<T>[]) => {
  const supportedLanguages = getSupportedLanguages();

  // We know this has to exist. `getInitialLanguage` will always return a string that matches
  // a `code` in one of the ClientLanguage objects returned from `getSupportedLanguages()`.
  const currentClientLanguage = supportedLanguages.find(
    (language) => language.code === getInitialLanguage()
  )!;

  // We know this *should* exist if everything was configured correctly.
  // GlobalClientConfig.internationalization.defaultLng has to match one of the supportedLanguages
  // `code` or things go very wrong for the initial visit.
  const defaultClientLanguage = supportedLanguages.find(
    (language) =>
      language.code === GlobalClientConfig.internationalization.defaultLng
  )!;

  const selectedLocaleFromField = fieldWithLocalizations.find(
    (localeObject) => localeObject.locale === currentClientLanguage.backendCode
  );

  if (selectedLocaleFromField) {
    return selectedLocaleFromField.value;
  }

  // We use this if the language selected by the user doesn't match any locale set in the backend
  // for the given field.
  const defaultLocaleFromField = fieldWithLocalizations.find(
    (localeObject) => localeObject.locale === defaultClientLanguage.backendCode
  );

  if (defaultLocaleFromField) {
    return defaultLocaleFromField.value;
  }

  // If neither the selected locale nor the default locale exist, return the first locale that does exist.
  if (fieldWithLocalizations.length) {
    return fieldWithLocalizations[0].value;
  }

  // Finally, if it's an empty array, return null.
  return null;
};

const LanguageService: ILanguageService = {
  switchLanguage,
  getSupportedLanguages,
  getInitialLanguage,
  setInitialLanguage,
  getRemoteSupportedLanguages,
  userInitiatedLanguageSwitch,
  getLocaleObject,
};

export default LanguageService;
