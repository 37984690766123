import { useEffect } from 'react';
import { useAuthContext } from '../pages/Authentication/AuthContext';
import useGetNotificationsQuery from './useGetNotificationsQuery';
import {
  AccountNotificationFragment,
  GamingAccountNotificationType,
  useNewNotificationSubscription,
} from '../generated/graphql';
import {
  sortUnexpiredNotifications,
  subscriptionHandler,
} from '../components/Notifications/NotificationHelpers';
import { useNotificationsContext } from '../components/Notifications/NotificationsContext';
import { NotificationType } from 'src/components/Notifications/NotificationTypes';

export const NOTIFICATIONS_SUBSCRIPTION_TYPES = [
  NotificationType.NewTeamInvitation,
  NotificationType.SubscriptionPayment,
  NotificationType.Payment,
  GamingAccountNotificationType.EventStartingInXAccountNotificationType,
  GamingAccountNotificationType.EventEndedAccountNotificationType,
  GamingAccountNotificationType.EventTimeChangedAccountNotificationType,
  GamingAccountNotificationType.MatchStartingInXAccountNotificationType,
  GamingAccountNotificationType.MatchTimeChangedAccountNotificationType,
  GamingAccountNotificationType.MatchEndedAccountNotificationType,
  GamingAccountNotificationType.KingOfTheHillSelectionAccountNotificationType,
  GamingAccountNotificationType.ConfirmParticipationNotificationType,
];

interface IProps {
  newSubscriptionCallback: (notification: AccountNotificationFragment) => void;
}

const useNotifications = (props: IProps) => {
  const [authContextState] = useAuthContext();
  const notificationsContext = useNotificationsContext();

  const notificationsQuery = useGetNotificationsQuery(authContextState.userId);

  useNewNotificationSubscription({
    variables: {
      notificationTypes: NOTIFICATIONS_SUBSCRIPTION_TYPES,
      personId: authContextState.userId!,
    },
    skip: !authContextState.userId,
    onSubscriptionData() {
      subscriptionHandler(notificationsQuery, props.newSubscriptionCallback);
    },
  });

  const notifications = notificationsQuery.data?.getNotifications ?? [];
  const unexpiredNotifications = sortUnexpiredNotifications(notifications);
  const unreadNotifications = unexpiredNotifications.filter(
    (notification) => !notification.read
  );

  useEffect(() => {
    notificationsContext.setNumberOfNotifications(unreadNotifications.length);
  }, [unreadNotifications.length, notificationsContext]);

  return {
    unexpiredNotifications,
  };
};

export default useNotifications;
