import { Roles } from '../components/RoleSelect/RoleSelect';

interface Team {
  groupMembers: { account: { id: string }; roles: { roleId: string }[] }[];
}

// Todo: get rid of this once I figure out how to have ability.can('manage', team) available on page load.
export const canManageTeam = (team: Team, accountId: string): boolean => {
  const member = team.groupMembers.find(
    (member) => member.account.id === accountId
  );

  const role = member?.roles.find(
    (role) => role.roleId === Roles.ADMIN || role.roleId === Roles.OWNER
  );

  return !!role;
};
