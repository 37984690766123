import styles from '../UserNavigation.module.scss';
import NavItem from '../NavItem/NavItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faLanguage,
  faSignOutAlt,
} from 'HiveClient/components/Icons/Icons';
import { Fragment, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import LanguageMenu from '../../../LanguageMenu/LanguageMenu';
import Button from '../../../Button/Button';
import { useAuthContext } from '../../../../pages/Authentication/AuthContext';
import { useLanguageContext } from '../../../LanguageMenu/LanguageContext';
import useGetUserMenuLinks from './useGetUserMenuLinks';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

interface Props {
  dismissMenu: () => void;
  handleSignOut: () => void;
  show: boolean;
}

const UserMenu = (props: Props) => {
  const { t } = useTranslation();
  const [authContextState] = useAuthContext();
  const [showNestedMenu, setShowNestedMenu] = useState<boolean>(false);

  const [languageContextState] = useLanguageContext();
  const navLinks = useGetUserMenuLinks();

  const renderMainMenu = () => {
    if (
      !authContextState.userId &&
      languageContextState.hasMultipleLanguageSupport
    ) {
      return <LanguageMenu />;
    }

    return (
      <Fragment>
        {navLinks.map((navLink) => (
          <NavItem
            tabIndex={props.show ? 0 : -1}
            key={navLink.title}
            onClick={props.dismissMenu}
            className={[styles.userMenuItem, 'btn btn-secondary'].join(' ')}
            link={navLink.url}
          >
            <FontAwesomeIcon icon={navLink.icon} className={'me-2'} />
            <span>{navLink.title}</span>
          </NavItem>
        ))}
        {languageContextState.hasMultipleLanguageSupport &&
          GlobalClientConfig.nav.orientation === 'horizontal' && (
            <Button
              semantic={'language-toggle'}
              tabIndex={props.show ? 0 : -1}
              onClick={() => setShowNestedMenu(true)}
              variant={'secondary'}
              className={[
                styles.userMenuItem,
                'p-2 justify-content-between',
              ].join(' ')}
            >
              <span>
                <FontAwesomeIcon icon={faLanguage} className={'me-2'} />
                <span>{t('dashboard.nav-links.language')}</span>
              </span>
              <span>
                <FontAwesomeIcon
                  className={'align-self-end'}
                  icon={faChevronRight}
                />
              </span>
            </Button>
          )}

        <NavDropdown.Item
          tabIndex={props.show ? 0 : -1}
          onClick={() => props.handleSignOut()}
          className={[styles.userMenuItem, 'p-2 btn btn-primary fw-bold'].join(
            ' '
          )}
        >
          <FontAwesomeIcon icon={faSignOutAlt} className={'me-2'} />
          {t('authenticated-nav.log-out')}
        </NavDropdown.Item>
      </Fragment>
    );
  };

  const renderNestedMenu = () => (
    <div>
      <Button
        semantic={'language-select-back-button'}
        variant={'hollow'}
        className={'d-flex lh-1 ps-0'}
        onClick={() => setShowNestedMenu(false)}
      >
        <span className={'me-2'}>
          <FontAwesomeIcon icon={faChevronLeft} className={'mb-3'} />
        </span>
        <span>{t('dashboard.back.label')}</span>
      </Button>
      <LanguageMenu />
    </div>
  );

  return (
    <Fragment>
      {
        // todo perhaps make a basic routing container without the need of having url changes if we start nesting deeper
        !showNestedMenu ? renderMainMenu() : renderNestedMenu()
      }
    </Fragment>
  );
};

export default UserMenu;
