import { createContext, useContext, useState } from 'react';
import { FeaturesEnum } from './FeaturesEnum';

export interface FeatureFlagsConfig {
  [FeaturesEnum.Store]: boolean;
  [FeaturesEnum.Arcade]: boolean;
  [FeaturesEnum.Streams]: boolean;
  [FeaturesEnum.Wallet]: boolean;
  [FeaturesEnum.AllowAddingCurrency]: boolean;
  [FeaturesEnum.Keycloak]: boolean;
  [FeaturesEnum.OnboardingEvent]: boolean;
  [FeaturesEnum.StreamsOnHomePage]: boolean;
  [FeaturesEnum.UpcomingMatchTimes]?: boolean;
  [FeaturesEnum.SubscriptionsUpgrade]?: boolean;
  [FeaturesEnum.SubscriptionsUnsubscribe]?: boolean;
  [FeaturesEnum.Subscriptions]: boolean;
  [FeaturesEnum.CheckinIndicators]?: boolean;
  [FeaturesEnum.FeaturedStoreHomepage]?: boolean;
  [FeaturesEnum.StoreNavigation]?: boolean;
  [FeaturesEnum.Events]: boolean;
  [FeaturesEnum.RemoteLocalizations]?: boolean;
  [FeaturesEnum.HeaderIcons]?: boolean;
  [FeaturesEnum.StoreBrowseByGamesToBottom]?: boolean;
  [FeaturesEnum.StoreBanners]?: boolean;
  [FeaturesEnum.StoreProfile]?: boolean;
  [FeaturesEnum.FooterIcons]?: boolean;
}
export interface FeatureFlagsContextInterface {
  updateFeatureFlags: (newFeatureFlags: FeatureFlagsConfig) => void;
  checkFeatureFlags: (featureFlag: FeaturesEnum | FeaturesEnum[]) => boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextInterface>({
  updateFeatureFlags: (_: FeatureFlagsConfig) => {},
  checkFeatureFlags: (_: FeaturesEnum | FeaturesEnum[]) => false,
});

export const FeatureFlagsContextProvider = ({ children, value }: any) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsConfig>(
    value.featureFlags
  );

  const updateFeatureFlags = (newFeatureFlags: FeatureFlagsConfig) => {
    setFeatureFlags((previousFeatureFlags) => ({
      ...previousFeatureFlags,
      ...newFeatureFlags,
    }));
  };

  const checkFeatureFlags = (featureFlag: FeaturesEnum | FeaturesEnum[]) => {
    if (Array.isArray(featureFlag)) {
      return featureFlag.every((flag) => featureFlags[flag] === true);
    }
    return featureFlags[featureFlag] ?? false;
  };

  return (
    <FeatureFlagsContext.Provider
      value={{
        updateFeatureFlags,
        checkFeatureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
