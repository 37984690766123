import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FallbackIcon.module.scss';

interface Props {
  size: number;
  icon: IconDefinition;
  className?: string;
}
const FallbackIcon = ({ size, icon, className }: Props) => (
  <div className={styles.container}>
    <FontAwesomeIcon
      style={{ fontSize: `${size}rem` }}
      icon={icon}
      className={[className, styles.icon].join(' ')}
    />
  </div>
);

export default FallbackIcon;
