import ReactSelect, {
  components,
  GroupBase,
  OptionProps,
  Props,
} from 'react-select';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';

import { AccountProjectionFragment } from '../../generated/graphql';

import DisplayName from '../DisplayName/DisplayName';

import ProfileAvatar from '../Avatar/ProfileAvatar';
import { AvatarSizes } from '../Avatar/Avatar';
import {
  faChevronDown,
  faSearch,
  faUser,
} from 'HiveClient/components/Icons/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoreItemSortingOptions } from 'src/pages/Store/StoreFilters/StoreItemSort';

import styles from './ReactSelect.module.scss';

export type GameOptionData = {
  label: string;
  value: string;
  game: string;
};

export const GameOption = (props: OptionProps<GameOptionData, true>) => {
  const { data } = props;

  return (
    <components.Option
      {...props}
      className={[
        styles.option,
        props.isSelected ? styles.optionActive : '',
      ].join(' ')}
    >
      {data.label}
    </components.Option>
  );
};

export const AccountOption = (
  props: OptionProps<AccountProjectionFragment, false>
) => {
  const { data } = props;

  return (
    <components.Option
      {...props}
      className={[
        styles.option,
        props.isSelected ? styles.optionActive : '',
      ].join(' ')}
    >
      <div className={'d-flex align-items-center m-2'}>
        <ProfileAvatar
          photo={data.avatarURL}
          size={AvatarSizes.md}
          fallbackIcon={faUser}
        />
        <div
          className={['d-flex', 'flex-grow-1', 'flex-column', 'mx-3'].join(' ')}
        >
          <span className={'lh-0 copy-large'}>
            <DisplayName name={data.displayName ?? ''} isVIP={false} />
          </span>
        </div>
      </div>
    </components.Option>
  );
};

export type StoreSortData = {
  label: string;
  value: string;
  sortingOption: StoreItemSortingOptions;
};

export const Option = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
) => (
  <components.Option
    {...props}
    className={[
      styles.option,
      props.isSelected ? styles.optionActive : '',
    ].join(' ')}
  >
    {props.label}
  </components.Option>
);

export const SingleValue: typeof components.SingleValue = (props) => (
  <components.SingleValue {...props} className={styles.singleValue} />
);

export const Input: typeof components.Input = (props) => (
  <components.Input {...props} className={styles.input} />
);

export const Control: typeof components.Control = (props) => (
  <components.Control
    {...props}
    className={props.isMulti ? styles.multiValueControl : styles.control}
  />
);

export const Menu: typeof components.Menu = (props) => (
  <components.Menu {...props} className={styles.menu} />
);

export const Placeholder: typeof components.Placeholder = (props) => (
  <components.Placeholder {...props} className={styles.placeholder} />
);

export const IndicatorSeparator: typeof components.IndicatorSeparator = (
  props
) => (
  <components.IndicatorSeparator
    {...props}
    className={styles.indicatorSeparator}
  />
);

export const SearchIndicator: typeof components.DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faSearch} className={styles.dropdownIndicator} />
  </components.DropdownIndicator>
);

export const DropdownIndicator: typeof components.DropdownIndicator = (
  props
) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon
      icon={faChevronDown}
      className={styles.dropdownIndicator}
    />
  </components.DropdownIndicator>
);

export const MultiValueContainer: typeof components.MultiValueContainer = (
  props
) => (
  <div className={styles.multiValueContainer}>
    <components.MultiValueContainer {...props}>
      {props.children}
    </components.MultiValueContainer>
  </div>
);

export function Select<
  O,
  IsMulti extends boolean = false,
  Group extends GroupBase<O> = GroupBase<O>
>(props: Props<O, IsMulti, Group>) {
  return (
    <ReactSelect
      {...props}
      components={{
        Option,
        Control,
        Menu,
        Input,
        MultiValueContainer,
        DropdownIndicator,
        Placeholder,
        IndicatorSeparator,
        SingleValue,
        ...props.components,
      }}
    />
  );
}

export function AsyncSelect<
  O,
  IsMulti extends boolean = false,
  Group extends GroupBase<O> = GroupBase<O>
>(props: AsyncProps<O, IsMulti, Group>) {
  return (
    <ReactAsyncSelect
      {...props}
      components={{
        Option,
        Control,
        Menu,
        MultiValueContainer,
        Input,
        DropdownIndicator,
        Placeholder,
        IndicatorSeparator,
        SingleValue,
        ...props.components,
      }}
    />
  );
}
