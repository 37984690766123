import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { NotificationBannerProps } from '../NotificationBanner/NotificationBanner';

export type INotificationBannerContext = NotificationBannerProps | null;

const NotificationBannerContext = createContext<
  | [
      INotificationBannerContext,
      Dispatch<SetStateAction<INotificationBannerContext>>
    ]
  | undefined
>(undefined);

export const NotificationBannerContextProvider = ({ children }: any) => {
  const notificationBannerContext = useState<NotificationBannerProps | null>(
    null
  );

  return (
    <NotificationBannerContext.Provider value={notificationBannerContext}>
      {children}
    </NotificationBannerContext.Provider>
  );
};

export const useNotificationBannerContext = () => {
  const context = useContext(NotificationBannerContext);

  if (!context) {
    throw new Error(
      'useNotificationBannerContext must be used within a <NotificationBannerContextProvider'
    );
  }

  return context;
};
export default NotificationBannerContext;
