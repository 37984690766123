import { Dispatch, lazy, SetStateAction, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Routes from './config/Routes/Routes';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { TeamAdminParam } from './pages/ManageTeam/ManageTeam';
import { isAllowedToManageTeam } from './utilities/PrivateRouteConditions';
import { State } from './App';
import { getFromlocalStorage } from './utilities/Utilities';
import { TokenResponse } from './pages/Authentication/AuthenticationService';
import { AuthenticationFlowTypes, StorageKey } from './config/Enums/App';
import FourOhFour from './pages/FourOhFour/FourOhFour';
import ClientRoutes from 'HiveClient/config/Routes/Routes';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useFeatureFlagsContext } from './config/FeatureFlags/FeatureFlagsContext';
import StoreItemDetail from './pages/Store/StoreItem/StoreItemDetail';
import TeamInviteLink from './pages/InviteLink/TeamInviteLink';
import EventTeamInviteLink from './pages/InviteLink/EventTeamInviteLink';
import { useAuthContext } from './pages/Authentication/AuthContext';
import useTrackPreviousRoute from './hooks/useTrackPreviousRoute';
import { FeaturesEnum } from './config/FeatureFlags/FeaturesEnum';

interface Props {
  appState: State;
  setAppState: Dispatch<SetStateAction<State>>;
}

const ManageTeam = lazy(() => import('./pages/ManageTeam/ManageTeam'));
const Home = lazy(() => import('./pages/Home/Home'));
const CompleteRegistration = lazy(
  () => import('./pages/Authentication/AccountVerified/CompleteRegistration')
);
const Events = lazy(() => import('./pages/Events/Events'));
const EventDetail = lazy(
  () => import('./pages/Events/EventDetail/EventDetailContainer')
);
const Series = lazy(() => import('./pages/Series/Series'));
const OnboardingEvent = lazy(
  () => import('./pages/Events/OnboardingEvent/OnboardingEventDetailContainer')
);
const ServerList = lazy(() => import('./pages/ServerList/ServerList'));
const WatchStreams = lazy(() => import('./pages/WatchStreams/WatchStreams'));
const WatchStream = lazy(
  () => import('./pages/WatchStreams/WatchStream/WatchStream')
);
const UserProfile = lazy(() => import('./pages/Profile/UserProfile'));
const MyProfile = lazy(() => import('./pages/Profile/MyProfile'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const Wallet = lazy(() => import('./pages/Wallet/Wallet'));
const MyTeams = lazy(() => import('./pages/MyTeams/MyTeams'));
const CreateTeam = lazy(() => import('./pages/CreateTeam/CreateTeam'));
const Team = lazy(() => import('./pages/Team/Team'));
const ConnectGameAccounts = lazy(
  () => import('./pages/ConnectGameAccounts/ConnectGameAccounts')
);
const Store = lazy(() => import('./pages/Store/Store'));
const StoreCategoryList = lazy(() => import('./pages/Store/StoreCategoryList'));
const StoreCategory = lazy(() => import('./pages/Store/StoreCategory'));
const StoreProductGroupSearchResults = lazy(
  () => import('./pages/Store/StoreSearch/StoreProductGroupSearchResults')
);
const Arcade = lazy(() => import('./pages/Arcade/Arcade'));
const NewSubscription = lazy(
  () => import('./pages/NewSubscription/NewSubscription')
);
const ManageSubscription = lazy(
  () => import('HiveClient/pages/ManageSubscription/ManageSubscription')
);

const SignInByToken = lazy(
  () => import('./pages/Authentication/Signin/Token/SignInByToken')
);

const SignIn = (() => {
  switch (GlobalClientConfig.authenticationFlowType) {
    case AuthenticationFlowTypes.StandAlone:
      return lazy(
        () =>
          import('./pages/Authentication/Signin/StandAlone/StandAloneSignIn')
      );

    case AuthenticationFlowTypes.Carousel:
      return lazy(
        () => import('./pages/Authentication/Signin/Carousel/CarouselSignIn')
      );

    default:
      return lazy(
        () =>
          import('./pages/Authentication/Signin/StandAlone/StandAloneSignIn')
      );
  }
})();

const Registration = lazy(
  () => import('./pages/Authentication/Registration/Registration')
);

const Msisdn = (() => {
  switch (GlobalClientConfig.authenticationFlowType) {
    case AuthenticationFlowTypes.StandAlone:
      return lazy(
        () =>
          import('./pages/Authentication/Msisdn/StandAlone/StandAloneMsisdn')
      );

    case AuthenticationFlowTypes.Carousel:
      return lazy(
        () => import('./pages/Authentication/Msisdn/Carousel/CarouselMsisdn')
      );

    default:
      return lazy(
        () =>
          import('./pages/Authentication/Msisdn/StandAlone/StandAloneMsisdn')
      );
  }
})();

const SMSVerification = (() => {
  switch (GlobalClientConfig.authenticationFlowType) {
    case AuthenticationFlowTypes.StandAlone:
      return lazy(
        () =>
          import(
            './pages/Authentication/SMSVerification/StandAlone/StandAloneSMSVerification'
          )
      );

    case AuthenticationFlowTypes.Carousel:
      return lazy(
        () =>
          import(
            './pages/Authentication/SMSVerification/Carousel/CarouselSMSVerification'
          )
      );

    default:
      return lazy(
        () =>
          import(
            './pages/Authentication/SMSVerification/StandAlone/StandAloneSMSVerification'
          )
      );
  }
})();

const ConnectGameAccount = (() => {
  switch (GlobalClientConfig.authenticationFlowType) {
    case AuthenticationFlowTypes.StandAlone:
      return lazy(
        () =>
          import(
            './pages/ConnectGameAccounts/ConnectGameAccount/StandAlone/StandAloneConnectAccount'
          )
      );

    case AuthenticationFlowTypes.Carousel:
      return lazy(
        () =>
          import(
            './pages/ConnectGameAccounts/ConnectGameAccount/Carousel/CarouselConnectAccount'
          )
      );

    default:
      return lazy(
        () =>
          import(
            './pages/ConnectGameAccounts/ConnectGameAccount/StandAlone/StandAloneConnectAccount'
          )
      );
  }
})();

const ResetPassword = lazy(
  () => import('./pages/Authentication/ResetPassword/ResetPassword')
);

const ForgotPassword = lazy(
  () => import('./pages/Authentication/ForgotPassword/ForgotPassword')
);

const MyOrders = lazy(() => import('./pages/Store/MyOrders/MyOrders'));
const OrderDetail = lazy(() => import('./pages/Store/MyOrders/OrderDetail'));

const Router = ({ appState, setAppState }: Props) => {
  const [authContextState] = useAuthContext();
  const client = useApolloClient();
  const token = getFromlocalStorage<TokenResponse>(StorageKey.AuthToken);
  const accountId = token?.accountId;
  const history = useHistory();
  const routeTracking = useTrackPreviousRoute();

  const { checkFeatureFlags } = useFeatureFlagsContext();

  const search = history.location.search;

  const enableNavigation = useCallback(
    () =>
      setAppState((prevState) => ({
        ...prevState,
        preventNavigation: false,
      })),
    [setAppState]
  );

  const isAllowedToManageTeamCallback = useCallback<
    (params: TeamAdminParam) => Promise<null | string>
  >(
    (params) => isAllowedToManageTeam(client, params, accountId),
    [accountId, client]
  );

  const locationState: any = history.location.state;

  const authenticationRoutes = () => {
    if (authContextState.userId) {
      return [
        <Redirect
          key={Routes.signIn}
          from={Routes.signIn}
          to={
            locationState?.from?.pathname ??
            routeTracking.previousRoute?.path ??
            Routes.home
          }
        />,
        <Redirect
          key={Routes.completeRegistration}
          from={Routes.completeRegistration}
          to={Routes.home}
        />,
        <Redirect
          key={Routes.createAccount}
          path={Routes.createAccount}
          to={Routes.home}
        />,
        <Redirect
          key={Routes.forgotPassword}
          path={Routes.forgotPassword}
          to={Routes.home}
        />,
      ];
    } else {
      return [
        <Route key={Routes.signIn} path={Routes.signIn} component={SignIn} />,
        <Route
          key={Routes.createAccount}
          path={Routes.createAccount}
          component={Registration}
        />,
        <Route
          key={Routes.forgotPassword}
          path={Routes.forgotPassword}
          component={ForgotPassword}
        />,
        ...(GlobalClientConfig.allowTokenLogin
          ? [
              <Route
                key={Routes.signInWithToken}
                path={Routes.signInWithToken}
                component={SignInByToken}
              />,
            ]
          : []),
      ];
    }
  };

  return (
    <Switch>
      {authenticationRoutes()}
      <Route
        key={Routes.resetPassword}
        path={Routes.resetPassword}
        component={ResetPassword}
      />
      <Route
        key={Routes.completeRegistration}
        path={Routes.completeRegistration}
      >
        <CompleteRegistration setAppState={setAppState} />
      </Route>
      <Route key={Routes.msisdn} path={Routes.msisdn}>
        <Msisdn
          msisdn={appState.msisdn}
          msisdnVerified={appState.msisdnVerified}
          setAppState={setAppState}
        />
      </Route>
      <Route key={Routes.verifySMS} path={Routes.verifySMS}>
        <SMSVerification
          msisdn={appState.msisdn}
          msisdnVerified={appState.msisdnVerified}
        />
      </Route>
      <Route key={Routes.series} path={Routes.series} component={Series} />

      {checkFeatureFlags(FeaturesEnum.Events) && [
        <PrivateRoute
          key={Routes.teamInviteLink}
          path={Routes.teamInviteLink}
          component={TeamInviteLink}
          redirectPath={Routes.signIn}
        />,
        <PrivateRoute
          key={Routes.eventTeamInviteLink}
          path={Routes.eventTeamInviteLink}
          component={EventTeamInviteLink}
          redirectPath={Routes.signIn}
        />,
        <Route
          exact
          key={Routes.events}
          path={Routes.events}
          component={Events}
        />,
        <Route
          key={Routes.event}
          path={Routes.event}
          component={EventDetail}
        />,
        ...(checkFeatureFlags(FeaturesEnum.OnboardingEvent)
          ? [
              <PrivateRoute
                key={Routes.onboardingEvent}
                path={Routes.onboardingEvent}
                component={OnboardingEvent}
                redirectPath={Routes.home}
              />,
            ]
          : []),
        ...(GlobalClientConfig.navigation.eventCategories
          ? [
              <Route
                exact
                key={Routes.tournaments}
                path={Routes.tournaments}
                component={Events}
              />,
              <Route
                exact
                key={Routes.challenges}
                path={Routes.challenges}
                component={Events}
              />,
            ]
          : []),
        <PrivateRoute
          exact
          key={Routes.createTeam}
          path={Routes.createTeam}
          component={CreateTeam}
          redirectPath={Routes.signIn}
        />,
        <PrivateRoute<TeamAdminParam>
          key={Routes.manageTeam}
          path={Routes.manageTeam}
          component={ManageTeam}
          redirectPath={Routes.signIn}
          condition={isAllowedToManageTeamCallback}
          componentProps={{
            enableNavigation,
          }}
        />,
        <Route
          exact
          key={Routes.viewTeam}
          path={Routes.viewTeam}
          component={Team}
        />,
        <PrivateRoute
          key={Routes.connectGameAccount}
          path={Routes.connectGameAccount}
          component={ConnectGameAccount}
          redirectPath={Routes.signIn}
        />,
        <PrivateRoute
          key={Routes.connectGameAccounts}
          path={Routes.connectGameAccounts}
          component={ConnectGameAccounts}
          redirectPath={Routes.signIn}
          exact
        />,

        <PrivateRoute
          exact
          key={Routes.myTeams}
          path={Routes.myTeams}
          component={MyTeams}
          redirectPath={Routes.signIn}
          componentProps={{
            enableNavigation,
          }}
        />,
      ]}

      <Route
        key={Routes.serverList}
        path={Routes.serverList}
        component={ServerList}
      />
      {checkFeatureFlags(FeaturesEnum.Streams) && [
        <Route
          exact
          key={Routes.watchStream}
          path={Routes.watchStream}
          component={WatchStream}
        />,
        <Route
          exact
          key={Routes.watchStreams}
          path={Routes.watchStreams}
          component={WatchStreams}
        />,
      ]}
      <Route
        exact
        key={Routes.profile}
        path={Routes.profile}
        component={UserProfile}
      />
      <PrivateRoute
        exact
        key={Routes.myProfile}
        path={Routes.myProfile}
        component={MyProfile}
        redirectPath={Routes.signIn}
      />
      <PrivateRoute
        key={Routes.settings}
        path={Routes.settings}
        component={Settings}
        redirectPath={Routes.signIn}
        componentProps={{
          setAppState,
        }}
      />
      {checkFeatureFlags(FeaturesEnum.Wallet) && (
        <PrivateRoute
          exact
          key={Routes.wallet}
          path={Routes.wallet}
          component={Wallet}
          redirectPath={Routes.signIn}
        />
      )}
      {checkFeatureFlags(FeaturesEnum.Store) && [
        <Route
          exact
          key={Routes.store}
          path={Routes.store}
          component={Store}
        />,
        <Route
          exact
          key={Routes.store}
          path={Routes.store}
          component={Store}
        />,
        <Redirect
          key={`/rewards`}
          from={'/rewards'}
          to={{ pathname: Routes.store, search }}
        />,
        <Redirect
          key={'/my-rewards'}
          from={'/my-rewards'}
          to={{ pathname: Routes.myOrders, search }}
        />,
        <Route
          exact
          key={Routes.storeCategoryList}
          path={Routes.storeCategoryList}
          component={StoreCategoryList}
        />,
        <Route
          exact
          key={Routes.storeItemSearchResults}
          path={Routes.storeItemSearchResults}
          component={StoreProductGroupSearchResults}
        />,
        <Route
          exact
          key={Routes.storeCategory}
          path={Routes.storeCategory}
          component={StoreCategory}
        />,
        <Route
          exact
          key={Routes.storeCategoryItem}
          path={Routes.storeCategoryItem}
          component={StoreItemDetail}
        />,
        <PrivateRoute
          exact
          key={Routes.myOrders}
          path={Routes.myOrders}
          component={MyOrders}
          redirectPath={Routes.signIn}
        />,
        <Route
          exact
          key={Routes.orderDetail}
          path={Routes.orderDetail}
          component={OrderDetail}
        />,
      ]}
      {checkFeatureFlags(FeaturesEnum.Arcade) && (
        <Route
          exact
          key={Routes.arcade}
          path={Routes.arcade}
          component={Arcade}
        />
      )}
      {checkFeatureFlags(FeaturesEnum.Subscriptions) && [
        <PrivateRoute
          key={Routes.subscriptions}
          path={Routes.subscriptions}
          component={ManageSubscription}
          redirectPath={Routes.signIn}
          exact
        />,
        <PrivateRoute
          key={Routes.subscribe}
          path={Routes.subscribe}
          component={NewSubscription}
          redirectPath={Routes.signIn}
          exact
        />,
      ]}
      {ClientRoutes.map((ClientRoute) =>
        ClientRoute.redirectPath ? (
          <PrivateRoute
            key={ClientRoute.path}
            path={ClientRoute.path}
            component={ClientRoute.component}
            redirectPath={ClientRoute.redirectPath}
            exact
          />
        ) : (
          <Route
            key={ClientRoute.path}
            path={ClientRoute.path}
            component={ClientRoute.component}
          />
        )
      )}
      {GlobalClientConfig.homeRedirectTo && (
        <Redirect
          key={'homeRedirectTo'}
          from={Routes.home}
          to={{ pathname: GlobalClientConfig.homeRedirectTo, search }}
        />
      )}
      <Route
        key={Routes.home}
        exact={true}
        path={Routes.home}
        component={Home}
      />
      <Route key='FourOhFour' component={FourOhFour} />
    </Switch>
  );
};

export default Router;
