import { useEffect } from 'react';
import { addTolocalStorage } from '../utilities/Utilities';
import { StorageKey } from '../config/Enums/App';
import {
  SocialAuthRedirectOptions,
  SocialAuthRedirectQueryParams,
} from '../config/Enums/QueryParams';
import Routes from '../config/Routes/Routes';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../pages/Authentication/AuthContext';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useQueryParams } from 'use-query-params';
import { getRedirectDetails } from '../services/RedirectPathService';
import { useToasts } from '../components/Toasts/ToastContext';

const useHandleParamsFromSocialRedirect = (
  handleUserRedirect: (to: string, state?: any) => void
) => {
  const { t } = useTranslation();
  const { showErrorNotification, showSuccessNotification } = useToasts();
  const [urlQuery] = useQueryParams(SocialAuthRedirectQueryParams);
  const [authContextState, setAuthContextState] = useAuthContext();

  useEffect(() => {
    const {
      accountId: accountIdURLQuery,
      refreshToken: refreshTokenURLQuery,
      token: tokenURLQuery,
      socialAuthError: socialAuthErrorURLQuery,
      steamAccountLinkError: steamAccountLinkErrorURLQuery,
      riotAccountLinkError: riotAccountLinkErrorURLQuery,
    } = urlQuery;

    const handleSocialAuthError = (socialAuthError: string) => {
      const socialAuthErrorMatch = Object.values(
        SocialAuthRedirectOptions
      ).find((value) => value.toLowerCase() === socialAuthError);

      let notification: {
        heading: string;
        message: string;
      } = {
        heading: 'Failed To Authenticate',
        message: 'An unexpected error occurred.',
      };

      // todo need to extract this... also need to find a better solution for when more providers come into play
      // wouldn't be able to figure out which provider this came from given the current info
      switch (socialAuthErrorMatch) {
        case SocialAuthRedirectOptions.USER_EXISTS_ALREADY_WITH_OTHER_LOGIN:
          notification = {
            heading: t('home.social-auth-error-notifications.title'),
            message: t(
              'home.social-auth-error-notifications.another-account.body'
            ),
          };
          break;
        case SocialAuthRedirectOptions.CANNOT_AUTHENTICATE_WITH_PROVIDER:
          notification = {
            heading: t('home.social-auth-error-notifications.title'),
            message: t(
              'home.social-auth-error-notifications.cannot-authenticate-with-provider.body'
            ),
          };
          break;
        case SocialAuthRedirectOptions.AUTH_FAILED:
          notification = {
            heading: t('home.social-auth-error-notifications.title'),
            message: t('home.social-auth-error-notifications.auth-failed.body'),
          };
          break;
        case SocialAuthRedirectOptions.ALREADY_ASSOCIATED:
          notification = {
            heading: t('home.social-auth-error-notifications.title'),
            message: t(
              'home.social-auth-error-notifications.already-associated.body',
              {
                clientName: GlobalClientConfig.clientName,
              }
            ),
          };
          break;
        case SocialAuthRedirectOptions.EMAIL_NOT_PROVIDED:
          notification = {
            heading: t('home.social-auth-error-notifications.title'),
            message: t('home.social-auth-error-notifications.no-email.body'),
          };
          break;
      }

      showErrorNotification({
        heading: notification.heading,
        body: notification.message,
      });
      // todo this could technically becoming from create account
      handleUserRedirect(Routes.signIn);
      return;
    };

    const handleSteamAccountLinkError = (steamAccountLinkError: string) => {
      const steamAccountLinkErrorMatch = Object.values(
        SocialAuthRedirectOptions
      ).find((value) => value.toLowerCase() === steamAccountLinkError);

      let notification: {
        heading: string;
        message: string;
      } = {
        heading: 'Failed To Authenticate',
        message: 'An unexpected error occurred.',
      };

      // todo need to extract this... also need to find a better solution for when more providers come into play
      // wouldn't be able to figure out which provider this came from given the current info
      switch (steamAccountLinkErrorMatch) {
        case SocialAuthRedirectOptions.USER_EXISTS_ALREADY_WITH_OTHER_LOGIN:
          notification = {
            heading: t('home.steam-auth-error-notifications.title'),
            message: t(
              'home.steam-auth-error-notifications.another-account.body'
            ),
          };
          break;
        case SocialAuthRedirectOptions.CANNOT_AUTHENTICATE_WITH_PROVIDER:
          notification = {
            heading: t('home.steam-auth-error-notifications.title'),
            message: t(
              'home.steam-auth-error-notifications.cannot-authenticate-with-provider.body'
            ),
          };
          break;
        case SocialAuthRedirectOptions.AUTH_FAILED:
          notification = {
            heading: t('home.steam-auth-error-notifications.title'),
            message: t('home.steam-auth-error-notifications.auth-failed.body'),
          };
          break;
      }

      showErrorNotification({
        heading: notification.heading,
        body: notification.message,
      });
      // todo this could technically becoming from create account
      handleUserRedirect(Routes.signIn);
      return;
    };

    const handleRiotAccountLinkError = (riotAccountLinkError: string) => {
      const riotAccountLinkErrorMatch = Object.values(
        SocialAuthRedirectOptions
      ).find((value) => value.toLowerCase() === riotAccountLinkError);

      let notification: {
        heading: string;
        message: string;
      } = {
        heading: 'Failed To Authenticate',
        message: 'An unexpected error occurred.',
      };

      switch (riotAccountLinkErrorMatch) {
        case SocialAuthRedirectOptions.USER_EXISTS_ALREADY_WITH_OTHER_LOGIN:
          notification = {
            heading: t('home.riot-auth-error-notifications.title'),
            message: t(
              'home.riot-auth-error-notifications.another-account.body'
            ),
          };
          break;
      }

      showErrorNotification({
        heading: notification.heading,
        body: notification.message,
      });
      // todo this could technically becoming from create account
      handleUserRedirect(Routes.signIn);
      return;
    };

    const handleTokenFromSocialRedirect = (
      token: string,
      refreshToken: string,
      accountId: string
    ) => {
      // Whether logging in / registering / manually connecting a social account, the redirect URL
      // back into hive is the same. If no authContextState.userId exists already when they come back in,
      // it means they logged in.

      // If they do already have an authContextState.userId, it means they were already logged in when authorizing
      // with social auth, which means they manually connected at their /settings page.

      // So we handle these two scenarios differently. If the user has registered, we don't handle that here, it's caught
      // elsewhere based on the fact that the user has no displayName set.
      if (!authContextState.userId) {
        addTolocalStorage(StorageKey.AuthToken, {
          accessToken: token,
          refreshToken: {
            token: refreshToken,
          },
          accountId: accountId,
        });

        setAuthContextState((prevState) => ({
          ...prevState,
          userId: accountId,
        }));

        const route = getRedirectDetails();

        if (route?.path) {
          handleUserRedirect(route.path);
        } else {
          handleUserRedirect(Routes.home);
        }
      } else {
        showSuccessNotification({
          heading: t('home.social-connect-success.title'),
          body: t('home.social-connect-success.body'),
        });

        handleUserRedirect(Routes.settings);
      }
    };

    if (socialAuthErrorURLQuery) {
      handleSocialAuthError(socialAuthErrorURLQuery);
    }

    if (steamAccountLinkErrorURLQuery) {
      handleSteamAccountLinkError(steamAccountLinkErrorURLQuery);
    }

    if (riotAccountLinkErrorURLQuery) {
      handleRiotAccountLinkError(riotAccountLinkErrorURLQuery);
    }

    if (tokenURLQuery && refreshTokenURLQuery && accountIdURLQuery) {
      handleTokenFromSocialRedirect(
        tokenURLQuery,
        refreshTokenURLQuery,
        accountIdURLQuery
      );
    }
  }, [
    urlQuery,
    handleUserRedirect,
    setAuthContextState,
    t,
    authContextState.userId,
    showSuccessNotification,
    showErrorNotification,
  ]);
};

export default useHandleParamsFromSocialRedirect;
