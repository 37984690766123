import { SocialLink, SocialLinkType } from 'src/generated/graphql';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { meetsValidUrlRequirement } from 'src/utilities/Utilities';

interface IProfileSocialLinksProps {
  socialLinks: SocialLink[];
}

const ProfileSocialLinks = (props: IProfileSocialLinksProps) => (
  <div className={'d-flex justify-content-center'}>
    {props.socialLinks
      .filter((socialLink) => meetsValidUrlRequirement(socialLink.link))
      .map((socialLink) => (
        <Button
          semantic={'profile-social-link'}
          key={socialLink.socialLinkType}
          as={'a'}
          href={socialLink.link}
          variant={'dark'}
          target={'_blank'}
          className={'mx-2 p-2'}
          data-cy={socialLink.socialLinkType}
        >
          <FontAwesomeIcon
            icon={(() => {
              switch (socialLink.socialLinkType) {
                case SocialLinkType.Facebook:
                  return faFacebook;
                case SocialLinkType.Instagram:
                  return faInstagram;
                case SocialLinkType.Twitter:
                  return faTwitter;
                default:
                  return faQuestion;
              }
            })()}
          />
        </Button>
      ))}
  </div>
);

export default ProfileSocialLinks;
