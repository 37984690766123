import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PaymentMethodImage.module.scss';
import { faMoneyBill } from '@fortawesome/pro-regular-svg-icons';
import PointsImage from 'HiveClient/images/payments/Points.png';

type PaymentMethodImageProps = {
  paymentMethod: {
    name: string;
    imageUrl?: string;
  };
};

export const PaymentMethodImage: React.FC<PaymentMethodImageProps> = ({
  paymentMethod: { name, imageUrl },
}) => {
  if (imageUrl) {
    return (
      <img
        className='tw-PaymentIcon tw-place-self-start tw-rounded tw-self-stretch tw-max-w-[135px] tw-max-h-[45px]'
        src={imageUrl}
        alt={name}
      />
    );
  }

  // TODO: Remove this when points are refactored to come as a payment method from the backend
  if (name.includes('Points')) {
    return (
      <img
        className='tw-PaymentIcon tw-place-self-start tw-rounded tw-self-stretch tw-max-w-[135px] tw-max-h-[45px]'
        src={PointsImage}
        alt={name}
      />
    );
  }

  return (
    <div
      className={`tw-w-[135px] tw-max-h-[45px] tw-self-stretch tw-rounded tw-place-self-start tw-flex tw-flex-row tw-justify-center tw-items-center ${styles.paymentMethodFallbackImage}`}
    >
      <FontAwesomeIcon className='tw-ml-1' icon={faMoneyBill} />
      <span className='tw-text-sm tw-mx-1 tw-max-h-[45px] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap'>
        {name}
      </span>
    </div>
  );
};
