import { useGetNotificationsQuery as useGeneratedGetNotificationsQuery } from '../generated/graphql';
import { CONTEXT_OPTIONS } from '../config/Client/createHttpLink';

const useGetNotificationsQuery = (userId?: string | null) =>
  useGeneratedGetNotificationsQuery({
    variables: { id: userId! },
    context: { client: CONTEXT_OPTIONS.notificationClient },
    skip: !userId,
  });

export default useGetNotificationsQuery;
