import { IClientBackgrounds } from 'HiveClient/components/Backgrounds/Backgrounds';
import DefaultBackground from '../../images/default-background.jpg';
import StoreBackground from '../../images/store-background.jpg';
import HomeFooterBackground from '../../images/home-footer.jpg';
import AuthBackground from '../../images/auth-banner.jpg';

const clientBackgrounds: IClientBackgrounds = {
  defaultBackground: DefaultBackground,
  authCarouselBackground: AuthBackground,
  homeFooterBackground: HomeFooterBackground,
  storeBackground: StoreBackground,
};

export default clientBackgrounds;
