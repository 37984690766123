import { AUTHENTICATION_SERVICE_URL } from '../config/Endpoints/Endpoints';

const apiUrl = 'https://api.twitch.tv/helix/';

export interface TwitchAPIServiceInterface {
  getTwitchAuthToken: () => Promise<any>;
  getTwitchStreams: (
    channelNames: string[],
    twitchClientID: string,
    twitchAuthToken: string
  ) => Promise<TwitchStreamData[]>;
  getTwitchGames: (
    gameIds: string[],
    twitchClientID: string,
    twitchAuthToken: string
  ) => Promise<TwitchGameData[]>;
}

export interface TwitchStreamData {
  id: string;
  user_id: string;
  user_name: string;
  game_id: string;
  game_name: string;
  type: string;
  title: string;
  viewer_count: number;
  started_at: string;
  language: string;
  thumbnail_url: string;
  tag_ids: string[];
  featured?: boolean; // added for WatchStreams
  weight?: number;
}

export interface TwitchGameData {
  id: string;
  name: string;
  box_art_url: string;
  streamer_name?: string;
}

export const getTwitchAuthToken = () =>
  fetch(`${AUTHENTICATION_SERVICE_URL}/oauth2/twitch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return Promise.reject();
      }
    })
    .catch((err) => {
      console.error('Error getting Twitch authorization: ', err);
      return Promise.reject();
    });

export function getTwitchStreams(
  channelNames: string[],
  twitchClientID: string,
  twitchAuthToken: string
): Promise<TwitchStreamData[]> {
  const queryParams = channelNames
    .map((channelName) => 'user_login=' + channelName)
    .join('&');
  return fetch(`${apiUrl}streams?${queryParams}`, {
    method: 'GET',
    headers: {
      'Client-ID': twitchClientID,
      Authorization: `Bearer ${twitchAuthToken}`,
    },
  })
    .then((res) => res.json())
    .then((res) => res?.data || [])
    .catch((err) => {
      console.error('Error getting streams from the Twitch API: ', err);
      throw new Error('Twitch API Service Error');
    });
}

export function getTwitchGames(
  gameIds: string[],
  twitchClientID: string,
  twitchAuthToken: string
): Promise<TwitchGameData[]> {
  let queryParams = gameIds.map((gameId) => 'id=' + gameId).join('&');

  return fetch(apiUrl + 'games?' + queryParams, {
    method: 'GET',
    headers: {
      'Client-ID': twitchClientID,
      Authorization: `Bearer ${twitchAuthToken}`,
    },
  })
    .then((res) => res.json())
    .then((res) => res?.data || [])
    .catch((err) => {
      console.error('Error in streaming service: ', err);
      throw new Error('Streaming service error');
    });
}

const TwitchAPIService: TwitchAPIServiceInterface = {
  getTwitchAuthToken,
  getTwitchGames,
  getTwitchStreams,
};

export default TwitchAPIService;
