import envVariables from '../EnvVariables/EnvVariables';

export const intercomInit = () => {
  const APP_ID = envVariables.intercomId;

  if (APP_ID) {
    (function () {
      var w = window;
      //@ts-ignore
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        //@ts-ignore
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          //@ts-ignore
          i.c(arguments);
        };
        //@ts-ignore
        i.q = [];
        //@ts-ignore
        i.c = function (args) {
          //@ts-ignore
          i.q.push(args);
        };
        //@ts-ignore
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + APP_ID;
          var x = d.getElementsByTagName('script')[0];
          //@ts-ignore
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
          //@ts-ignore
        } else if (w.attachEvent) {
          //@ts-ignore
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
};

export const intercomBoot = (
  APP_ID: string,
  alignment: 'left' | 'right',
  email?: string,
  userId?: string
) => {
  const intercomBootParams =
    email && userId
      ? {
          app_id: APP_ID,
          email: email,
          user_id: userId,
          created_at: Date.now(),
          alignment,
        }
      : {
          app_id: APP_ID,
          created_at: Date.now(),
          alignment,
        };

  //@ts-ignore
  window.Intercom('boot', intercomBootParams);
};
