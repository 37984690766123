import { useEffect, useReducer } from 'react';

import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';

import { useAuthContext } from '../../pages/Authentication/AuthContext';
import Spinner from '../Spinner/Spinner';

/**
 * This component handles private routes.
 *
 * A private route at the moment is any route that requires you to be signed in.
 *
 * If there are other validation requirements beyond that, they can be provided as the `condition` prop,
 * which is a function that returns a promise of either null or string. If null, that means
 * the condition passed, and the user is allowed to see the route.
 *
 * If the promise value is a string, that means the user is not allowed to see the route,
 * and the string is the route that the user should be redirected to. This gives the condition
 * function the opportunity to return a different redirect depending on why the condition failed.
 *
 * In the future we may remove the userId check from this component and move it into the condition checks, in case
 * we want this to be more of a <ConditionalRoute>.
 *
 * I'm not 100% sure why, but if a PrivateRoute is given a condition, it must also have a key prop, even outside of an array.
 * It might have to do with all the <PrivateRoute>s being siblings, but it will result in an infinite loop otherwise. It could
 * also be covering up some other issue, but it works for now.
 */

/**
 * Extends react-router Route, and forces the component and path property from that interface to be required.
 * <T> is the shape of the Params as returned by useRouteMatch. So /team/:id/manage would have <PrivateRoute<{id: string}>>
 *   so it can be passed to the condition function if needed in the conditional logic.
 */
interface PrivateRouteProps<T>
  extends Omit<RouteProps, 'component' | 'render'> {
  redirectPath: string;
  component: NonNullable<RouteProps['component']>;
  path: NonNullable<RouteProps['path']>;
  componentProps?: Object;
  condition?: (params: T) => Promise<null | string>;
}

function reducer(
  state: {
    loading: boolean;
    conditionRedirectPath?: null | string;
    satisfiesCondition: boolean;
  },
  action: {
    type: 'isAllowed' | 'isNotAllowed';
    conditionRedirectPath?: string | null;
  }
) {
  switch (action.type) {
    case 'isAllowed':
      return {
        loading: false,
        satisfiesCondition: true,
        conditionRedirectPath: null,
      };
    case 'isNotAllowed':
      return {
        loading: false,
        satisfiesCondition: false,
        conditionRedirectPath: action.conditionRedirectPath,
      };
    default:
      throw new Error();
  }
}

const PrivateRoute = <T extends object>({
  component: Component,
  componentProps = {},
  redirectPath,
  condition,
  path,
  ...rest
}: PrivateRouteProps<T>) => {
  const [authContextState] = useAuthContext();

  // If condition is set, then default state to loading.
  const [state, dispatch] = useReducer(reducer, {
    loading: !!condition,
    conditionRedirectPath: null,
    satisfiesCondition: !condition,
  });

  const params = useRouteMatch<T>(path)?.params;

  useEffect(() => {
    if (condition) {
      // if no routeMatch came back, it means we're checking on a bogus route,
      // in which case, get them out of here.
      if (!params) {
        dispatch({ type: 'isNotAllowed' });
      } else {
        // We pass params to the condition check, in case it needs the :id or what have you for its conditional logic
        condition(params).then((conditionRedirectPath) => {
          conditionRedirectPath
            ? dispatch({
                type: 'isNotAllowed',
                conditionRedirectPath: conditionRedirectPath,
              })
            : dispatch({ type: 'isAllowed' });
        });
      }
    }
    // useRouteMatch returns a new object every render, even though the .params is not going to change.
    // rather than hack some useRef stuff, we just leave it out. It will have everything we need first render.
    // eslint-disable-next-line
  }, [condition]);

  return (
    <Route
      path={path}
      {...rest}
      render={(routeProps) => {
        if (state.loading) {
          return (
            <div className={'d-flex justify-content-center mt-5'}>
              <Spinner />
            </div>
          );
        }

        return authContextState.userId && state.satisfiesCondition ? (
          <Component {...routeProps} {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: state.conditionRedirectPath ?? redirectPath,
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
