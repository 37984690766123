import { Fragment, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfileCard from 'src/components/ProfileCard/ProfileCard';
import Button from 'src/components/Button/Button';
import ProfileSocialLinks from 'src/components/ProfileSocialLinks/ProfileSocialLinks';

import Routes from 'src/config/Routes/Routes';
import { useAbilityContext } from 'src/config/Casl/Can';
import { TeamProfileFragment } from 'src/generated/graphql';
import useRemoveMemberFromTeam from 'src/hooks/useRemoveMemberFromTeam';

import { faCog, faUsers } from 'HiveClient/components/Icons/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Team.module.scss';
import { useAuthContext } from '../Authentication/AuthContext';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { formatToLocalTimezone } from 'src/utilities/Utilities';

type Props = {
  team: TeamProfileFragment;
  noEdit?: boolean;
};

const TeamProfileCard = ({ team, noEdit = false }: Props) => {
  const { t } = useTranslation('team');
  const history = useHistory();
  const ability = useAbilityContext();
  const [authContextState] = useAuthContext();

  const [
    showRemoveMemberConfirmationModal,
    setShowRemoveMemberConfirmationModal,
  ] = useState<boolean>(false);

  const [removeMemberMutation, removeMemberMutationState] =
    useRemoveMemberFromTeam(authContextState.userId ?? '', () => {
      setShowRemoveMemberConfirmationModal(false);
      history.goBack();
    });

  const authenticatedGroupMemberInTeam = team.groupMembers.find(
    (groupMember) => groupMember.account.id === authContextState.userId
  );

  return (
    <Fragment>
      <ProfileCard
        className={styles.profileCard}
        name={team.name}
        avatarPhoto={team.avatarURL}
        fallbackAvatarIcon={faUsers}
        footerChildren={
          ability.can('leave', team) || ability.can('manage', team) ? (
            <div className={'d-flex flex-column align-items-center'}>
              {ability.can('leave', team) && (
                <Button
                  semantic={'leave-team'}
                  className={'mx-auto'}
                  variant={'outline-danger'}
                  onClick={() => {
                    setShowRemoveMemberConfirmationModal(true);
                  }}
                >
                  {t('leave-roster.label')}
                </Button>
              )}
              {ability.can('manage', team) && !noEdit && (
                <Button
                  semantic={'manage-team'}
                  className={[
                    'mx-auto',
                    ability.can('leave', team) ? 'mt-2' : '',
                  ].join(' ')}
                  variant={'outline-primary'}
                  onClick={() =>
                    history.push(
                      generatePath(Routes.manageTeam, {
                        id: team.id,
                      })
                    )
                  }
                >
                  {t('edit-team.button.label')}
                  <FontAwesomeIcon icon={faCog} className={'ms-2'} />
                </Button>
              )}
              {authenticatedGroupMemberInTeam && (
                <Fragment>
                  <hr className={'w-100 m-4'} />
                  <small className={'text-center text-dark'}>
                    {t('member-since', {
                      joinDate: formatToLocalTimezone(
                        authenticatedGroupMemberInTeam.created,
                        {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric',
                        }
                      ),
                    })}
                  </small>
                </Fragment>
              )}
            </div>
          ) : null
        }
      >
        <Fragment>
          <p>{team.description}</p>
          <ProfileSocialLinks socialLinks={team.socialLinks} />
        </Fragment>
      </ProfileCard>
      <ConfirmationModal
        title={t('leave-roster-modal.title')}
        confirmAction={() => {
          removeMemberMutation({
            variables: {
              groupId: team.id,
              accountId: authContextState.userId!,
            },
          });
        }}
        loading={removeMemberMutationState.loading}
        onHide={() => setShowRemoveMemberConfirmationModal(false)}
        show={showRemoveMemberConfirmationModal}
      >
        <p>{t('leave-roster-modal.body', { team: team.name })}</p>
      </ConfirmationModal>
    </Fragment>
  );
};

export default TeamProfileCard;
