import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import i18next from 'i18next';

interface UtilsInterface {
  formatNumberDoubleDigits: (num: number) => string;
  meetsMinLengthRequirement: (input: string, minLength: number) => boolean;
  meetsDisplayNameRequirements: (input: string) => boolean;
  meetsInputRequired: (input: string) => boolean;
  meetsEmailRequirement: (input: string) => boolean;
  meetsValidUrlRequirement: (input: string) => boolean;

  getURLSearchParamByName: (
    searchQuery: string,
    param: string
  ) => string | null;
  generateSearchParamsStringFromObject: (param: string) => string;

  formatCash: (n: number) => string;
  formatPlacement: (n: number) => string;
  getFormDataFromFile: (
    file: File | null,
    formDataKey: string
  ) => FormData | null;

  addTolocalStorage: (key: string, value: any) => void;
  getFromlocalStorage: <T>(key: string) => T | null;
  formatToLocalTimezone: (
    dateTime: string,
    format: LocaleOptions & DateTimeFormatOptions
  ) => string;
  formatDateFromNow: (dateTime: string) => string | null;
  abbreviate: (phrase: string) => string;
  formatCurrencyForLocale: (amount: number, currency: string) => string;
  formatCurrencyToPartsForLocale: (
    amount: number,
    currency: string
  ) => Intl.NumberFormatPart[];

  sha256: (input: string) => Promise<string>;
}

// Add a zero before single digits for display, converts number to string
export const formatNumberDoubleDigits = (num: number) =>
  `${num < 10 ? '0' : ''}${num}`;

// Validation utils
export const meetsMinLengthRequirement = (input: string, minLength: number) =>
  input.length >= minLength;
// todo original alpha numeric regex seems to have a bug need to cross check this with the swarmio portal
export const meetsDisplayNameRequirements = (input: string) =>
  /^[-_!$\w\s\d]*$/.test(input);

export const isAlphaNumeric = (input: string) => /^[a-zA-Z0-9]*$/.test(input);

export const meetsInputRequired = (input: string) => input.trim().length !== 0;

export const meetsEmailRequirement = (input: string) =>
  /(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/.test(
    input
  );

export const meetsPhoneRequirement = (input: string) =>
  /^\+?(\d{1,3})?[-.\s]?(\(?\d{2,3}\)?)?[-.\s]?\d{4,5}[-.\s]?\d{4}$/.test(
    input
  );

/**
 * Naive URL Validator.
 *
 * Checks that the given string starts with http:// or https://
 * Then runs the string through the URL() constructor, which throws
 * an error if the string does not confirm to the browser's URL spec.
 *
 * It's fairly permissive at the moment, but it should at least prevent people
 * from trying to add javascript:alert('lol') or what have you to user entered
 * url fields.
 *
 * There are a number of huge regexes out there that can be used, but I'm worried
 * about how western-centric they might be where we operate in other markets and I don't
 * want to accidentally block some valid characters, or what have you.
 */
export const meetsValidUrlRequirement = (input: string) => {
  if (!input) {
    return true;
  }

  if (!/^https?:\/\//.test(input)) {
    return false;
  }

  try {
    new URL(input);
    return true;
  } catch {
    return false;
  }
};

// URL UTILs
export const getURLSearchParamByName = (
  searchQuery: string,
  param: string
): string | null => {
  const { URLSearchParams } = window;

  // this is needed as any '+' chars will get stripped
  const encodedString: string = encodeURI(searchQuery).replace(/\+/gi, '%2B');
  return new URLSearchParams(encodedString).get(param);
};

export const generateSearchParamsStringFromObject = (params: any) =>
  Object.keys(params)
    .map(
      (key: string) =>
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    )
    .join('&');

// truncate money string
export const formatCash = (n: number): string =>
  new Intl.NumberFormat(i18next.language, {
    notation: 'compact',
  }).format(n);

// convert number into placement string
export const formatPlacement = (n: number): string =>
  `${n}${['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'}`;

// convert number to string with commas per thousand
export const numberWithCommas = (n: number): string =>
  n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// file upload/form util
export const getFormDataFromFile = (
  file: File | null,
  formDataKey: string
): FormData | null => {
  const formData: FormData = new FormData();

  if (file) {
    formData.append(formDataKey, file);
    return formData;
  }

  return null;
};

export const addToSessionStorage = (key: string, value: any) => {
  const { sessionStorage }: Window = window;
  const storageValue = JSON.stringify(value);

  sessionStorage.setItem(key, storageValue);
};

export const getFromSessionStorage = <T>(key: string): T | null => {
  const { sessionStorage }: Window = window;
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const addTolocalStorage = (key: string, value: any) => {
  const { localStorage }: Window = window;
  const storageValue = JSON.stringify(value);

  localStorage.setItem(key, storageValue);
};

export const getFromlocalStorage = <T>(key: string): T | null => {
  const { localStorage }: Window = window;
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const formatToLocalTimezone = (
  dateTime: string,
  format: LocaleOptions & DateTimeFormatOptions
) =>
  DateTime.fromISO(dateTime, { zone: 'utc' }).toLocal().toLocaleString(format);

export const formatDateFromNow = (dateTime: string) =>
  // todo: look into why toRelative can return null, and not just a string
  DateTime.fromISO(dateTime, { zone: 'utc' }).toRelative()!;

// numbers 1 to 26 map to A - Z and 27 maps to AA
export const numberToLetter = (number: number): string => {
  const mod = number % 26;
  let pow = (number / 26) | 0;
  const out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? numberToLetter(pow) + out : out;
};

export const abbreviate = (phrase: string): string => {
  const match = phrase.match(/\b\w/g);

  return match ? match.join('') : phrase;
};

export const caseInsensitiveComparison = (
  a?: string | null,
  b?: string | null
): boolean => (a || '').toLowerCase() === (b || '').toLowerCase();

export const formatCurrencyForLocale = (amount: number, currency: string) =>
  new Intl.NumberFormat(i18next.language, {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount);

export const formatCurrencyToPartsForLocale = (
  amount: number,
  currency: string
) =>
  new Intl.NumberFormat(i18next.language, {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).formatToParts(amount);

export const formatNumberForLocale = (
  number: number,
  options?: Intl.NumberFormatOptions
) => new Intl.NumberFormat(i18next.language, options).format(number);

export const sha256 = (input: string) => {
  const utf8 = new TextEncoder().encode(input);
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
};

/*
 * Function to get camel case text as inputs and convert them to normal text
 */
export const camelCaseToNormal = (camelCaseString: string) =>
  // Replace uppercase letters with space followed by the lowercase letter
  camelCaseString
    .replace(/([A-Z])/g, ' $1')
    // Capitalize the first letter
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });

const Utils: UtilsInterface = {
  formatNumberDoubleDigits,
  meetsMinLengthRequirement,
  meetsDisplayNameRequirements,
  meetsEmailRequirement,
  meetsInputRequired,
  meetsValidUrlRequirement,

  getURLSearchParamByName,
  generateSearchParamsStringFromObject,

  formatCash,
  formatPlacement,

  getFormDataFromFile,

  addTolocalStorage,
  getFromlocalStorage,

  formatToLocalTimezone,
  formatDateFromNow,

  abbreviate,

  formatCurrencyForLocale,
  formatCurrencyToPartsForLocale,

  sha256,
};

export default Utils;
