import { RefObject, useEffect } from 'react';

export function useOnClickOutside(
  refs: RefObject<any>[],
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const matchingRefs = refs.filter(
        (ref) =>
          ref.current?.contains?.(event.target) ||
          // Hack for checking if it was a modal that was clicked on.
          ref.current?.dialog?.contains?.(event.target)
      );

      if (matchingRefs.length > 0) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
}
