import { faCoins } from 'HiveClient/components/Icons/Icons';
import TitleWithIcon from '../../components/TitleWithIcon/TitleWithIcon';

type Props = {
  balance: string;
  iconSize?: '1rem' | '1.5rem' | '2rem';
};

const SwarmioCurrencyBalance = (props: Props) => (
  <TitleWithIcon
    icon={faCoins}
    title={props.balance}
    iconSize={props.iconSize ?? '2rem'}
    className={`copy-large mx-2 fw-bold`}
    as={'span'}
  />
);

export default SwarmioCurrencyBalance;
