import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Background from 'src/components/Background/Background';
import ClientHelmet from 'src/components/ClientHelmet/ClientHelmet';
import PageWrapper from 'src/components/PageWrapper/PageWrapper';
import Routes from '../../../config/Routes/Routes';
import {
  useProductGroupItemsQuery,
  usePurchaseStoreItemMutation,
  usePurchaseStoreItemWithPointsMutation,
} from '../../../generated/graphql';
import useGetMinimalUserQuery from '../../../hooks/useGetMinimalUserQuery';
import { getWalletBalance } from '../../../utilities/Wallet';
import { useAuthContext } from '../../Authentication/AuthContext';
import { DEFAULT_SWARMIO_CURRENCY_ID } from '../../Wallet/CurrencyId';
import { StoreContextProvider } from '../StoreContext';
import {
  MerchantPaymentOption,
  PriceOption,
  transformCatalogItemToStoreItemData,
  useUpdateMyOrdersCache,
} from '../StoreHelpers';
import { StoreItemData, useStoreReducer } from '../useStoreReducer';
import StoreItemDetailBalance from './StoreItemDetailBalance';
import { StoreItemDetailBody } from 'HiveClient/components/StoreItem/StoreItemDetailBody';
import StoreItemDetailFailure from './StoreItemDetailFailure';
import EnvVariables from 'src/config/EnvVariables/EnvVariables';
import AsyncView from 'src/components/AsyncView/AsyncView';
import { useToasts } from 'src/components/Toasts/ToastContext';
import { createRedirectURL } from 'src/services/SwarmioPayService';
import StoreNavigation from '../StoreNavigation';
import { usePostHog } from 'posthog-js/react';

interface Props {
  show: boolean;
  onHide: () => void;
}

const StoreItemDetail = (props: Props) => {
  const { t } = useTranslation('store');
  const { t: defaultNamespaceT } = useTranslation();
  const [state, dispatch] = useStoreReducer();
  const postHog = usePostHog();

  const getUserQuery = useGetMinimalUserQuery();
  const [authContextState] = useAuthContext();

  const { itemId, categoryId } = useParams<{
    itemId: string;
    categoryId: string;
  }>();
  const { data, loading } = useProductGroupItemsQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
      productGroupId: itemId,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const firstProduct = data.partnerProductGroup?.items?.[0];

    if (firstProduct) {
      dispatch({
        type: 'UPDATE_STORE_SELECTED_ITEM',
        payload: {
          item: {
            ...firstProduct,
            fields: transformCatalogItemToStoreItemData(firstProduct.fields),
          },
        },
      });
    }
  }, [data, dispatch]);

  const itemOptions = useMemo(() => {
    const items = [...(data?.partnerProductGroup?.items ?? [])];
    return items.map(
      (item) =>
        ({
          ...item,
          fields: transformCatalogItemToStoreItemData(item?.fields ?? []),
        } as StoreItemData)
    );
  }, [data?.partnerProductGroup?.items]);

  const currentItem = state.selectedItem;
  const itemCost = currentItem?.pointsValue;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [purchaseMessageTitle, setPurchaseMessageTitle] = useState<string>(
    t('modal.title')
  );
  const [purchaseMessageBody, setPurchaseMessageBody] =
    useState<JSX.Element | null>(null);

  const history = useHistory();

  const updateMyOrdersCache = useUpdateMyOrdersCache();

  const resetDetail = () => {
    props.onHide();
    setPurchaseMessageTitle(t('modal.title'));
    setPurchaseMessageBody(null);
  };

  const [
    purchaseStoreItemWithPointsMutation,
    purchaseStoreItemWithPointsMutationState,
  ] = usePurchaseStoreItemWithPointsMutation({
    onCompleted: (res) => {
      updateMyOrdersCache(res);
      let lastTransaction =
        res.purchaseExternalItemWithPoints.purchaseTransactions[
          res.purchaseExternalItemWithPoints.purchaseTransactions.length - 1
        ];

      // Refetch to update wallet balance after purchase is complete
      getUserQuery.refetch();
      history.push(Routes.myOrders + '/' + lastTransaction.id);
    },
    onError: (error) => {
      let message = error.message
        ? error.message
        : t('unexpected-error-occured');

      if (message.includes('out of stock')) {
        message = t('out-of-stock');
      }

      setPurchaseMessageTitle(t('modal.error.title'));
      setPurchaseMessageBody(<StoreItemDetailFailure message={message} />);
    },
  });

  // disable the redeem button in the modal if there is no cost
  const disableButton = !currentItem?.pointsValue;

  const hasInSufficientFunds = () => {
    const walletBalance = getUserQuery.data
      ? getWalletBalance(getUserQuery.data.user)
      : undefined;

    return itemCost! && walletBalance !== undefined && itemCost > walletBalance;
  };

  const getSelectedItemCost = () => state.selectedPaymentOption?.option;
  const getSelectedPaymentMethod = () => state.selectedPaymentOption?.method;

  // handle purchase based on selected item state
  async function doPurchase(fields: { [key: string]: string | number }) {
    const selectedItemCost = getSelectedItemCost();
    const selectedPaymentMethod = getSelectedPaymentMethod();
    const userEmail = fields['email'] as string;

    if (!selectedItemCost || !selectedPaymentMethod) {
      showErrorNotification({
        heading: 'error',
        body: t('please-select-a-payment-method'),
      });
      return;
    }

    postHog.capture('store_purchase_started', { item: currentItem?.itemId, selectedPaymentMethod: selectedPaymentMethod, providedEmail: userEmail });

    if (selectedItemCost.currencyId === DEFAULT_SWARMIO_CURRENCY_ID) {
      // points purchase handling
      if (authContextState.userId) {
        if (hasInSufficientFunds()) {
          // show insufficient balance message if they don't have enough points
          setPurchaseMessageTitle(t('modal.balance.header'));
          setPurchaseMessageBody(<StoreItemDetailBalance />);
        } else if (itemCost) {
          purchaseStoreItemWithPointsMutation({
            variables: {
              itemId: currentItem.itemId,
              gamerId: getUserQuery.data?.user?.id ?? '',
              gamerEmail: userEmail,
              gamerFirstName: getUserQuery.data?.user?.displayName ?? 'johnny',
              gamerLastName: getUserQuery.data?.user?.displayName ?? 'fiveaces',
              gamerAddress: 'internet',
              gamerIPAddress: '127.0.0.1',
              country: 'Philippines',
              gamerMobileNumber: '09176881448',
              quantity: 1,
              tagId: selectedItemCost.tagId,
              fields: JSON.stringify(fields),
            },
          });
        }
      } else {
        // redirect to signin if they try to purchase the item unauthenticated
        history.push(Routes.signIn);
      }
    } else {
      // we *require* an email for all purchases, check if they're logged in to get it.
      console.info(
        'purchase with fiat tagId: ' +
          selectedItemCost.tagId +
          ' pspTagId: ' +
          selectedItemCost.pspTagId +
          'and userEmail: ' +
          userEmail
      );
      purchaseWithFiat(
        selectedItemCost,
        selectedPaymentMethod,
        userEmail,
        fields
      );
    }
  }

  const { showErrorNotification } = useToasts();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [purchaseStoreItem, purchaseStoreItemMutationState] =
    usePurchaseStoreItemMutation({
      onCompleted(data) {
        if (data.purchaseExternalItem.gatewaySessionId) {
          const paySessionURL = createRedirectURL(
            data.purchaseExternalItem.gatewaySessionId
          );
          console.log(
            'redirecting to paySessionURL: ' +
              paySessionURL +
              ' with generated transactionId: ' +
              data.purchaseExternalItem.transactionId
          );
          window.location.href = paySessionURL;
          // opening a status tab is going to be *troublesome*
          // const payStatusURL = history.createHref({ pathname: Routes.myOrders }) + '/' + data.purchaseExternalItem.transactionId
          // window.open(payStatusURL, '_blank')
        }
      },
      onError(error) {
        showErrorNotification({
          heading: '',
          body: error.message,
        });
      },
    });

  /**
   * Purchase a store item with fiat currency.
   * @param tagId The specific costId associated with the purchase.
   * @param userEmail The email of the user making the purchase. Optional, defaults to the current user's auth email.
   */
  const purchaseWithFiat = (
    price: PriceOption,
    paymentChannel: MerchantPaymentOption,
    userEmail: string,
    fields: { [key: string]: string | number }
  ) => {
    // SH-3867: check for cookie set with maxit JWT
    const passedAuthorization = document.cookie
      .split('; ')
      .find((row) => row.startsWith('PassedAuthorization='))
      ?.split('=')[1];

    if (passedAuthorization) {
      console.log(`PassedAuthorization Cookie: ${passedAuthorization}`);
    } else {
      console.log('PassedAuthorization Cookie not found');
    }

    purchaseStoreItem({
      variables: {
        gamerEmail: userEmail,
        itemId: currentItem?.itemId ?? '',
        quantity: 1,
        // SH-3583: tagId vs. pspTagId split -- if it's *NOT* DCB (including WALLET), use pspTagId
        tagId:
          paymentChannel.paymentMethodType === 'DCB'
            ? price.tagId
            : price.pspTagId,
        merchantPaymentMethodType: paymentChannel.paymentMethodType,
        merchantPaymentMethodId: paymentChannel.id.toString(),
        gamerFirstName: getUserQuery.data?.user?.displayName ?? '',
        gamerLastName: getUserQuery.data?.user?.displayName ?? '',
        gamerAddress: 'internet',
        gamerIPAddress: '127.0.0.1',
        country: 'Philippines',
        gamerMobileNumber: '09176881448',
        fields: JSON.stringify(fields),
        maybeMaxitJWT: passedAuthorization,
      },
    });
  };

  return (
    <StoreContextProvider value={{ dispatch, state }}>
      <PageWrapper clearMenu noBackgroundImage>
        <ClientHelmet title={t('page.title')} />
        <Background
          clearMenu={true}
          className='tw-py-0'
          noGradient={true}
          style={{
            backgroundImage: `
                linear-gradient(to bottom, var(--body-bg-transparent) 70%, var(--body-bg) 100%),
                url()
              `,
          }}
        >
          <AsyncView loading={loading}>
            <Container>
              <StoreNavigation />
              {
                // show the purchase response message (success/failure)
                // this is StoreItemDetailSuccess or StoreItemDetailFailure
                purchaseMessageBody ?? (
                  <StoreItemDetailBody
                    onHide={resetDetail}
                    name={data?.partnerProductGroup?.name ?? ''}
                    currencyName={
                      itemCost
                        ? defaultNamespaceT('swarmio-currency.pretty-name')
                        : undefined
                    }
                    pointsValue={itemCost ?? 0}
                    category={''}
                    categoryId={categoryId}
                    productType={currentItem?.productType}
                    description={data?.partnerProductGroup?.description ?? ''}
                    image={data?.partnerProductGroup?.bannerImage ?? ''}
                    currentItem={currentItem}
                    disableButton={disableButton}
                    loading={
                      purchaseStoreItemWithPointsMutationState.loading ||
                      purchaseStoreItemMutationState.loading
                    }
                    itemOptions={itemOptions} // TODO: Refactor this and create a new type that contains only what this component needs
                    onConfirm={doPurchase}
                  />
                )
              }
            </Container>
          </AsyncView>
        </Background>
      </PageWrapper>
    </StoreContextProvider>
  );
};

export default StoreItemDetail;
