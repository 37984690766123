/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { StoreItemDetailPromo } from '../../../../pages/Store/StoreItem/StoreItemDetailsPromo';
import { Fragment, useMemo } from 'react';
import {
  ProductType,
  StoreCategoryFragment,
  useCategoryCatalogQuery,
  useCategoryProductGroupsQuery,
} from 'src/generated/graphql';
import StoreItemRedemptionDetails from 'HiveClient/components/StoreItem/StoreItemRedemptionDetails';
import { StoreItemData } from '../../../../pages/Store/useStoreReducer';
import { StoreItemDetailDescription } from '../../../../pages/Store/StoreItem/StoreItemDetailDescription';
import useGetMinimalUserQuery from 'src/hooks/useGetMinimalUserQuery';
import { useAuthContext } from 'src/pages/Authentication/AuthContext';
import { isTypename } from 'src/utilities/TSUtilities';
import { StoreItemDetailCurrencyOptions } from '../../../../pages/Store/StoreItem/StoreItemDetailCurrencyOptions';
import { StoreItemDetailOptions } from '../../../../pages/Store/StoreItem/StoreItemDetailOptions';
import {
  useFormattedPriceOptions,
  useMerchantPaymentOptions,
} from '../../../../pages/Store/StoreHelpers';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useStoreContext } from '../../../../pages/Store/StoreContext';
import { Button } from 'react-bootstrap';
import Routes from '../../../../config/Routes/Routes';
import EnvVariables from '../../../../config/EnvVariables/EnvVariables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

interface StoreItemPrice {
  currency: string;
  price: number;
  tagId: string;
}

export interface StoreItemDetailBodyProps {
  onHide: () => void;
  name: string;
  description?: string;
  category?: string;
  categoryId: string;
  currencyName?: string;
  pointsValue?: number;
  prices?: StoreItemPrice[] | null;
  image?: string;
  productType?: ProductType | null;
  currentItem?: StoreItemData | null;
  disableButton: boolean;
  loading: boolean;
  promoEnabled?: boolean;
  detailDenominationEnabled?: boolean;
  itemOptions: StoreItemData[];
  onConfirm: (fields: { [key: string]: string | number }) => void;
}

export const StoreItemDetailBody = (props: StoreItemDetailBodyProps) => {
  const [authContextState] = useAuthContext();
  const { t } = useTranslation('store');
  const getUserQuery = useGetMinimalUserQuery();
  const { dispatch } = useStoreContext();
  const history = useHistory();

  const priceOptions = useFormattedPriceOptions(
    props.currencyName,
    props.pointsValue,
    props.currentItem
  );

  const defaultPriceOption = useMemo(
    () =>
      priceOptions[0].name === 'Points' ? priceOptions[1] : priceOptions[0],
    [priceOptions]
  );

  const allStoreCategoriesRes = useCategoryCatalogQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
    },
  });

  const currentCategory: StoreCategoryFragment | undefined =
    allStoreCategoriesRes.data?.categoryCatalog?.categories
      ?.filter(
        (category): category is StoreCategoryFragment => category !== null
      )
      .filter(
        (category): category is StoreCategoryFragment =>
          category.id === props.categoryId
      )
      .at(0);

  const availableStoreItemsRes = useCategoryProductGroupsQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
      categoryId: props.categoryId,
      limit: 2,
      offset: 0,
    },
    initialFetchPolicy: 'cache-first',
  });

  const productGroups =
    availableStoreItemsRes.data?.categoryProductGroups?.productGroups;

  useMerchantPaymentOptions({
    price: defaultPriceOption.price,
    currency: defaultPriceOption.currency,
    skip: !GlobalClientConfig.currency?.hideCurrencySelectOnStore,
    onCompleted: (data) => {
      const result = data.getPaymentMethodsAvailableForMerchant;
      if (
        GlobalClientConfig.currency?.hideCurrencySelectOnStore &&
        result.length
      ) {
        dispatch({
          type: 'SELECT_ITEM_PAYMENT_OPTION',
          payload: {
            option: defaultPriceOption,
            method: result[0] as any,
          },
        });
      }
    },
  });

  function getFieldsWithEmailAdded(
    props: StoreItemDetailBodyProps
  ): import('./StoreItemRedemptionDetails').IField[] {
    const defaultEmail = authContextState.userId
      ? getUserQuery.data?.user?.email &&
        isTypename('StringAttribute', getUserQuery.data.user.email)
        ? getUserQuery.data.user.email.value
        : ''
      : '';
    const adjustedFields = (props.currentItem?.fields ?? []).concat({
      name: 'email',
      type: 'email',
      data: [{ name: 'email', value: defaultEmail }],
    });
    return adjustedFields;
  }

  return (
    <Fragment>
      <Button
        variant='link'
        className='tw-flex tw-gap-2 tw-items-center tw-m-2 tw-max-w-fit'
        onClick={() =>
          history.push(
            productGroups && productGroups.length > 1
              ? `${Routes.store}/${currentCategory?.id}/`
              : Routes.store
          )
        }
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        {t('button.back')}
      </Button>
      <div className='tw-Frame25 tw-self-stretch tw-justify-center tw-items-start tw-gap-8 tw-flex tw-flex-wrap'>
        <div
          data-semantic='leftCol'
          className='tw-Frame24 tw-grow tw-basis-7/12 lg:tw-basis-6/12 tw-justify-center tw-gap-4 tw-flex tw-flex-col'
        >
          <div className='tw-Banner tw-h-48 tw-bg-white dark:tw-bg-zinc-900 tw-rounded-3xl tw-border tw-border-zinc-700 tw-overflow-clip'>
            <img
              className='tw-BackgroundImage tw-h-full tw-w-full tw-object-cover tw-object-center' // center this vertically in the containing div
              // src={'https://place-puppy.com/800x450'}
              src={props.image}
            />
          </div>
          <div className='tw-Heading tw-flex-col tw-justify-start tw-items-start tw-gap-0 tw-inline-flex'>
            <div className='tw-MobileLegendsBangBang tw-text-black dark:tw-text-zinc-100 tw-text-2xl tw-font-bold tw-leading-7'>
              {props.name}
            </div>
            <div className='tw-DirectTopUp tw-text-[#938F99] tw-text-lg tw-font-normal tw-mt-2 tw-leading-snug'>
              {props.productType}
            </div>
          </div>
          <div className=''>
            {props.description && (
              <StoreItemDetailDescription description={props.description} />
            )}
          </div>

          <StoreItemDetailOptions itemOptions={props.itemOptions} />

          {props.promoEnabled && <StoreItemDetailPromo />}
        </div>

        <div
          data-semantic='rightCol'
          className='tw-AccountInfo tw-basis-full lg:tw-basis-4/12 tw-flex-col tw-justify-center tw-items-center tw-gap-8 tw-flex'
        >
          {!GlobalClientConfig.currency?.hideCurrencySelectOnStore && (
            <StoreItemDetailCurrencyOptions
              currencyName={props.currencyName}
              currentItem={props.currentItem}
              priceOptions={priceOptions}
            />
          )}

          <StoreItemRedemptionDetails
            onConfirm={props.onConfirm}
            onHide={props.onHide}
            loading={props.loading}
            productType={props.productType}
            disableButton={props.disableButton}
            fields={getFieldsWithEmailAdded(props)}
            price={props.pointsValue ?? 0}
            currencyName={props.currencyName ?? 'beans'}
          />
        </div>
      </div>
    </Fragment>
  );
};
