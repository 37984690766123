import { SyntheticEvent } from 'react';
import Form from 'react-bootstrap/Form';
import { RoleIdFragment } from '../../generated/graphql';

interface Props {
  name: string;
  value: string;
  onChange: (event: SyntheticEvent) => void;
  highestLoggedInUserRole: RoleIdFragment;
  disabled: boolean;
}

export enum Roles {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
}

const RoleSelect = ({
  name,
  value,
  onChange,
  highestLoggedInUserRole,
  disabled,
}: Props) => (
  <Form.Control
    name={name}
    value={value}
    onChange={onChange}
    as='select'
    data-testid={'role-select'}
    disabled={disabled}
  >
    {highestLoggedInUserRole.roleId === Roles.OWNER && (
      <option value={Roles.OWNER}>Owner</option>
    )}
    <option value={Roles.ADMIN}>Admin</option>
    <option value={Roles.MEMBER}>Player</option>
  </Form.Control>
);

export default RoleSelect;
