import React, { createContext, useContext, useState } from 'react';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

const ThemeContext = createContext({
  theme: GlobalClientConfig.theme,
  toggleTheme: () => {},
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<'tw-light' | 'tw-dark'>(() => {
    document.documentElement.classList.toggle(
      'tw-dark',
      GlobalClientConfig.theme === 'tw-dark'
    );
    return GlobalClientConfig.theme;
  });

  const toggleTheme = () => {
    const newTheme = theme === 'tw-light' ? 'tw-dark' : 'tw-light';
    setTheme(newTheme);
    document.documentElement.classList.toggle(
      'tw-dark',
      newTheme === 'tw-dark'
    );
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);

  return context;
};

export { ThemeProvider, useTheme };
