import Navbar from 'react-bootstrap/Navbar';

import styles from './Footer.module.scss';

import ContactLinks from 'HiveClient/components/FooterContent/ContactLinks';
import LearnLinks from 'HiveClient/components/FooterContent/LearnLinks';
import Branding from 'HiveClient/components/FooterContent/Branding';
import Container from 'react-bootstrap/Container';
import EtisalatLogo from 'HiveClient/images/etisalat_logo.png';
import { useTranslation } from 'react-i18next';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { DateTime } from 'luxon';

export type Props = {
  preventNavigation: boolean;
};

const Footer = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Navbar
      className={styles.footerNavbar}
      as={'footer'}
      variant={'dark'}
      bg={'dark'}
    >
      <Container fluid>
        <section
          className={[
            'd-flex',
            'flex-column',
            'flex-column-reverse',
            'd-md-flex',
            'flex-lg-row',
            'align-items-center',
            'justify-content-around',
            'py-2',
            styles.footer,
          ].join(' ')}
        >
          <section className={styles.etisalatLogo}>
            <img src={EtisalatLogo} alt='Etisalat' />
            <small
              className={'text-dark text-center text-lg-end m-auto m-lg-0'}
            >
              {t('footer.copyright', {
                year: DateTime.local().year,
                copyrightName: GlobalClientConfig.partnerCopyrightName,
              })}
            </small>
          </section>
          <section
            className={[
              'd-flex flex-column flex-lg-row mt-5',
              styles.mediaSection,
            ].join(' ')}
          >
            <section className={'mx-3 mb-3'}>
              {!props.preventNavigation && (
                <LearnLinks
                  className={[
                    'my-3 my-lg-0 mx-auto mx-lg-0',
                    styles.footerNav,
                  ].join(' ')}
                />
              )}
            </section>
            {!props.preventNavigation && (
              <ContactLinks className={styles.footerNav} />
            )}
          </section>
          <Branding className={styles.brandSection} />
        </section>
      </Container>
    </Navbar>
  );
};

export default Footer;
