import { useEffect } from 'react';
import { getTwitchAuthToken } from '../services/TwitchAPIService';
import { useTwitchAuthContext } from '../pages/WatchStreams/TwitchAuthContext';

const useGetTwitchAuthToken = () => {
  const [, setTwitchAuthContextState] = useTwitchAuthContext();
  useEffect(() => {
    getTwitchAuthToken()
      .then((res) => {
        if (res?.authResponse.access_token) {
          setTwitchAuthContextState({
            hasError: false,
            clientID: res.clientId,
            token: res.authResponse.access_token,
          });
        } else {
          throw new Error('Failure retrieving Twitch clientID & token!');
        }
      })
      .catch((e: Error) => {
        if (import.meta.env.MODE !== 'test') {
          console.error(e);
        }
        setTwitchAuthContextState({
          clientID: null,
          token: null,
          hasError: true,
        });
      });
  }, [setTwitchAuthContextState]);
};

export default useGetTwitchAuthToken;
