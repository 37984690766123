import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { Person } from '../../generated/graphql';
import subjectTypeFromGraphql from './subjectTypeFromGraphql';
import { Roles } from '../../components/RoleSelect/RoleSelect';
import { RecursivePartial } from '../../utilities/TSUtilities';

interface IRoles {
  roleId: string;
}

interface IGroupMember {
  id: string;
  account: { id: string };
  roles: IRoles[];
}

/**
 * Exactly the fields on a Team that are needed to check
 * the roles of a given user id.
 */
interface TeamWithGroupMembersAndRoles {
  id: string;
  groupMembers: IGroupMember[];
  __typename: 'Team';
}

type GroupMember = IGroupMember & {
  'account.id': IGroupMember['account']['id'];
  'roles.roleId': IRoles['roleId'];
};

type TeamWithGroupMembersAndRolesDotNotation = TeamWithGroupMembersAndRoles & {
  groupMembers: GroupMember[];
};

type Abilities =
  | ['manage', 'Team' | TeamWithGroupMembersAndRoles]
  | ['leave', 'Team' | TeamWithGroupMembersAndRoles];

export type AppAbility = Ability<Abilities>;
// https://casl.js.org/v5/en/advanced/typescript#application-ability
// eslint-disable-next-line
export const AppAbility = Ability as AbilityClass<AppAbility>;

const defineAbility = () => {
  const { build } = new AbilityBuilder(AppAbility);
  return build({ detectSubjectType: subjectTypeFromGraphql });
};

const ability = defineAbility();

export const updateAbility = (user: RecursivePartial<Person>) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  can<TeamWithGroupMembersAndRolesDotNotation>('manage', 'Team', {
    groupMembers: {
      $elemMatch: {
        'account.id': user.id,
        'roles.roleId': { $in: [Roles.ADMIN, Roles.OWNER] },
      },
    },
  });

  can<TeamWithGroupMembersAndRolesDotNotation>('leave', 'Team', {
    groupMembers: {
      $elemMatch: {
        'account.id': { $eq: user.id },
      },
    },
  });

  cannot<TeamWithGroupMembersAndRolesDotNotation>('leave', 'Team', {
    groupMembers: {
      $elemMatch: {
        'account.id': user.id,
        'roles.roleId': { $eq: Roles.OWNER },
      },
    },
  }).because(
    'You are the owner of that team. Transfer ownership before attempting to leave.'
  );

  ability.update(rules);
};

export default ability;
