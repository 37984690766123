import {
  createContext,
  SetStateAction,
  Dispatch,
  useContext,
  useState,
} from 'react';
import { getFromlocalStorage } from '../../utilities/Utilities';
import { TokenResponse } from './AuthenticationService';
import { StorageKey } from '../../config/Enums/App';

export interface AuthContextInterface {
  userId: string | null;
  newlyRegistered: boolean;
}

const AuthContext = createContext<
  | [AuthContextInterface, Dispatch<SetStateAction<AuthContextInterface>>]
  | undefined
>(undefined);

export const AuthContextProvider = ({ children }: any) => {
  const token = getFromlocalStorage<TokenResponse>(StorageKey.AuthToken);

  const authContext = useState<AuthContextInterface>({
    userId: token ? token.accountId : null,
    newlyRegistered: false,
  });

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'useAuthContext must be used inside an AuthContextProvider'
    );
  }

  return context;
};
