import { CatalogItemFragment } from 'src/generated/graphql';
import { useStoreContext } from '../StoreContext';
import { getDefaultDisplayPriceForProductGroup } from '../StoreHelpers';
import { StoreItemData } from '../useStoreReducer';
import { useTranslation } from 'react-i18next';

export type ItemOption = {
  name: string;
  imageUrl: string;
  productId: string;
};

type StoreItemDetailOptionProps = {
  itemOptions: StoreItemData[];
  onOptionClick?: (item: StoreItemData) => void;
};

const StoreItemDetailOptions = ({
  itemOptions = [],
  onOptionClick,
}: StoreItemDetailOptionProps) => {
  const { state, dispatch } = useStoreContext();
  const { t } = useTranslation('store');

  return (
    <div className='tw-flex tw-flex-wrap tw-gap-8'>
      {itemOptions.map((option) => (
        <button
          className={`
            tw-min-w-40 tw-w-40 tw-h-50 tw-min-h-40 tw-max-h-50 
            tw-flex-item tw-flex-grow tw-flex tw-justify-center tw-items-center tw-flex-col 
            tw-bg-white dark:tw-bg-zinc-900 tw-rounded-2xl tw-border tw-border-solid tw-p-4 ${
              state.selectedItem && state.selectedItem.itemId === option.itemId
                ? 'tw-border-orange-500'
                : 'tw-border-zinc-700'
            }`}
          onClick={() => {
            onOptionClick?.(option);
            dispatch({
              type: 'UPDATE_STORE_SELECTED_ITEM',
              payload: {
                item: option,
              },
            });
          }}
        >
          <img
            className='tw-rounded-lg tw-w-20 tw-h-20'
            src={option.itemImageUrl ?? ''}
            alt=''
          />
          <span className='tw-mt-4 tw-text-black dark:tw-text-zinc-100 tw-text-sm tw-font-normal tw-text-center'>
            {option.itemName}
          </span>
          <span className='tw-text-[#938F99] tw-text-xs tw-font-normal tw-text-center mt-1'>
            {t('price-from')}{' '}
            <span className='tw-text-[#F16E00] dark:tw-text-zinc-100'>
              {getDefaultDisplayPriceForProductGroup([
                option as CatalogItemFragment,
              ])}
            </span>
          </span>
        </button>
      ))}
    </div>
  );
};
export { StoreItemDetailOptions };
