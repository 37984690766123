import { useCallback } from 'react';

import BootstrapPagination from 'react-bootstrap/Pagination';

export interface PaginationData {
  totalCount: number;
  limit: number;
  offset: number;
  // Optionally supply a pageRange for navigation. Needs to be an odd number.
  // Defaults to DEFAULT_PAGE_RANGE
  pageRange?: number;
}

export interface Props {
  paginationData: PaginationData;
  handlePageChange: (offset: number) => void;
}

// Needs to be an odd number
const DEFAULT_PAGE_RANGE = 5;

const Pagination = ({
  handlePageChange,
  paginationData: { limit, totalCount, offset, pageRange = DEFAULT_PAGE_RANGE },
}: Props) => {
  const isFirstPageSelected = offset === 0;
  const isLastPageSelected = (totalCount: number, limit: number) =>
    Math.ceil(totalCount / limit) * limit - limit === offset;

  const prevPage = () => {
    handlePageChange(offset - limit);
  };

  const firstPage = useCallback(() => {
    handlePageChange(0);
  }, [handlePageChange]);

  const selectPage = (limit: number, pageIndex: number) => {
    handlePageChange(limit * pageIndex);
  };

  const nextPage = () => {
    handlePageChange(offset + limit);
  };

  const lastPage = (totalCount: number, limit: number) => {
    handlePageChange(Math.ceil(totalCount / limit) * limit - limit);
  };

  const renderPagination = () => {
    if (totalCount > limit) {
      return (
        <BootstrapPagination>
          <BootstrapPagination.First
            onClick={firstPage}
            disabled={isFirstPageSelected}
          />
          <BootstrapPagination.Prev
            disabled={isFirstPageSelected}
            onClick={prevPage}
          />

          {renderPaginationItems()}

          <BootstrapPagination.Next
            disabled={isLastPageSelected(totalCount, limit)}
            onClick={nextPage}
          />
          <BootstrapPagination.Last
            disabled={isLastPageSelected(totalCount, limit)}
            onClick={() => lastPage(totalCount, limit)}
          />
        </BootstrapPagination>
      );
    }

    return null;
  };

  const renderPaginationItems = () => {
    const numberOfPages = Math.ceil(totalCount / limit);
    const activePage = offset / limit;

    const numberOfPagesFromEnd = numberOfPages - activePage - 1;
    let prevPagesToShow = Math.floor(pageRange / 2);

    if (numberOfPagesFromEnd < Math.ceil(pageRange / 2)) {
      prevPagesToShow = pageRange - numberOfPagesFromEnd - 1;
    }

    const rangeStart = Math.max(0, activePage - prevPagesToShow);
    const rangeEnd = Math.min(numberOfPages, rangeStart + pageRange);

    let items: JSX.Element[] = [];

    if (rangeStart > 0) {
      items.push(
        <BootstrapPagination.Ellipsis
          key={'prev-ellipsis'}
          onClick={() => selectPage(limit, Math.max(0, activePage - pageRange))}
        />
      );
    }

    for (let i = rangeStart; i < rangeEnd; i++) {
      items.push(
        <BootstrapPagination.Item
          onClick={() => selectPage(limit, i)}
          key={`pagination-item-${i}`}
          active={activePage === i}
        >
          {i + 1}
        </BootstrapPagination.Item>
      );
    }

    if (rangeEnd < numberOfPages) {
      items.push(
        <BootstrapPagination.Ellipsis
          key={'post-ellipsis'}
          onClick={() =>
            selectPage(
              limit,
              Math.min(numberOfPages - 1, activePage + pageRange)
            )
          }
        />
      );
    }

    return items;
  };

  return (
    <div className={'d-flex justify-content-center'}>{renderPagination()}</div>
  );
};

export default Pagination;
