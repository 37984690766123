import { CSSProperties } from 'react';
import useMediaQuery from 'src/hooks/useMediaQuery';
import styles from './Background.module.scss';
type Props = {
  children?: JSX.Element | JSX.Element[];
  background?: string;
  mobileBackground?: string;
  className?: string;
  position?: string;
  // Use this if the <Background> component is the first thing on the page and needs top padding
  // to clear the menu.
  clearMenu?: boolean;
  style?: CSSProperties;
  noGradient?: boolean;
  imageGradient?: boolean;
  fitToHeight?: boolean;
};

const Background = (props: Props): JSX.Element => {
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <section
      className={[
        styles.container,
        props.className,
        props.clearMenu ? 'padding-top-menu-height' : null,
        props.fitToHeight ? styles.fitToHeight : null,
      ].join(' ')}
      style={{
        backgroundImage: props.background
          ? props.imageGradient
            ? `linear-gradient(to bottom, var(--body-bg-transparent) 70%, var(--body-bg) 100%), url(${
                isDesktop || !props.mobileBackground
                  ? props.background
                  : props.mobileBackground
              })`
            : `url(${
                isDesktop || !props.mobileBackground
                  ? props.background
                  : props.mobileBackground
              })`
          : 'none',
        backgroundPosition: props.position ? props.position : 'top center',
        ...props.style,
      }}
    >
      <div className={props.background && styles.overlay} />
      <div className={styles.content}>{props.children}</div>
      {!props.noGradient && <div className={styles.bottomGradient} />}
    </section>
  );
};

export default Background;
