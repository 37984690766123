import { useEffect } from 'react';
import { addTolocalStorage } from '../utilities/Utilities';
import { StorageKey } from '../config/Enums/App';
import { PaymentRedirectQueryParams } from '../config/Enums/QueryParams';
import Routes from '../config/Routes/Routes';
import { useAuthContext } from '../pages/Authentication/AuthContext';
import { useQueryParams } from 'use-query-params';

const useHandlePaymentRedirect = (
  handleUserRedirect: (to: string, state?: any) => void
) => {
  const [authContextState] = useAuthContext();
  const [urlQuery] = useQueryParams(PaymentRedirectQueryParams);

  useEffect(() => {
    const handlePaymentRedirect = () => {
      if (authContextState.userId) {
        const { paymentStatus: paymentStatusURLQuery } = urlQuery;

        if (paymentStatusURLQuery) {
          addTolocalStorage(StorageKey.PaymentStatus, paymentStatusURLQuery);
          handleUserRedirect(Routes.wallet, { paymentStatusURLQuery });
        }
      }
    };

    handlePaymentRedirect();
  }, [handleUserRedirect, authContextState.userId, urlQuery]);
};

export default useHandlePaymentRedirect;
