import { Fragment, Ref, RefObject } from 'react';

import { Link } from 'react-router-dom';

import Routes from '../../../../config/Routes/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUser } from 'HiveClient/components/Icons/Icons';
import ProfileAvatar from '../../../Avatar/ProfileAvatar';

import { AvatarSizes } from '../../../Avatar/Avatar';
import { AccountNotificationFragment } from '../../../../generated/graphql';
import useGetMinimalUserQuery from '../../../../hooks/useGetMinimalUserQuery';

import Spinner from '../../../Spinner/Spinner';
import SwarmioCurrencyBalance from '../../../../pages/Wallet/SwarmioCurrencyBalance';

import { getWalletBalanceFormatted } from '../../../../utilities/Wallet';

import styles from '../UserNavigation.module.scss';
import NotificationsToggle from '../NotificationsToggle/NotificationsToggle';
import Button from '../../../Button/Button';
import { useFeatureFlagsContext } from '../../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';

interface Props {
  forwardedRef: Ref<HTMLElement>;
  userId: string;
  toggleUserMenu: () => void;
  handleSignOut: () => void;
  notifToggleRef: RefObject<HTMLButtonElement>;
  toggleNotificationsMenu: () => void;
  setPopupNotification: (
    notification: AccountNotificationFragment | null
  ) => void;
}

const Authenticated = (props: Props) => {
  const { data, loading } = useGetMinimalUserQuery();

  const { checkFeatureFlags } = useFeatureFlagsContext();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {data?.user && (
        <Fragment>
          {checkFeatureFlags(FeaturesEnum.HeaderIcons) && (
            <NotificationsToggle
              userId={props.userId}
              notifToggleRef={props.notifToggleRef}
              setPopupNotification={props.setPopupNotification}
              toggleNotificationsMenu={props.toggleNotificationsMenu}
            />
          )}
          <section
            className={'d-flex align-items-center h-100 overflow-hidden'}
            data-cy={'authenticatedNav'}
          >
            {checkFeatureFlags(FeaturesEnum.Wallet) && (
              <Link
                data-semantic={'navbar-wallet-link'}
                to={Routes.wallet}
                className={[
                  'd-none',
                  'd-md-flex',
                  'align-items-center',
                  'text-decoration-none',
                  'p-0',
                  'mx-2',
                  styles.walletIcon,
                ].join(' ')}
              >
                <SwarmioCurrencyBalance
                  iconSize={'1.5rem'}
                  balance={getWalletBalanceFormatted(data.user)}
                />
              </Link>
            )}
            {/** User profile photo */}
            <Link
              data-semantic={'navbar-my-profile-link'}
              to={Routes.myProfile}
              className={['d-inline-block'].join(' ')}
            >
              <ProfileAvatar
                size={AvatarSizes.sm}
                photo={data.user.avatarURL}
                fallbackIcon={faUser}
                borderClassName={styles.avatarBorder}
              />
            </Link>
            {/** User profile dropdown menu */}
            <Button
              semantic={'authenticated-menu-toggle-user-menu'}
              variant={'hollow'}
              className={'d-flex align-items-center overflow-hidden p-0'}
              ref={props.forwardedRef}
              onClick={props.toggleUserMenu}
            >
              <span
                data-testid={'dropdown-display-name'}
                className={[
                  'ms-2',
                  'd-inline-block',
                  'text-truncate',
                  styles.displayName,
                ].join(' ')}
              >
                {data.user.displayName || ''}
              </span>
              <FontAwesomeIcon
                className={styles.userMenuIcon}
                icon={faChevronDown}
              />
            </Button>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Authenticated;
