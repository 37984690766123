import { Scalars } from '../../generated/graphql';

export enum ClientNotificationType {
  UpdatedEvent = 'UpdatedEventNotificationType',
  NewTeamInvitation = 'NewTeamInvitationNotificationType',
  JoinedEvent = 'JoinedEventNotificationType',
  DidNotJoinEvent = 'DidNotJoinEventNotificationType',
  // todo need to rename this to be consistent, but the backend is currently not consistent so to get it subscribe we will keep this for now
  // get kate to update this
  LeftEvent = 'LeftEventNotificationNotificationType',
}

export enum NotificationType {
  Account = 'temporaryAccountNotification',
  NewTeamInvitation = 'NewTeamInvitationNotificationType',
  SubscriptionPayment = 'UpdatedSubscriptionPaymentNotification',
  Payment = 'PaymentStatusUpdateNotification',
  EventStartingSoon = 'EventStartingInXAccountNotificationType',
  EventEnded = 'EventEndedAccountNotificationType',
  EventTimeChanged = 'EventTimeChangedAccountNotificationType',
  MatchStartingSoon = 'MatchStartingInXAccountNotificationType',
  MatchTimeChanged = 'MatchTimeChangedAccountNotificationType',
  KingOfTheHillSelection = 'KingOfTheHillSelectionAccountNotificationType',
  MatchEndedNotifications = 'MatchEndedAccountNotificationType',
}

export enum NotificationCompetitionType {
  MatchUp = 'MatchUpType',
  Tournament = 'TournamentType',
}

export type NewTeamInvitationNotificationFields = {
  teamId: string;
  teamName: string;
  affectedAccountId: string;
};

export type PaymentNotificationFields = {
  orderId: string;
  status: string;
};

// Event notification fields

export type EventStartingSoonNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  eventStartingAt: Scalars['Date'];
  temporaryCompetitionType: NotificationCompetitionType;
  hasStreams: boolean;
  xInSeconds: number;
};

export type EventEndedNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  placement: number;
  temporaryCompetitionType: NotificationCompetitionType;
};

export type EventTimeChangedNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  temporaryCompetitionType: NotificationCompetitionType;
  newStartTime: Scalars['Date'];
};

export type ConfirmParticipationNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  eventStartAt: string;
  registrationClosedAt: string;
};

// Match notification fields

export type MatchStartingSoonNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  competitionType: NotificationCompetitionType;
  matchId: string;
  xInSeconds: number;
  matchStartingAt: Scalars['Date'];
};

export type MatchTimeChangedNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  competitionType: NotificationCompetitionType;
  matchId: string;
  newStartTime: Scalars['Date'];
};

export type MatchEndedNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  competitionType: NotificationCompetitionType;
  matchId: string;
  matchEndedAt: string;
  placement: number;
};

// KOTH notification fields

export type KingOfTheHillSelectionNotificationFields = {
  id: string;
  eventId: string;
  eventName: string;
  matchStartingAt: Scalars['Date'];
};
