import { useEffect } from 'react';
import {
  GetUserForPermissionsDocument,
  GetUserForPermissionsQuery,
  GetUserForPermissionsQueryVariables,
} from '../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { getFromlocalStorage } from '../utilities/Utilities';
import { TokenResponse } from '../pages/Authentication/AuthenticationService';
import { StorageKey } from '../config/Enums/App';
import { updateAbility } from '../config/Casl/AbilityBuilder';
import { isTypename } from 'src/utilities/TSUtilities';

/**
 * Watches any fields in the get-user-for-permissions.graphql query that change,
 * and notifies parent when cache changes.
 *
 * Will not make network requests on its own, only looks in cache. We need this
 * as we don't want to manually have to update the AbilityBuilder from every query
 * or mutation that updates or gets new fields for our User object in cache.
 *
 * We instead subscribe to cache changes on fields we care about (get-user-for-permissions)
 * and update the AbilityBuilder any time those fields change.
 */
function useWatchGetUserForPermissions() {
  const client = useApolloClient();
  const token = getFromlocalStorage<TokenResponse>(StorageKey.AuthToken);
  const accountId = token?.accountId;

  useEffect(() => {
    if (accountId) {
      client
        .watchQuery<
          GetUserForPermissionsQuery,
          GetUserForPermissionsQueryVariables
        >({
          query: GetUserForPermissionsDocument,
          variables: { id: accountId },
          fetchPolicy: 'cache-only',
        })
        .subscribe((data) => {
          if (data.data.user && isTypename('Person', data.data.user)) {
            updateAbility(data.data.user);
          }
        });
    }
  }, [accountId, client]);
}

export default useWatchGetUserForPermissions;
