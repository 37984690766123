import { createContext, Dispatch, ReducerAction, useContext } from 'react';
import { StoreActions } from './StoreActions';
import { StoreState } from './useStoreReducer';

export interface IStoreContext {
  dispatch: Dispatch<
    ReducerAction<(state: StoreState, action: StoreActions) => StoreState>
  >;
  state: StoreState;
}

const StoreContext = createContext<IStoreContext | undefined>(undefined);

export const StoreContextProvider = ({
  value,
  children,
}: {
  value: IStoreContext;
  children: JSX.Element | JSX.Element[];
}) => <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;

export const useStoreContext = () => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error(
      'useStoreContext must be used within an <StoreContextProvider />'
    );
  }

  return context;
};
