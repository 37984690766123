import { Fragment, useRef, useState } from 'react';

import Notifications from '../../Notifications/Notifications';

import styles from './UserNavigation.module.scss';
import Authenticated from './Authenticated/Authenticated';
import { useOnClickOutside } from '../../../utilities/useOnClickOutside';
import { AccountNotificationFragment } from '../../../generated/graphql';
import UserMenu from './UserMenu/UserMenu';
import Unauthenticated from './Unauthenticated/Unauthenticated';
import { useAuthContext } from '../../../pages/Authentication/AuthContext';
import DisabledNav from './DisabledNav/DisabledNav';

export interface Props {
  atPageTop: boolean;
  preventNavigation: boolean;
  handleSignOut: () => void;
}

const UserNavigation = (props: Props) => {
  const [authContextState] = useAuthContext();

  const notifRef = useRef<HTMLDivElement>(null);
  const notifToggleRef = useRef<HTMLButtonElement>(null);
  const userDropdownToggleRef = useRef<HTMLElement>(null);
  const userDropdownRef = useRef<HTMLDivElement>(null);

  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  const dismissMenu = () => {
    setShowUserDropdown(false);
    setShowNotifications(false);
  };

  const [popupNotification, setPopupNotification] =
    useState<AccountNotificationFragment | null>(null);

  useOnClickOutside([userDropdownRef, userDropdownToggleRef], () => {
    setShowUserDropdown(false);
  });

  useOnClickOutside([notifRef, notifToggleRef], () => {
    setShowNotifications(false);
  });

  return (
    <Fragment>
      <div className={styles.userNavigation}>
        <div
          className={[
            styles.userNavigationContainer,
            props.atPageTop ? styles.pageTop : '',
          ].join(' ')}
        >
          <div className={styles.userMenuToggleContainer}>
            {props.preventNavigation ? (
              <DisabledNav
                handleSignOut={props.handleSignOut}
                userDropdownToggleRef={userDropdownToggleRef}
                setShowUserDropdown={setShowUserDropdown}
                showUserDropdown={showUserDropdown}
              />
            ) : authContextState.userId ? (
              <Authenticated
                forwardedRef={userDropdownToggleRef}
                toggleUserMenu={() => setShowUserDropdown(!showUserDropdown)}
                userId={authContextState.userId}
                handleSignOut={props.handleSignOut}
                notifToggleRef={notifToggleRef}
                setPopupNotification={setPopupNotification}
                toggleNotificationsMenu={() =>
                  setShowNotifications(!showNotifications)
                }
              />
            ) : (
              <Unauthenticated
                userDropdownToggleRef={userDropdownToggleRef}
                setShowUserDropdown={setShowUserDropdown}
                showUserDropdown={showUserDropdown}
              />
            )}
          </div>

          {authContextState.userId && !props.preventNavigation && (
            <div
              ref={notifRef}
              data-testid={`notif-menu-container${
                showNotifications ? '-show' : ''
              }`}
              className={[
                styles.userMenuContainer,
                styles.notifications,
                showNotifications ? styles.show : '',
              ].join(' ')}
            >
              <Notifications
                onClick={dismissMenu}
                popupNotification={popupNotification}
                setPopupNotification={setPopupNotification}
                show={showNotifications}
                setShowNotifications={() => setShowNotifications(true)}
              />
            </div>
          )}
          <div
            ref={userDropdownRef}
            className={[
              styles.userMenuContainer,
              showUserDropdown ? styles.show : '',
            ].join(' ')}
          >
            <UserMenu
              show={showUserDropdown}
              dismissMenu={dismissMenu}
              handleSignOut={props.handleSignOut}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserNavigation;
