import { faLanguage } from 'HiveClient/components/Icons/Icons';
import styles from '../UserNavigation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject } from 'react';
import { useLanguageContext } from '../../../LanguageMenu/LanguageContext';
import Button from '../../../Button/Button';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useTranslation } from 'react-i18next';

interface Props {
  userDropdownToggleRef: RefObject<HTMLElement>;
  setShowUserDropdown: (show: boolean) => void;
  handleSignOut: () => void;
  showUserDropdown: boolean;
}

const DisabledNav = (props: Props) => {
  const { t } = useTranslation();
  const [languageContextState] = useLanguageContext();

  return (
    <div className={'d-flex w-100'} data-cy={'disabledNav'}>
      <section className={'d-flex'}>
        <Button
          semantic={'sign-out'}
          onClick={() => props.handleSignOut()}
          variant={'hollow'}
          className={[
            styles.authLinks,
            'd-inline-block',
            'nav-link',
            'text-nowrap',
          ].join(' ')}
          data-semantic={'navbar-sign-in-link'}
        >
          {t('authenticated-nav.log-out')}
        </Button>
      </section>
      {languageContextState.hasMultipleLanguageSupport &&
        GlobalClientConfig.nav.orientation === 'horizontal' && (
          <Button
            semantic={'language-toggle'}
            variant={'hollow'}
            ref={props.userDropdownToggleRef}
            onClick={() => props.setShowUserDropdown(!props.showUserDropdown)}
            className={'d-flex align-items-center ms-2 p-0'}
          >
            <FontAwesomeIcon
              icon={faLanguage}
              className={styles.userMenuLanguage}
              size={'2x'}
            />
          </Button>
        )}
    </div>
  );
};

export default DisabledNav;
