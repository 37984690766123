import { RouteProps } from 'react-router-dom';

export interface ClientRoute {
  path: string;
  redirectPath?: string;
  component: NonNullable<RouteProps['component']>;
}

// these CANNOT be used outside of the client pack they belong to.
export const ClientRoutePaths: { [key: string]: string } = {};

// no client routes needed by default
export const ClientRoutes: ClientRoute[] = [];

export default ClientRoutes;
