import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import cacheConfig from './CacheConfig';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import createOnErrorLink from './createOnErrorLink';
import createRetryLink from './createRetryLink';
import createHttpLink from './createHttpLink';
import createLink from './createLink';

const cache = new InMemoryCache(cacheConfig);

const createClient = () =>
  new ApolloClient({
    name: GlobalClientConfig.clientName,
    link: ApolloLink.from([
      createLink(),
      createOnErrorLink(),
      createRetryLink(),
      createHttpLink(),
    ]),
    cache,
  });

export default createClient;
