import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

interface Props {
  name: string;
  isVIP: boolean;
}

const DisplayName = ({ name, isVIP }: Props) => (
  <Fragment>
    {name}
    {isVIP && (
      <FontAwesomeIcon icon={faCrown} className={'ms-2 text-primary'} />
    )}
  </Fragment>
);

export default DisplayName;
