import Table from 'react-bootstrap/Table';
import Pagination, { PaginationData } from '../Pagination/Pagination';

type Props<T> = {
  columns: string[];
  data: T[];
  renderRow: (rowData: T, index: number) => JSX.Element;
  paginationData?: PaginationData;
  handlePageChange?: (offset: number) => void;
  className?: string;
};

const DataTable = <T,>(props: Props<T>) => {
  const renderBody = () =>
    props.data.map((row, index) => props.renderRow(row, index));

  return (
    <div className={props.className}>
      <Table className={'text-center'} responsive borderless>
        <thead>
          <tr>
            {props.columns.map((column) => (
              <th key={`table-column-${column}`}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </Table>
      {props.paginationData && props.handlePageChange && (
        <Pagination
          paginationData={props.paginationData}
          handlePageChange={props.handlePageChange}
        />
      )}
    </div>
  );
};

export default DataTable;
