import {
  AccountNotificationFragment,
  GamingAccountNotificationType,
  GetMinimalUserQuery,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
} from '../../generated/graphql';
import { DateTime } from 'luxon';
import { QueryResult } from '@apollo/client';
//@ts-ignore
import notificationSound from '../../sounds/notification.mp3';
import {
  faMedal,
  faTrophy,
  faUsers,
  faRetweet,
  faWallet,
} from 'HiveClient/components/Icons/Icons';
import { generatePath } from 'react-router-dom';
import Routes from 'src/config/Routes/Routes';
import {
  EventDetailsURLActions,
  generateEventURL,
} from 'src/pages/Events/EventUtilities';
import { isTypename } from 'src/utilities/TSUtilities';
import {
  PaymentNotificationFields,
  EventEndedNotificationFields,
  EventStartingSoonNotificationFields,
  EventTimeChangedNotificationFields,
  KingOfTheHillSelectionNotificationFields,
  MatchEndedNotificationFields,
  MatchStartingSoonNotificationFields,
  MatchTimeChangedNotificationFields,
  NotificationCompetitionType,
  NotificationType,
  NewTeamInvitationNotificationFields,
} from './NotificationTypes';
import { TFunction } from 'i18next';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const sortUnexpiredNotifications = (
  notifications: GetNotificationsQuery['getNotifications']
) =>
  notifications
    .filter(
      (notification) =>
        DateTime.fromISO(notification.expiry, { zone: 'utc' }).toLocal() >
        DateTime.local()
    )
    .sort(
      (a, b) =>
        DateTime.fromISO(b.created, { zone: 'utc' }).toMillis() -
        DateTime.fromISO(a.created, { zone: 'utc' }).toMillis()
    );

const MILLISECONDS_BETWEEN_NOTIFICATION_SOUNDS = 3000;
function unboundSubscriptionHandler(
  this: { lastPlayed: number },
  notificationsQuery: QueryResult<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >,
  callBack: (notification: AccountNotificationFragment) => void
) {
  // todo: Think about why we need any of this.
  // If the useSubscription has been triggered, then that means there is a new
  // notification, we shouldn't have to compare the refetch of getNotifications
  // against its previous call. We know there is a new notification because of the subscription.

  // If anything, we should be searching through the new notifications for the one that
  // matches the one the subscription has responded to, as it's conceivable that there
  // is a even newer notification that we're not subscribed to, and this logic will
  // show the newer notification, not the one we're responding to.

  const previousNotifications = notificationsQuery.data?.getNotifications
    ? sortUnexpiredNotifications(notificationsQuery.data.getNotifications)
    : null;

  notificationsQuery.refetch().then((response) => {
    const newNotifications = sortUnexpiredNotifications(
      response.data.getNotifications
    );

    // Might not be perfect, but the idea is if the most recent notification
    // from the refetch isn't the same as the most recent notification prior
    // to the refetch, it means it's a new notification.
    if (
      (!previousNotifications?.length && newNotifications.length) ||
      (previousNotifications?.length &&
        newNotifications.length &&
        previousNotifications[0].id !== newNotifications[0].id)
    ) {
      callBack(newNotifications[0]);

      if (
        Date.now() - this.lastPlayed >=
        MILLISECONDS_BETWEEN_NOTIFICATION_SOUNDS
      ) {
        const sound = new Audio(notificationSound);
        sound.play();
        this.lastPlayed = Date.now();
      }
    }
  });
}

export const subscriptionHandler = unboundSubscriptionHandler.bind({
  lastPlayed: 0,
});

interface notificationDisplayProps {
  notification: AccountNotificationFragment;
  t: TFunction;
  userData?: GetMinimalUserQuery;
}

interface IGetNotificationDisplayReturn {
  icon: IconDefinition;
  label: string;
  link: string;
}

export const getNotificationDisplay = (
  props: notificationDisplayProps
): IGetNotificationDisplayReturn | null | undefined => {
  switch (props.notification.notificationType) {
    case NotificationType.NewTeamInvitation: {
      return teamInvitationNotificationDisplay(props);
    }
    case NotificationType.SubscriptionPayment: {
      return subscriptionPaymentNotificationDisplay(props);
    }
    case NotificationType.Payment: {
      return paymentNotificationDisplay(props);
    }
    case GamingAccountNotificationType.EventStartingInXAccountNotificationType: {
      return eventStartingSoonNotificationDisplay(props);
    }
    case GamingAccountNotificationType.EventEndedAccountNotificationType: {
      return eventEndedNotificationDisplay(props);
    }
    case GamingAccountNotificationType.EventTimeChangedAccountNotificationType: {
      return eventTimeChangedNotificationDisplay(props);
    }
    case GamingAccountNotificationType.MatchStartingInXAccountNotificationType: {
      return matchStartingSoonNotificationDisplay(props);
    }
    case GamingAccountNotificationType.MatchTimeChangedAccountNotificationType: {
      return matchTimeChangedNotificationDisplay(props);
    }
    case GamingAccountNotificationType.MatchEndedAccountNotificationType: {
      return matchEndedNotificationDisplay(props);
    }
    case GamingAccountNotificationType.KingOfTheHillSelectionAccountNotificationType: {
      return kingOfTheHillSelectionNotificationDisplay(props);
    }
    case GamingAccountNotificationType.ConfirmParticipationNotificationType: {
      return confirmParticipationNotificationDisplay(props);
    }
  }
};

const teamInvitationNotificationDisplay = ({
  notification,
  userData,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as NewTeamInvitationNotificationFields;

  // if user belongs to the team, then redirect to the team profile
  const groupMemberships = userData?.user?.groupMemberships ?? [];
  const groupMembership = groupMemberships.find(
    (groupMembership) => groupMembership.group.id === fields.teamId
  );

  const notificationRoute = groupMembership
    ? generatePath(Routes.viewTeam, {
        idOrPublicURL:
          isTypename('Team', groupMembership.group) &&
          groupMembership.group.publicURL
            ? groupMembership.group.publicURL
            : groupMembership.group.id,
      })
    : Routes.myTeams;

  let notificationMessage = notification.defaultMessage;

  if (fields.teamName) {
    notificationMessage = t('notification.invited-to-team', {
      teamName: fields.teamName,
    });
  }

  return {
    icon: faUsers,
    label: notificationMessage,
    link: notificationRoute,
  };
};

const subscriptionPaymentNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as PaymentNotificationFields;

  let notificationMessage = notification.defaultMessage;

  if (fields.status && fields.status === 'Approved') {
    notificationMessage = t('notification.subscription.approved');
  } else {
    // event starting in x minutes
    notificationMessage = t('notification.subscription.declined');
  }

  return {
    icon: faRetweet,
    label: notificationMessage,
    link: Routes.subscriptions,
  };
};

const paymentNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as PaymentNotificationFields;

  let notificationMessage = notification.defaultMessage;

  if (fields.status && fields.status === 'Success') {
    notificationMessage = t('notification.payment.success');
  } else {
    // event starting in x minutes
    notificationMessage = t('notification.payment.failure');
  }

  return {
    icon: faWallet,
    label: notificationMessage,
    link: Routes.wallet,
  };
};

const eventStartingSoonNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as EventStartingSoonNotificationFields;
  const competitionIcon =
    fields.temporaryCompetitionType === NotificationCompetitionType.MatchUp
      ? faMedal
      : faTrophy;

  let notificationMessage = notification.defaultMessage;

  if (fields.eventName && fields.xInSeconds === 0) {
    // event has started
    if (
      fields.temporaryCompetitionType === NotificationCompetitionType.MatchUp
    ) {
      if (fields.hasStreams) {
        // has streams koth
        notificationMessage = t('notification.koth-with-stream-has-started', {
          eventName: fields.eventName,
        });
      } else {
        // no streams koth
        notificationMessage = t('notification.koth-has-started', {
          eventName: fields.eventName,
        });
      }
    } else {
      // tournament
      notificationMessage = t('notification.tournament-has-started', {
        eventName: fields.eventName,
      });
    }
  } else if (fields.eventName && fields.xInSeconds) {
    // event starting in x minutes
    notificationMessage = t('notification.event-starts-in-minutes', {
      eventName: fields.eventName,
      minutes: fields.xInSeconds / 60,
    });
  }

  return {
    icon: competitionIcon,
    label: notificationMessage,
    link: generateEventURL(fields.eventId),
  };
};

const eventEndedNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as EventEndedNotificationFields;

  if (fields.temporaryCompetitionType === NotificationCompetitionType.MatchUp) {
    return null;
  }

  const notificationMessage =
    fields.eventName && fields.placement === 1
      ? t('notification.event-ended.winner', {
          eventName: fields.eventName,
        })
      : t('notification.event-ended.loser');

  return {
    icon: faTrophy,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.match),
  };
};

const eventTimeChangedNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as EventTimeChangedNotificationFields;
  const competitionIcon =
    fields.temporaryCompetitionType === NotificationCompetitionType.MatchUp
      ? faMedal
      : faTrophy;

  const notificationMessage = t('notification.event-time-changed', {
    eventName: fields.eventName,
  });

  return {
    icon: competitionIcon,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.overview),
  };
};

const matchStartingSoonNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as MatchStartingSoonNotificationFields;
  const competitionIcon =
    fields.competitionType === NotificationCompetitionType.MatchUp
      ? faMedal
      : faTrophy;

  let notificationMessage = notification.defaultMessage;

  if (
    fields.eventName &&
    fields.competitionType === NotificationCompetitionType.MatchUp
  ) {
    notificationMessage = t('notification.koth-match-starting-soon', {
      eventName: fields.eventName,
    });
  } else if (
    fields.eventName &&
    fields.competitionType === NotificationCompetitionType.Tournament
  ) {
    const minutesUntil = fields.xInSeconds / 60;
    notificationMessage =
      minutesUntil === 5
        ? t('notification.match-starting-soon')
        : t('notification.match-starting', { eventName: fields.eventName });
  }

  return {
    icon: competitionIcon,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.match),
  };
};

const matchTimeChangedNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as MatchTimeChangedNotificationFields;
  const competitionIcon =
    fields.competitionType === NotificationCompetitionType.MatchUp
      ? faMedal
      : faTrophy;

  const notificationMessage = t('notification.match-time-changed', {
    eventName: fields.eventName,
  });

  return {
    icon: competitionIcon,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.match),
  };
};

const matchEndedNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields = notification.fields as MatchEndedNotificationFields;

  if (fields.competitionType === NotificationCompetitionType.Tournament) {
    return null;
  }

  const notificationMessage =
    fields.eventName && fields.placement === 1
      ? t('notification.match-ended.winner', {
          eventName: fields.eventName,
        })
      : t('notification.match-ended.loser');

  return {
    icon: faTrophy,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.match),
  };
};

const kingOfTheHillSelectionNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields =
    notification.fields as KingOfTheHillSelectionNotificationFields;
  const notificationMessage = fields.eventName
    ? t('notification.koth-selected', {
        eventName: fields.eventName,
      })
    : notification.defaultMessage;

  return {
    icon: faMedal,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.match),
  };
};

const confirmParticipationNotificationDisplay = ({
  notification,
  t,
}: notificationDisplayProps) => {
  const fields =
    notification.fields as KingOfTheHillSelectionNotificationFields;
  const notificationMessage = fields.eventName
    ? t('notification.confirm-participation', {
        eventName: fields.eventName,
      })
    : notification.defaultMessage;

  return {
    icon: faMedal,
    label: notificationMessage,
    link: generateEventURL(fields.eventId, EventDetailsURLActions.confirm),
  };
};
