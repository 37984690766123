import { Fragment } from 'react';

export const StoreItemDetailPromo = () => (
  <Fragment>
    <div className='tw-Promotions tw-w-96 tw-h-12 tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-inline-flex'>
      <div className='tw-Promotions tw-w-80 tw-text-[#938F99] tw-text-base tw-font-normal tw-leading-tight'>
        Promotions
      </div>
      <div className='tw-Promo tw-self-stretch tw-justify-between tw-items-center tw-gap-2 tw-inline-flex'>
        <div className='tw-Left tw-rounded-3xl tw-justify-center tw-items-center tw-gap-4 tw-flex'>
          <div className='tw-Cta tw-text-black dark:tw-text-zinc-100 tw-text-lg tw-font-medium tw-leading-snug'>
            Use
          </div>
          <div className='tw-Currency tw-justify-center tw-items-center tw-gap-1 tw-flex'>
            <div className='tw-EmberGemsThin tw-w-4 tw-h-4 tw-relative' />
            <div className='tw-210 tw-text-center tw-text-black dark:tw-text-zinc-100 tw-text-2xl tw-font-bold tw-leading-7'>
              4,210
            </div>
          </div>
          <div className='tw-Cta tw-text-black dark:tw-text-zinc-100 tw-text-lg tw-font-medium tw-leading-snug'>
            for $4.21 off
          </div>
        </div>
        <div className='tw-Toggle tw-w-16 tw-h-8 tw-relative tw-bg-rose-600 tw-rounded-full'>
          <div className='tw-Ellipse3 tw-w-6 tw-h-6 tw-left-[36px] tw-top-[4px] tw-absolute tw-bg-white tw-rounded-full' />
        </div>
      </div>
    </div>

    <div className='tw-PromoCode tw-w-96 tw-h-20 tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-inline-flex'>
      <div className='tw-EnterPromoCodeHere tw-self-stretch tw-text-[#938F99] tw-text-base tw-font-normal tw-leading-tight'>
        Enter promo code here
      </div>
      <div className='tw-TextField tw-w-96 tw-px-4 tw-py-6 tw-bg-white dark:tw-bg-zinc-900 tw-rounded-2xl tw-border tw-border-zinc-700 tw-justify-start tw-items-center tw-gap-2 tw-inline-flex'>
        <div className='tw-grow tw-shrink tw-basis-0 tw-opacity-0 tw-text-black dark:tw-text-zinc-100 tw-text-base tw-font-normal tw-leading-tight'>
          ...
        </div>
        <div className='tw-Apply tw-text-rose-600 tw-text-base tw-font-normal tw-leading-tight'>
          Apply
        </div>
      </div>
    </div>
  </Fragment>
);
