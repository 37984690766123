import styles from './VerticalNav.module.scss';
import useGetNavLinks from '../useGetNavLinks';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useRef, useState } from 'react';
import Button from '../../Button/Button';
import {
  faChevronDown,
  faChevronRight,
  faLanguage,
} from 'HiveClient/components/Icons/Icons';
import { useTranslation } from 'react-i18next';
import Routes from '../../../config/Routes/Routes';
import LogoIcon from 'HiveClient/images/logo-icon.png';
import Logo from 'HiveClient/images/logo.png';
import VerticalNavItem from './VerticalNavItem';
import { useOnClickOutside } from '../../../utilities/useOnClickOutside';
import { useLanguageContext } from '../../LanguageMenu/LanguageContext';
import LanguageService from '../../../services/LanguageService';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import EmberLogo from './EmberLogo';
import { useFeatureFlagsContext } from '../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../config/FeatureFlags/FeaturesEnum';

interface IProps {
  preventNavigation: boolean;
}
const VerticalNav = (props: IProps) => {
  const { t } = useTranslation();
  const navLinks = useGetNavLinks();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [languageMenuIsExpanded, setLanguageMenuIsExpanded] = useState(false);
  const verticalNavRef = useRef<HTMLDivElement>(null);
  const [languageContextState] = useLanguageContext();
  const { checkFeatureFlags } = useFeatureFlagsContext();

  useOnClickOutside([verticalNavRef], () => {
    setLanguageMenuIsExpanded(false);
    setIsExpanded(false);
  });

  const scrollBarWidthChecker = () => {
    // We need to know the width of the scrollbar for browsers that use the width
    // as part of the total width of the element's calculations, so we can add it
    // to the max width calculation in order to get the opening animation we want.
    const root = document.documentElement;

    const scrollbarWidth = verticalNavRef.current
      ? verticalNavRef.current.offsetWidth - verticalNavRef.current.clientWidth
      : 0;

    root.style.setProperty(
      '--vertical-nav-scrollbar-width',
      scrollbarWidth + 'px'
    );
  };

  useEffect(() => {
    window.addEventListener('resize', scrollBarWidthChecker);
    return () => window.removeEventListener('resize', scrollBarWidthChecker);
  }, []);

  scrollBarWidthChecker();

  const renderLanguageMenu = () => (
    <Fragment>
      <Button
        semantic={'language-menu-toggle'}
        variant={'hollow'}
        className={[isExpanded ? styles.navItemExpanded : styles.navItem].join(
          ' '
        )}
        onClick={() => {
          setIsExpanded(true);
          setLanguageMenuIsExpanded((prevState) => !prevState);
        }}
      >
        <VerticalNavItem
          isExpanded={isExpanded}
          icon={
            <FontAwesomeIcon className={styles.navItemIcon} icon={faLanguage} />
          }
          title={
            <span>
              {t('vertical-menu.language')}
              <FontAwesomeIcon
                icon={faChevronDown}
                className={[
                  languageMenuIsExpanded
                    ? styles.languageExpandButtonIconExpanded
                    : styles.languageExpandButtonIcon,
                  'ms-1 rtl-maintain-orientation',
                ].join(' ')}
              />
            </span>
          }
        />
      </Button>

      <div
        className={[
          languageMenuIsExpanded
            ? styles.languageMenuExpanded
            : styles.languageMenuCollapsed,
          'd-flex flex-column my-1',
        ].join(' ')}
      >
        {languageContextState.supportedLanguages.map((language) => (
          <Button
            semantic={'language-menu-choice'}
            variant={'hollow'}
            key={language.code}
            onClick={() => {
              LanguageService.userInitiatedLanguageSwitch(language.code);
            }}
            className={'text-start'}
          >
            <span className={'small'}>{language.name}</span>
          </Button>
        ))}
      </div>
    </Fragment>
  );

  return (
    <div
      className={isExpanded ? styles.containerExpanded : styles.container}
      ref={verticalNavRef}
    >
      {props.preventNavigation ? (
        <Fragment>
          <NavLink
            data-semantic={'navbar-HOME-link-disabled'}
            className={`${
              isExpanded ? styles.logoExpanded : styles.logo
            } cursor-default`}
            to={'#'}
          >
            {isExpanded ? (
              <img src={Logo} alt={'Logo'} />
            ) : (
              <img src={LogoIcon} alt={'Logo'} />
            )}
          </NavLink>
          <div className={'mt-auto'}>
            {languageContextState.supportedLanguages.length > 1 &&
              renderLanguageMenu()}

            <Button
              semantic={'vertical-nav-toggle-expand'}
              variant={'hollow'}
              className={
                isExpanded ? styles.expandButtonExpanded : styles.expandButton
              }
              onClick={() =>
                setIsExpanded((prevState) => {
                  if (prevState) {
                    setLanguageMenuIsExpanded(false);
                  }

                  return !prevState;
                })
              }
            >
              <VerticalNavItem
                isExpanded={isExpanded}
                icon={
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={
                      isExpanded
                        ? styles.expandButtonIconExpanded
                        : styles.expandButtonIcon
                    }
                  />
                }
                title={t('vertical-menu.collapse-menu')}
              />
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <NavLink
            data-semantic={'navbar-HOME-link'}
            className={isExpanded ? styles.logoExpanded : styles.logo}
            to={Routes.home}
          >
            {isExpanded ? (
              <img src={Logo} alt={'Logo'} />
            ) : (
              <img src={LogoIcon} alt={'Logo'} />
            )}
          </NavLink>
          {checkFeatureFlags(FeaturesEnum.HeaderIcons) && (
            <>
              <nav className={'my-auto'}>
                {navLinks
                  .filter((link) => !link.hideOnVertical)
                  .map((link) => (
                    <NavLink
                      exact
                      className={[
                        'btn',
                        isExpanded ? styles.navLinkExpanded : styles.navLink,
                        location.pathname === link.url ? ' btn-primary' : '',
                      ].join(' ')}
                      key={link.title}
                      to={{ pathname: link.url }}
                      target={link.external ? '_blank' : '_self'}
                      data-semantic={`navbar-${link.semantic}-link`}
                    >
                      <VerticalNavItem
                        isExpanded={isExpanded}
                        icon={
                          <FontAwesomeIcon
                            className={styles.navItemIcon}
                            icon={link.icon}
                          />
                        }
                        title={link.title}
                      />
                    </NavLink>
                  ))}
              </nav>
              <div className={'mt-auto'}>
                {languageContextState.supportedLanguages.length > 1 &&
                  renderLanguageMenu()}
                {GlobalClientConfig.marketingSiteUrl && (
                  <NavLink
                    exact
                    className={[
                      'btn',
                      isExpanded ? styles.navLinkExpanded : styles.navLink,
                    ].join(' ')}
                    to={{ pathname: GlobalClientConfig.marketingSiteUrl }}
                    target={'_blank'}
                    data-semantic={'navbar-marketing-site-link'}
                  >
                    <VerticalNavItem
                      isExpanded={isExpanded}
                      icon={<EmberLogo className={styles.navItemIcon} />}
                      title={GlobalClientConfig.clientName}
                    />
                  </NavLink>
                )}
                <Button
                  semantic={'vertical-nav-toggle-expand'}
                  variant={'hollow'}
                  className={
                    isExpanded
                      ? styles.expandButtonExpanded
                      : styles.expandButton
                  }
                  onClick={() =>
                    setIsExpanded((prevState) => {
                      if (prevState) {
                        setLanguageMenuIsExpanded(false);
                      }

                      return !prevState;
                    })
                  }
                >
                  <VerticalNavItem
                    isExpanded={isExpanded}
                    icon={
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className={
                          isExpanded
                            ? styles.expandButtonIconExpanded
                            : styles.expandButtonIcon
                        }
                      />
                    }
                    title={t('vertical-menu.collapse-menu')}
                  />
                </Button>
              </div>
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default VerticalNav;
