import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import LogoIcon from 'HiveClient/images/favicon.png';
import LogoWithNotificationsIcon from 'HiveClient/images/favicon-notification.png';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useNotificationsContext } from '../Notifications/NotificationsContext';

interface Props {
  title?: string;
}
const ClientHelmet = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const siteTitle = GlobalClientConfig.siteTitle;

  const notificationsContext = useNotificationsContext();

  // react-helmet had weirdness when trying to handle changing the favicon dynamically.
  // It would correctly change to the notification favicon, but after checking notifications
  // it wasn't switching back without a route change, even though the <link> with the notifications
  // favicon was being removed from the DOM.

  // I'm explicitly handling changing the href here instead, and avoiding handling favicon with react-helmet completely.
  const favicon = document.getElementById('favicon');
  if (favicon) {
    (favicon as HTMLLinkElement).href =
      notificationsContext.numberOfNotifications
        ? LogoWithNotificationsIcon
        : LogoIcon;
  }

  return (
    <Helmet
      defaultTitle={
        notificationsContext.numberOfNotifications
          ? `(${notificationsContext.numberOfNotifications}) ${siteTitle}`
          : siteTitle
      }
      titleTemplate={`%s | ${siteTitle}`}
      defer={false}
    >
      <meta
        name='description'
        content={t('client-helmet.description', { clientName: siteTitle })}
      />
      <meta name='theme-color' content={t('client-helmet.theme-color')} />
      {props.title && (
        <title>
          {notificationsContext.numberOfNotifications
            ? `(${notificationsContext.numberOfNotifications}) ${props.title}`
            : props.title}
        </title>
      )}
    </Helmet>
  );
};

export default ClientHelmet;
