import { Link, NavLink, useLocation } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import useGetNavLinks from '../../useGetNavLinks';
import Container from 'react-bootstrap/Container';
import { useFeatureFlagsContext } from '../../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';

type Props = {
  preventNavigation: boolean;
  logo: JSX.Element;
};

const HeaderNav = (props: Props) => {
  const location = useLocation();
  const links = useGetNavLinks();

  const { checkFeatureFlags } = useFeatureFlagsContext();

  if (props.preventNavigation) {
    return (
      <Navbar>
        <Container fluid>
          <Navbar.Brand>{props.logo}</Navbar.Brand>
        </Container>
      </Navbar>
    );
  }

  return (
    <Navbar variant={'dark'} className={'py-0'}>
      <Container fluid>
        <Navbar.Brand
          as={Link as any}
          to={'/'}
          data-semantic={'navbar-home-link'}
        >
          {props.logo}
        </Navbar.Brand>
        {checkFeatureFlags(FeaturesEnum.HeaderIcons) && (
          <section className={'d-none d-lg-flex w-100 align-items-between'}>
            <Nav className={'me-auto'} activeKey={location.pathname}>
              {links
                .filter((link) => !link.hideOnHorizontal)
                .map((link) => (
                  <Nav.Link
                    eventKey={link.url}
                    key={link.url}
                    as={link.external ? 'a' : NavLink}
                    //@ts-ignore
                    to={link.url}
                    // in case it's external, we need href. Doesn't seem to have an bad impact on NavLink
                    href={link.url}
                    target={link.external ? '_blank' : '_self'}
                    data-semantic={`navbar-${link.semantic}-link`}
                  >
                    {link.title}
                  </Nav.Link>
                ))}
            </Nav>
          </section>
        )}
      </Container>
    </Navbar>
  );
};

export default HeaderNav;
