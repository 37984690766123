import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend, { I18NextChainedBackend } from 'i18next-chained-backend';
import HTTPBackend, { BackendOptions } from 'i18next-http-backend';

import LanguageService from '../../services/LanguageService';
import { TRANSLATIONS_SERVICE_URL } from '../Endpoints/Endpoints';
import FeatureFlags from 'HiveClient/config/FeatureFlags/FeatureFlags';
import { FeaturesEnum } from '../FeatureFlags/FeaturesEnum';

function getBackend(clientName: string) {
  // this is temp solution to make releases easier eventually they will all have
  // remote localizations and the static fallback backend
  const backend: I18NextChainedBackend.BackendOptions = {
    backends: [HTTPBackend],
    backendOptions: [
      {
        loadPath: () => {
          console.log(
            'LOAD_PATH',
            import.meta.env.DEV
              ? `/src/clients/${clientName}/localization/{{lng}}/{{ns}}.json`
              : `${
                  import.meta.env.VITE_PUBLIC_URL
                }/localization/{{lng}}/{{ns}}.json`
          );

          return import.meta.env.DEV
            ? `/src/clients/${clientName}/localization/{{lng}}/{{ns}}.json`
            : `${
                import.meta.env.VITE_PUBLIC_URL
              }/localization/{{lng}}/{{ns}}.json`;
        },
      } as BackendOptions,
    ],
  };

  if (FeatureFlags[FeaturesEnum.RemoteLocalizations]) {
    // need to add the primary backend to the front of the array as the fallback needs to be defined last
    // doesn't actually matter for the backend but will for the backend options
    backend.backends?.unshift(HTTPBackend);
    backend.backendOptions?.unshift({
      addPath: `${TRANSLATIONS_SERVICE_URL}/clients/${clientName}/namespaces/{{ns}}/language/{{lng}}`,
      parsePayload: function (
        namespace: string,
        key: string,
        fallbackValue: string
      ) {
        return {
          key,
          fallbackValue,
        };
      },
      crossDomain: true,
      loadPath: `${TRANSLATIONS_SERVICE_URL}/clients/${clientName}/namespaces/{{ns}}/language/{{lng}}`,
    });
  }

  return backend;
}

function initi18n(clientName: string) {
  const langCode = LanguageService.getInitialLanguage();

  return (
    i18n
      // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(ChainedBackend)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init(
        {
          backend: getBackend(clientName),
          defaultNS: 'translation',
          debug: import.meta.env.DEV,
          lng: langCode,
          // Fallback language for missing keys. Means both sets of translation files are loaded,
          // their currently selected language - if not the fallback - and the fallback files.
          fallbackLng: 'en',
          saveMissing: FeatureFlags[FeaturesEnum.RemoteLocalizations],
          saveMissingTo: 'all',
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          load: 'currentOnly',
        },
        () => {}
      )
      .then(() => LanguageService.setInitialLanguage())
      .catch((err) => {
        console.error(err);
      })
  );
}

export default initi18n;
