import { RetryLink } from '@apollo/client/link/retry';

const createRetryLink = () =>
  new RetryLink({
    delay: {
      initial: 2000,
      max: 2000,
      jitter: false,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) =>
        Boolean(
          Array.isArray(error?.result?.errors) &&
            error.result.errors.length &&
            error.result.errors[0].message === 'Too many fetches in progress'
        ),
    },
  });

export default createRetryLink;
