import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';

type Props = {
  className: string;
  children: JSX.Element[];
  onClick: () => void;
  link: string;
  tabIndex?: number;
};

const NavItem = (props: Props) => (
  <NavDropdown.Item
    tabIndex={props.tabIndex}
    as={NavLink}
    to={props.link}
    onClick={props.onClick}
    className={[props.className, 'p-2', 'fw-bold'].join(' ')}
  >
    {props.children}
  </NavDropdown.Item>
);

export default NavItem;
