import { Dispatch, SetStateAction, useEffect } from 'react';
import Routes from '../config/Routes/Routes';
import {
  GetMinimalUserDocument,
  GetMinimalUserQuery,
  GetMinimalUserQueryVariables,
} from '../generated/graphql';
import { isTypename } from '../utilities/TSUtilities';
import { handleSignOut, State } from '../App';
import { useApolloClient } from '@apollo/client';
import MsisdnService from '../services/MsisdnService';
import { useAuthContext } from '../pages/Authentication/AuthContext';

const useHandleCompleteAccountRedirect = (
  handleUserRedirect: (to: string, state?: any) => void,
  setAppState: Dispatch<SetStateAction<State>>
) => {
  const client = useApolloClient();
  const [authContextState] = useAuthContext();

  useEffect(() => {
    if (authContextState.userId) {
      client
        .query<GetMinimalUserQuery, GetMinimalUserQueryVariables>({
          query: GetMinimalUserDocument,
          variables: {
            id: authContextState.userId,
          },
        })
        .then((result) => {
          if (result.data.user && isTypename('Person', result.data.user)) {
            const msisdn = MsisdnService.getMsisdnValue(
              result.data.user.msisdn
            );

            const redirectToMsisdnPage = MsisdnService.mustRedirectToMsisdnForm(
              result.data.user.msisdnVerified
            );

            const redirectToVerifySMSPage = MsisdnService.mustRedirectToVerifySMS(
              msisdn,
              result.data.user.msisdnVerified
            );

            const displayName = result.data.user.displayName;

            // If the user doesn't have a display name, it means they've registered with social auth
            // and need to pick a swarmio displayName, so redirect them to the complete account.
            const needsToSetDisplayName = !displayName;

            const email =
              result.data.user.email &&
              isTypename('StringAttribute', result.data.user.email)
                ? result.data.user.email.value
                : null;

            setAppState((prevState) => ({
              ...prevState,
              email,
              displayName,
              msisdn,
              msisdnVerified: result.data.user?.msisdnVerified ?? false,
              preventNavigation: needsToSetDisplayName || redirectToMsisdnPage || redirectToVerifySMSPage,
            }));

            if (needsToSetDisplayName) {
              handleUserRedirect(Routes.completeAccount);
            } else if (redirectToVerifySMSPage) {
              handleUserRedirect(Routes.verifySMS);
            } else if (redirectToMsisdnPage) {
              handleUserRedirect(Routes.msisdn);
            }
          } else {
            // They somehow have an account id in localStorage that doesn't return a user from the backend.
            // This shouldn't happen.
            handleSignOut();
          }
        });
    }
  }, [client, handleUserRedirect, authContextState.userId, setAppState]);
};
export default useHandleCompleteAccountRedirect;
