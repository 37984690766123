/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from 'react-i18next';

import { Fragment, MouseEventHandler } from 'react';

interface Props {
  displayType?: 'small' | 'medium' | 'list' | 'dynamic' | 'stretch' | 'search';
  name: string;
  displayPrice?: string;
  productType?: string;
  image: string;
  onClick: () => void;
}

function StoreItemFormOfList(props: {
  onClick?: MouseEventHandler<HTMLDivElement>;
  name: string;
  productType?: string;
  image: string;
  price?: string;
}) {
  const { t } = useTranslation('store');

  return (
    <div
      className='tw-StoreCardsList tw-max-w-96 tw-min-h-16 tw-justify-start tw-items-center tw-gap-4 tw-inline-flex'
      onClick={props.onClick}
      role='button'
      tabIndex={0}
    >
      <img
        className='tw-BannerImage tw-w-28 tw-h-16 tw-rounded-xl tw-border tw-border-zinc-700'
        src={props.image}
      />
      <div className='tw-Frame3 tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-inline-flex'>
        <div className='tw-PubgmSeasonPass tw-self-stretch tw-min-h-4 tw-text-black dark:tw-text-zinc-100 tw-text-lg tw-font-bold  tw-leading-snug'>
          {props.name}
        </div>
        {(props.price !== undefined || props.productType !== undefined) && (
          <div className='tw-Frame1 tw-justify-start tw-items-center tw-gap-2 tw-inline-flex'>
            {props.productType !== undefined && (
              <div className='tw-TopUp tw-text-[#938F99] tw-text-sm tw-font-normal  tw-leading-none'>
                {props.productType}
              </div>
            )}
            <div className='tw-Line1 tw-w-3 tw-self-stretch tw-origin-top-left tw-rotate-90 tw-border tw-border-zinc-500'></div>
            <div className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
              {props.price !== undefined && (
                <>
                  <span className='tw-text-[#938F99] tw-text-xs tw-font-normal tw-leading-none'>
                    {t('price-from')}
                  </span>
                  <span className='tw-099 tw-text-[#F16E00] dark:tw-text-zinc-100 tw-text-base tw-font-medium  tw-leading-tight'>
                    {props.price}
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function StoreItemFormOfSearch(props: {
  onClick?: MouseEventHandler<HTMLDivElement>;
  image: string;
  name: string;
}) {
  return (
    <div
      className='tw-StoreCardsList tw-w-auto tw-min-h-10 tw-justify-start tw-items-center tw-gap-2 tw-inline-flex hover:tw-bg-[#504A53] tw-rounded-xl'
      onClick={props.onClick}
      role='button'
      tabIndex={0}
    >
      <img
        className={`tw-BannerImage tw-max-w-48 tw-max-h-28 tw-rounded-xl tw-border tw-object-cover tw-border-zinc-700 ${
          props.image && 'tw-h-16 tw-w-16'
        }`}
        src={props.image}
        loading='lazy'
      />
      <div className='tw-Frame3 tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-center tw-items-center tw-gap-2'>
        <div className='tw-PubgmSeasonPass tw-self-stretch tw-min-h-4 tw-text-[#F16E00] dark:tw-text-zinc-100 tw-text-lg tw-font-bold  tw-leading-snug'>
          {props.name}
        </div>
      </div>
    </div>
  );
}

function StoreItemFormOfMedium(props: {
  onClick?: MouseEventHandler<HTMLDivElement>;
  image: string;
  name: string;
  productType?: string;
  price?: string;
}) {
  const { t } = useTranslation('store');

  return (
    <div
      className={`tw-StoreCardsMedium !tw-w-56 tw-min-h-48 tw-flex-col tw-justify-start tw-gap-2 tw-inline-flex ${
        document.dir === 'rtl' ? 'tw-items-end' : 'tw-items-start'
      }`}
      onClick={props.onClick}
      role='button'
      tabIndex={0}
    >
      <img
        className='tw-BannerImage tw-w-60 tw-h-32 tw-rounded-2xl tw-border tw-border-zinc-700'
        src={props.image}
      />
      <div className='tw-ItemName tw-text-black dark:tw-text-zinc-100 tw-text-lg tw-font-bold tw-leading-snug'>
        {props.name}
      </div>
      {props.productType !== undefined && (
        <div className='tw-TopUp tw-text-[#938F99] tw-text-sm tw-font-normal tw-leading-none'>
          {props.productType}
        </div>
      )}
      {props.price !== undefined && (
        <div className='tw-Frame1 tw-justify-start tw-items-center tw-gap-2 tw-inline-flex'>
          <div className='tw-TopUp tw-text-[#938F99] tw-text-sm tw-font-normal tw-leading-none'>
            {t('price-from')}
          </div>
          <br />
          <div className='tw-Line1 tw-w-1 tw-self-stretch tw-origin-top-left tw-rotate-90 tw-border tw-border-zinc-500'></div>
          <div className='tw-099 tw-text-[#F16E00] dark:tw-text-zinc-100 tw-text-base tw-font-semibold tw-leading-tight'>
            {props.price}
          </div>
        </div>
      )}
    </div>
  );
}

function StoreItemFormOfStretch(props: {
  onClick?: MouseEventHandler<HTMLDivElement>;
  image: string;
  name: string;
  productType?: string;
  price?: string;
}) {
  const { t } = useTranslation('store');

  return (
    <div
      className={`tw-StoreCardsMedium tw-inline-flex tw-flex-col tw-justify-start tw-w-11/12 tw-gap-2 ${
        document.dir === 'rtl' ? 'tw-items-end' : 'tw-items-start'
      }`}
      onClick={props.onClick}
      role='button'
      tabIndex={0}
    >
      <img
        className='tw-BannerImage tw-w-full tw-rounded-2xl tw-border tw-border-zinc-700'
        src={props.image}
      />
      <div className='tw-ItemName tw-text-black dark:tw-text-zinc-100 tw-text-lg tw-font-bold tw-leading-snug'>
        {props.name}
      </div>
      {props.productType !== undefined && (
        <div className='tw-TopUp tw-text-[#938F99] tw-text-sm tw-font-normal tw-leading-none'>
          {props.productType}
        </div>
      )}
      {props.price !== undefined && (
        <div className='tw-Frame1 tw-justify-start tw-items-center tw-gap-2 tw-inline-flex'>
          <div className='tw-TopUp tw-text-[#938F99] tw-text-sm tw-font-normal tw-leading-none'>
            {t('price-from')}
          </div>
          <br />
          <div className='tw-Line1 tw-w-1 tw-self-stretch tw-origin-top-left tw-rotate-90 tw-border tw-border-zinc-500'></div>
          <div className='tw-099 tw-text-[#F16E00] dark:tw-text-zinc-100 tw-text-base tw-font-semibold tw-leading-tight'>
            {props.price}
          </div>
        </div>
      )}
    </div>
  );
}

function StoreItemFormOfSmall(props: {
  onClick?: MouseEventHandler<HTMLDivElement>;
  image: string;
  name: string;
  productType?: string;
  price?: string;
}) {
  const { t } = useTranslation('store');

  return (
    <div
      className={`tw-StoreCardsSmall tw-self-stretch tw-w-40 tw-justify-start tw-gap-4 tw-inline-flex tw-flex-col ${
        document.dir === 'rtl' ? 'tw-items-end' : 'tw-items-start'
      }`}
      onClick={props.onClick}
      role='button'
      tabIndex={0}
    >
      <img
        className='tw-BannerImage tw-w-40 tw-rounded-xl tw-border tw-border-zinc-700'
        src={props.image}
      />
      <div className='tw-Frame3 tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-inline-flex'>
        <div className='tw-PubgmSeasonPass tw-self-stretch  tw-text-black dark:tw-text-zinc-100 tw-text-base tw-font-bold tw-leading-snug'>
          {props.name}
        </div>
        {(props.price !== undefined || props.productType !== undefined) && (
          <div className='tw-Frame1 tw-justify-start tw-items-start tw-gap-2 tw-inline-flex tw-flex-col'>
            {props.productType !== undefined && (
              <div className='tw-TopUp tw-text-[#938F99] tw-text-sm tw-font-normal tw-leading-none'>
                {props.productType}
              </div>
            )}
            <div className='tw-Line1 tw-w-3 tw-self-stretch tw-origin-top-left tw-rotate-90 tw-border tw-border-zinc-500'></div>
            {props.price !== undefined && (
              <span className='tw-099 tw-text-base tw-font-medium tw-leading-tight'>
                <span className='tw-text-[#F16E00] tw-text-sm tw-font-normal tw-leading-none'>
                  {t('price-from')}
                </span>{' '}
                {props.price}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const StoreItem = (props: Props) => {
  useTranslation('store');

  switch (props.displayType) {
    case 'list':
      return (
        <StoreItemFormOfList
          key={props.name}
          onClick={props.onClick}
          image={props.image}
          name={props.name}
          productType={props.productType}
          price={props.displayPrice}
        ></StoreItemFormOfList>
      );
    case 'search':
      return (
        <StoreItemFormOfSearch
          key={props.name}
          onClick={props.onClick}
          image={props.image}
          name={props.name}
        ></StoreItemFormOfSearch>
      );
    case 'medium':
      return (
        <StoreItemFormOfMedium
          key={props.name}
          onClick={props.onClick}
          image={props.image}
          name={props.name}
          productType={props.productType}
          price={props.displayPrice}
        ></StoreItemFormOfMedium>
      );
    case 'stretch':
      return (
        <StoreItemFormOfStretch
          key={props.name}
          onClick={props.onClick}
          image={props.image}
          name={props.name}
          productType={props.productType}
          price={props.displayPrice}
        ></StoreItemFormOfStretch>
      );
    case 'dynamic':
      return (
        // just kind of return all three gated by sizes...
        <Fragment>
          <div className='md:tw-hidden'>
            <StoreItemFormOfList
              key={props.name}
              onClick={props.onClick}
              image={props.image}
              name={props.name}
              productType={props.productType}
              price={props.displayPrice}
            ></StoreItemFormOfList>
          </div>
          <div className='tw-hidden md:tw-block lg:tw-hidden'>
            <StoreItemFormOfSmall
              key={props.name}
              onClick={props.onClick}
              image={props.image}
              name={props.name}
              productType={props.productType}
              price={props.displayPrice}
            ></StoreItemFormOfSmall>
          </div>
          <div className='tw-hidden lg:tw-block'>
            <StoreItemFormOfMedium
              key={props.name}
              onClick={props.onClick}
              image={props.image}
              name={props.name}
              productType={props.productType}
              price={props.displayPrice}
            ></StoreItemFormOfMedium>
          </div>
        </Fragment>
      );
    default:
      return (
        <StoreItemFormOfSmall
          key={props.name}
          onClick={props.onClick}
          image={props.image}
          name={props.name}
          productType={props.productType}
          price={props.displayPrice}
        ></StoreItemFormOfSmall>
      );
  }
};

export default StoreItem;
