import { useGetMinimalUserQuery as useGeneratedUseGetMinimalUserQuery } from '../generated/graphql';
import { useAuthContext } from '../pages/Authentication/AuthContext';
import { handleSignOut } from '../App';
import {
  HomeErrorOptions,
  HomeErrorQueryParams,
} from '../config/Enums/QueryParams';
import Routes from '../config/Routes/Routes';
import { encodeQueryParams } from 'serialize-query-params';
import { objectToSearchString } from 'use-query-params';

function useGetMinimalUserQuery() {
  const [authContextState] = useAuthContext();

  return useGeneratedUseGetMinimalUserQuery({
    variables: {
      id: authContextState.userId!,
    },
    skip: !authContextState.userId,
    onError() {
      // The getUser call only errors when there is something really wrong,
      // like the service is down, or our query is constructed incorrectly.

      // It does not error if a bogus user id is provided, it just returns
      // null. So it's safe to assume that if there is an error, it's bad enough
      // that we should log the user out and refresh, and show the network error.

      // Also if the error is that the token is expired, our Apollo Client instance
      // will retry first with the refresh token before this error handler fires,
      // so if it can get a fresh token, this won't trigger.
      const encodedQuery = encodeQueryParams(HomeErrorQueryParams, {
        error: HomeErrorOptions.NETWORK_ERROR,
      });
      handleSignOut(`${Routes.home}?${objectToSearchString(encodedQuery)}`);
    },
  });
}

export default useGetMinimalUserQuery;
