import gdprBanner from '../../libraries/gdpr-banner/script';
import '../../libraries/gdpr-banner/style.css';

declare global {
  interface Window {
    gdprCookieNotice?: (options: Object) => void;
  }
}

const initCookieBanner = () => {
  gdprBanner();
  window.gdprCookieNotice?.({
    locale: 'en', //This is the default value
    timeout: 500, //Time until the cookie bar appears
    expiration: 30, //This is the default value, in days
    domain: import.meta.env.VITE_APP_COOKIE_DOMAIN, //If you run the same cookie notice on all subdomains, define the main domain starting with a .
    implicit: false, //Accept cookies on page scroll automatically
    statement: 'https://swarmio.media/cookie-policy', //Link to your cookie statement page
    performance: [], //Cookies in the performance category.
    analytics: [], //Cookies in the analytics category.
    marketing: [], //Cookies in the marketing category.
  });
};

export default initCookieBanner;
