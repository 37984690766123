import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from 'HiveClient/components/Icons/Icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Routes from 'src/config/Routes/Routes';
import styles from './StoreItem.module.scss';

const StoreItemDetailBalance = () => {
  const { t } = useTranslation('store');

  return (
    <div className={'d-flex flex-column align-items-center py-3'}>
      <FontAwesomeIcon
        icon={faCoins}
        size={'4x'}
        className={styles.insufficientCoinsIcon}
      />
      <p className={'mb-4 mt-3 fw-bold text-center'}>
        {t('modal.balance.message')}
      </p>
      <Link
        to={Routes.wallet}
        className={'btn btn-primary'}
        data-semantic={'wallet-balance-link'}
      >
        {t('modal.balance.button')}
      </Link>
    </div>
  );
};

export default StoreItemDetailBalance;
