import { HttpLink, split } from '@apollo/client';
import {
  CDN_SERVER_URL,
  NOTIFICATIONS_SERVICE_URL,
  SERVER_URL,
} from '../Endpoints/Endpoints';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from '../../utilities/Utilities';
import envVariables from '../EnvVariables/EnvVariables';

const PERSISTED_QUERIES_LOCALSTORAGE_KEY = 'persistedQueriesEnabled';

export const CONTEXT_OPTIONS = {
  notificationClient: 'notification',
};

/**
 * Sends the request to either the notification service or the gateway.
 *
 * Queries and mutations that should be directed to the notifications service
 * should have `context: { client: CONTEXT_OPTIONS.notificationClient }` passed
 * in as part of the options.
 */
const createHttpLink = () => {
  if (localStorage.getItem(PERSISTED_QUERIES_LOCALSTORAGE_KEY) === null) {
    localStorage.setItem(PERSISTED_QUERIES_LOCALSTORAGE_KEY, 'true');
  }

  if (localStorage.getItem(PERSISTED_QUERIES_LOCALSTORAGE_KEY) !== 'false') {
    return split(
      ({ getContext }) =>
        getContext().client === CONTEXT_OPTIONS.notificationClient,
      new HttpLink({
        uri: ({ operationName }) =>
          `${NOTIFICATIONS_SERVICE_URL}/graphql?${operationName}`,
      }),
      createPersistedQueryLink({
        useGETForHashedQueries: envVariables.cdnServerUrl ? true : false,
        sha256,
      }).concat(
        split(
          (operation) => {
            const context = operation.getContext();
            return (
              context.fetchOptions && context.fetchOptions.method === 'GET'
            );
          },
          new HttpLink({
            uri: ({ operationName }) =>
              `${CDN_SERVER_URL}/graphql?${operationName}`,
          }),
          new HttpLink({
            uri: ({ operationName }) => `${SERVER_URL}?${operationName}`,
          })
        )
      )
    );
  }

  return split(
    ({ getContext }) =>
      getContext().client === CONTEXT_OPTIONS.notificationClient,
    new HttpLink({
      uri: ({ operationName }) =>
        `${NOTIFICATIONS_SERVICE_URL}/graphql?${operationName}`,
    }),
    split(
      (operation) => {
        const context = operation.getContext();
        return context.fetchOptions && context.fetchOptions.method === 'GET';
      },
      new HttpLink({
        uri: ({ operationName }) =>
          `${CDN_SERVER_URL}/graphql?${operationName}`,
      }),
      new HttpLink({
        uri: ({ operationName }) => `${SERVER_URL}?${operationName}`,
      })
    )
  );
};

export default createHttpLink;
