import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'use-query-params';
import { SwarmioPayQueryParams } from '../config/Enums/QueryParams';
import { useToasts } from '../components/Toasts/ToastContext';
const useHandleSwarmioPayQueryParams = () => {
  const { t } = useTranslation();
  const { showSuccessNotification } = useToasts();
  const [urlQuery, setUrlQuery] = useQueryParams(SwarmioPayQueryParams);

  if (urlQuery.paymentId && urlQuery.orderId) {
    showSuccessNotification({
      heading: t('swarmio-pay.success-redirect.heading'),
      body: t('swarmio-pay.success-redirect.body'),
    });

    setUrlQuery({ paymentId: undefined, orderId: undefined }, 'replace');
  }
};

export default useHandleSwarmioPayQueryParams;
