import ClientBackgrounds from 'HiveClient/components/Backgrounds/Backgrounds';
import styles from './PageWrapper.module.scss';
import { useImage } from 'react-image';
import { DEFAULT_BACKGROUND } from 'src/config/Backgrounds/Backgrounds';

type Props = {
  children: JSX.Element | JSX.Element[];
  background?: string | null;
  className?: string;
  noBackgroundImage?: boolean;
  clearMenu?: boolean;
  contentClassName?: string;
};

/**
 * This component should wrap every page. It gives the page the opportunity to specify a background
 * image. This background image should be 1920 x 1080, and it has a max height of 1080, fading into
 * a gradient that is the same color as $body-bg.
 *
 * If the page isn't 1080px tall, then there is a gradient at the bottom of the PageWrapper which will
 * overlap the background image and fade it out into the footer.
 *
 * If noBackgroundImage is true, then the first component on the page will need to use the padding-top-menu-height
 * class in order to clear the menu. Gives components like FeaturedEvents the opportunity to provide its own
 * background image.
 */
const PageWrapper = (props: Props) => {
  const { src } = useImage({
    useSuspense: false,
    srcList: [
      `${props.background}`,
      DEFAULT_BACKGROUND,
      ClientBackgrounds.defaultBackground,
    ],
  });

  return (
    <section
      className={[
        'padding-top-menu-height',
        props.className,
        styles.container,
        props.clearMenu ? 'tw-pt-0' : '',
      ].join(' ')}
    >
      {!props.noBackgroundImage && (
        <div
          className={styles.background}
          style={{
            backgroundImage: `linear-gradient(to bottom, var(--body-bg-transparent) 70%, var(--body-bg) 100%), url(${src})`,
          }}
        ></div>
      )}
      <div
        className={[
          'tw-flex tw-flex-1 tw-z-10 tw-flex-col',
          props.contentClassName ?? '',
        ].join(' ')}
      >
        {props.children}
      </div>
      {!props.noBackgroundImage && <div className={styles.bottomGradient} />}
    </section>
  );
};

export default PageWrapper;
