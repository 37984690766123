import styles from '../Dashboard/Dashboard.module.scss';
import HorizontalNav from './HorizontalNav/HorizontalNav';
import UserNavigation from '../Dashboard/UserNavigation/UserNavigation';
import NavLogo from 'HiveClient/components/NavLogo/NavLogo';
import MobileNav from './MobileNav/MobileNav';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { useCallback, useEffect, useState } from 'react';
import VerticalNav from './VerticalNav/VerticalNav';

interface IProps {
  handleSignOut: () => void;
  preventNavigation: boolean;
}

const Nav = (props: IProps) => {
  const [atPageTop, setAtPageTop] = useState(window.pageYOffset < 30);

  const scrollHandler = useCallback(() => {
    setAtPageTop(window.pageYOffset < 30);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return GlobalClientConfig.nav.orientation === 'hidden' ? (
    <></>
  ) : (
    <div>
      <div
        className={[
          styles.horizontalNavWrapper,
          atPageTop ? styles.pageTop : '',
        ].join(' ')}
      >
        <HorizontalNav
          className={styles.mainNavigation}
          UserNavigation={
            <UserNavigation
              preventNavigation={props.preventNavigation}
              atPageTop={atPageTop}
              handleSignOut={props.handleSignOut}
            />
          }
          mainLogo={<NavLogo atPageTop={atPageTop} />}
          preventNavigation={props.preventNavigation}
        />
        <MobileNav
          preventNavigation={props.preventNavigation}
          handleSignOut={props.handleSignOut}
          logoAlt={GlobalClientConfig.clientName}
        />
      </div>
      {GlobalClientConfig.nav.orientation === 'vertical' && (
        <VerticalNav preventNavigation={props.preventNavigation} />
      )}
    </div>
  );
};

export default Nav;
