import {
  CatalogItemFragment,
  ItemStatus,
  ItemType,
  StoreCategoryFragment,
} from '../../generated/graphql';
import { StoreActions, ISelectItemPaymentOption } from './StoreActions';
import EnvVariables from '../../config/EnvVariables/EnvVariables';
import { StoreItemSortingOptions } from './StoreFilters/StoreItemSort';
import { useReducer } from 'react';
import { IField } from '../../clients/default/components/StoreItem/StoreItemRedemptionDetails';

export type StoreItemData = Omit<CatalogItemFragment, 'fields'> & {
  fields: IField[] | undefined;
};

export interface StoreState {
  availableCategories?: StoreCategoryFragment[];
  partnerId: string;
  filter: {
    itemStatus: ItemStatus;
    itemType: ItemType;
    itemName?: string;
  };
  sort?: StoreItemSortingOptions;
  pagination: {
    limit: number;
    offset: number;
  };
  selectedItem?: StoreItemData;
  selectedPaymentOption?: ISelectItemPaymentOption['payload'];
}

const PAGINATION_LIMIT = 12;

const initialStoreState: StoreState = {
  partnerId: EnvVariables.storePartnerId ?? '',
  filter: {
    itemStatus: ItemStatus.Published,
    itemType: ItemType.Virtual,
  },
  pagination: {
    limit: PAGINATION_LIMIT,
    offset: 0,
  },
  selectedItem: undefined,
};

const storeReducer = (state: StoreState, action: StoreActions): StoreState => {
  switch (action.type) {
    case 'FILTER_BY_ITEM_NAME':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: 0,
        },
        filter: {
          ...state.filter,
          itemName: action.payload.itemName ?? undefined,
        },
      };
    case 'SORT_STORE_ITEMS':
      return {
        ...state,
        sort: action.payload.sort,
      };
    case 'REDIRECT_TO_STORE_GROUP':
      const productGroupId = action.payload.selectedGroupId;
      if (action.payload.ignorePreviousPageFromBrowserHistory) {
        action.payload.history.replace(
          `/store/${action.payload.categoryId}/${productGroupId}`
        ); // use history.replace to navigate to the desired route, but removing the previous page (Category) from the browser history
      } else {
        action.payload.history.push(
          `/store/${action.payload.categoryId}/${productGroupId}`
        ); // use history.push to navigate to the desired route
      }

      return {
        ...state,
      };
    case 'SET_PAGINATION_OFFSET':
      return {
        ...state,
        pagination: {
          limit: state.pagination.limit,
          offset:
            action.payload.offset > 0
              ? Math.floor(action.payload.offset / state.pagination.limit)
              : action.payload.offset,
        },
      };
    case 'SELECT_ITEM_PAYMENT_OPTION':
      return {
        ...state,
        selectedPaymentOption: action.payload,
      };
    case 'UPDATE_STORE_CATEGORIES':
      return {
        ...state,
        availableCategories: action.payload.categories,
      };
    case 'UPDATE_STORE_SELECTED_ITEM':
      return {
        ...state,
        selectedItem: action.payload.item,
        selectedPaymentOption: undefined, // Make sure to clean selected Payment Option when item changes
      };
    default:
      throw new Error(`Store Reducer doesn't know how to handle this action`);
  }
};

export function useStoreReducer() {
  return useReducer(storeReducer, initialStoreState);
}
