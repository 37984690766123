import { Operation } from '@apollo/client';

export const AUTHORIZATION_HEADER = 'internal-authorization';

export const setAuthorizationHeaderWithAccessToken = (
  operation: Operation,
  accessToken?: string | null
) => {
  operation.setContext((context: Record<string, any>) => ({
    headers: {
      ...context.headers,
      // otherToken is passed in by the JoinEventModal. It replaces the user's accessToken
      // with the team's accessToken, as the user is operating as the team.
      [AUTHORIZATION_HEADER]: context.otherToken
        ? `Bearer ${context.otherToken}`
        : accessToken
        ? `Bearer ${accessToken}`
        : '',
    },
  }));
};
