import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Routes from '../../../../config/Routes/Routes';
import {
  faCog,
  faTags,
  faUser,
  faUsers,
  faWallet,
  faRetweet,
} from 'HiveClient/components/Icons/Icons';
import { useFeatureFlagsContext } from '../../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';

interface INavLink {
  url: string;
  title: string;
  icon: IconDefinition;
  semantic: string;
}

const useGetUserMenuLinks = () => {
  const { t } = useTranslation();

  const { checkFeatureFlags } = useFeatureFlagsContext();

  let navLinks: INavLink[] = [
    {
      url: Routes.myProfile,
      title: t('authenticated-nav.user-profile'),
      icon: faUser,
      semantic: 'profile',
    },
    {
      url: Routes.settings,
      title: t('authenticated-nav.settings'),
      icon: faCog,
      semantic: 'settings',
    },
  ];

  if (checkFeatureFlags(FeaturesEnum.Events)) {
    navLinks.push({
      url: Routes.myTeams,
      title: t('authenticated-nav.teams'),
      icon: faUsers,
      semantic: 'teams',
    });
  }

  if (checkFeatureFlags(FeaturesEnum.Wallet)) {
    navLinks.push({
      url: Routes.wallet,
      icon: faWallet,
      title: t('authenticated-nav.wallet'),
      semantic: 'wallet',
    });
  }

  if (checkFeatureFlags(FeaturesEnum.Subscriptions)) {
    navLinks.push({
      url: Routes.subscriptions,
      title: t('authenticated-nav.subscriptions'),
      icon: faRetweet,
      semantic: 'subscriptions',
    });
  }

  if (checkFeatureFlags(FeaturesEnum.Store)) {
    navLinks.push({
      icon: faTags,
      title: t('authenticated-nav.my-orders'),
      url: Routes.myOrders,
      semantic: 'orders',
    });
  }

  return navLinks;
};

export default useGetUserMenuLinks;
