import { GetMinimalUserQuery } from '../generated/graphql';
import { formatCash } from './Utilities';
import { DEFAULT_SWARMIO_CURRENCY_ID } from '../pages/Wallet/CurrencyId';

interface WalletUtilsInterface {
  getWalletBalance: (user: GetMinimalUserQuery['user']) => number;
  getWalletBalanceFormatted: (user: GetMinimalUserQuery['user']) => string;
}

export const getWalletBalance = (user: GetMinimalUserQuery['user']): number => {
  let balance: number = 0;
  if (user?.wallets) {
    const foundWallet = user.wallets.find(
      (wallet) => wallet.swarmioCurrency.id === DEFAULT_SWARMIO_CURRENCY_ID
    );

    if (foundWallet) {
      balance = foundWallet.balance;
    }
  }

  return balance;
};

export const getWalletBalanceFormatted = (
  user: GetMinimalUserQuery['user']
): string => formatCash(getWalletBalance(user));

const WalletUtils: WalletUtilsInterface = {
  getWalletBalance,
  getWalletBalanceFormatted,
};

export default WalletUtils;
