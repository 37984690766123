import styles from './GlobalGradient.module.scss';

/**
 * Makes this gradient available as fill: url(#global-gradient) to any element that wants it.
 * Only needs to be placed once on a page, all elements can reference it.
 */
const GlobalGradient = () => (
  <svg className={styles.svg}>
    <linearGradient id='global-gradient' x1='50%' y1='0%' x2='50%' y2='100%'>
      <stop offset='0%' className={styles.firstStop} />
      <stop offset='100%' className={styles.secondStop} />
    </linearGradient>
  </svg>
);

export default GlobalGradient;
