import envVariables from '../EnvVariables/EnvVariables';

export const BASE_URL: string = import.meta.env.VITE_APP_BASE_URL!;
export const SERVER_URL = `https://${BASE_URL}/graphql`;
export const CDN_SERVER_URL = envVariables.cdnServerUrl
  ? `https://${envVariables.cdnServerUrl}`
  : `https://${BASE_URL}`;
export const AUTHENTICATION_SERVICE_URL = `https://${BASE_URL}/api/users`;
export const GAMES_SERVICE_URL = `https://${BASE_URL}/api/games`;
export const SUBSCRIPTIONS_SERVICE_URL = `wss://${BASE_URL}/api/notifications/graphql-subscriptions`;
export const NOTIFICATIONS_SERVICE_URL = `https://${BASE_URL}/api/notifications`;
export const TRANSLATIONS_SERVICE_URL = `https://${BASE_URL}/api/internationalizations`;
export const PAYMENTS_URL: string = `https://${
  import.meta.env.VITE_APP_PAYMENTS_URL
}`;
export const BACKGROUNDS_URL: string = `https://${
  import.meta.env.VITE_APP_S3_BACKGROUND_IMAGES_BUCKET
}`;
