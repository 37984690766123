import { NavLink } from 'react-router-dom';
import Routes from '../../../../config/Routes/Routes';
import { faLanguage } from 'HiveClient/components/Icons/Icons';
import styles from '../UserNavigation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../../../LanguageMenu/LanguageContext';
import Button from '../../../Button/Button';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

interface Props {
  userDropdownToggleRef: RefObject<HTMLElement>;
  setShowUserDropdown: (show: boolean) => void;
  showUserDropdown: boolean;
}

const Unauthenticated = (props: Props) => {
  const { t } = useTranslation();
  const [languageContextState] = useLanguageContext();

  return (
    <div
      className={'d-flex w-100 justify-content-between'}
      data-cy={'unauthenticatedNav'}
    >
      <section className={'d-flex'}>
        <NavLink
          to={Routes.signIn}
          className={[
            styles.authLinks,
            'd-inline-block',
            'nav-link',
            'text-nowrap',
          ].join(' ')}
          data-semantic={'navbar-sign-in-link'}
        >
          {t('dashboard.nav-links.login')}
        </NavLink>
        <NavLink
          to={Routes.createAccount}
          className={[
            styles.authLinks,
            'd-inline-block',
            'nav-link',
            'text-nowrap',
          ].join(' ')}
          data-semantic={'navbar-create-account-link'}
        >
          {t('dashboard.nav-links.register')}
        </NavLink>
      </section>
      {languageContextState.hasMultipleLanguageSupport &&
        GlobalClientConfig.nav.orientation === 'horizontal' && (
          <Button
            semantic={'language-toggle'}
            variant={'hollow'}
            ref={props.userDropdownToggleRef}
            onClick={() => props.setShowUserDropdown(!props.showUserDropdown)}
            className={'d-flex align-items-center ms-2 p-0'}
          >
            <FontAwesomeIcon
              icon={faLanguage}
              className={styles.userMenuLanguage}
              size={'2x'}
            />
          </Button>
        )}
    </div>
  );
};

export default Unauthenticated;
