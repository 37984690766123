import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import envVariables from '../config/EnvVariables/EnvVariables';
import { useAuthContext } from '../pages/Authentication/AuthContext';

const useGTMDataLayer = () => {
  const [{ userId }] = useAuthContext();

  useEffect(() => {
    if (envVariables.gtmId && userId) {
      TagManager.dataLayer({ dataLayer: { userId: userId } });
    }
  }, [userId]);
};

export default useGTMDataLayer;
