import { detectSubjectType } from '@casl/ability';

const subjectTypeFromGraphql = (subject: any) => {
  if (subject?.__typename) {
    return subject.__typename;
  }

  return detectSubjectType(subject);
};

export default subjectTypeFromGraphql;
