/**
 * We need these to be available to i18next-parser.config.js which is run with node which
 * needs to require() this array. TypeScript still enforces this correctly when imported into
 * the GlobalClientConfig.
 */

const supportedLanguages = [
  {
    id: '1',
    code: 'en',
    backendCode: 'en_US',
    name: 'English',
  },
  {
    id: '2',
    code: 'ar-BH',
    backendCode: 'ar_BH',
    name: 'عربي',
  },
];

export default supportedLanguages;
