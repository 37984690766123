import { useApolloClient } from '@apollo/client';
import {
  MyInvitationsDocument,
  MyInvitationsQuery,
  MyInvitationsQueryVariables,
  useNewTeamInvitationSubscription,
} from '../generated/graphql';
import { ClientNotificationType } from '../components/Notifications/NotificationTypes';
import { useAuthContext } from '../pages/Authentication/AuthContext';

const useSubscribeToNewTeamInvitations = () => {
  const client = useApolloClient();
  const [{ userId }] = useAuthContext();

  return useNewTeamInvitationSubscription({
    variables: {
      notificationType: ClientNotificationType.NewTeamInvitation,
      targetId: userId ?? '',
    },
    skip: !userId,
    onSubscriptionData() {
      client.query<MyInvitationsQuery, MyInvitationsQueryVariables>({
        query: MyInvitationsDocument,
        fetchPolicy: 'network-only',
        variables: {
          id: userId!,
        },
      });
    },
  });
};

export default useSubscribeToNewTeamInvitations;
