import { useEffect } from 'react';
import {
  GetMinimalUserDocument,
  GetMinimalUserQuery,
  GetMinimalUserQueryVariables,
  useCreateWalletsMutation,
} from '../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { getFromlocalStorage } from '../utilities/Utilities';
import { TokenResponse } from '../pages/Authentication/AuthenticationService';
import { StorageKey } from '../config/Enums/App';
import {
  DEFAULT_SWARMIO_CURRENCY_ID,
  SHINY_SWARMIO_CURRENCY_ID,
} from 'src/pages/Wallet/CurrencyId';

const useCreateWalletIfNone = () => {
  const client = useApolloClient();
  const token = getFromlocalStorage<TokenResponse>(StorageKey.AuthToken);
  const accountId = token?.accountId;

  const [createWallets] = useCreateWalletsMutation({
    onError: (err) => {
      console.log('err: ', err);
    },
    onCompleted: (res) => {
      console.log('Res: ', res);
    },
  });

  useEffect(() => {
    if (accountId) {
      client
        .watchQuery<GetMinimalUserQuery, GetMinimalUserQueryVariables>({
          query: GetMinimalUserDocument,
          variables: { id: accountId },
        })
        .subscribe((response) => {
          if (response.data.user?.wallets.length === 0) {
            createWallets({
              variables: {
                ownerId: accountId,
                swarmioCurrencyIds: [
                  DEFAULT_SWARMIO_CURRENCY_ID,
                  SHINY_SWARMIO_CURRENCY_ID,
                ],
              },
              refetchQueries: [
                {
                  query: GetMinimalUserDocument,
                  variables: { id: accountId },
                },
              ],
            });
          }
        });
    }
  }, [accountId, client, createWallets]);
};

export default useCreateWalletIfNone;
