import styles from '../Dashboard/UserNavigation/UserNavigation.module.scss';
import { Fragment } from 'react';
import Button from '../Button/Button';
import { useLanguageContext } from './LanguageContext';
import LanguageService from '../../services/LanguageService';

const LanguageMenu = () => {
  const [languageContextState] = useLanguageContext();

  return (
    <Fragment>
      {languageContextState.supportedLanguages.map((language) => (
        <Button
          semantic={'language-menu-choice'}
          key={language.code}
          onClick={() => {
            LanguageService.userInitiatedLanguageSwitch(language.code);
          }}
          variant={'dark'}
          className={[
            styles.dropdownItem,
            'py-2 px-2 mx-lg-2 mx-md-2 mt-2 w-100',
            languageContextState.initialLanguageSelection === language.code
              ? 'active'
              : '',
          ].join(' ')}
        >
          <span>{language.name}</span>
        </Button>
      ))}
    </Fragment>
  );
};

export default LanguageMenu;
