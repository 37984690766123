import {
  AllEventsEventFragment,
  CompetitionType,
  EventCategory,
  EventElementState,
  EventFragment,
  GameAccountOnGroupMemberForEventJoinFragment,
  MatchUpFragment,
  MatchUpPhaseFragment,
  ResultFragment,
  RoundParticipantReferenceFragment,
  TagFragment,
} from '../../generated/graphql';
import { generatePath } from 'react-router-dom';

import { getI18n } from 'react-i18next';
import { Roles } from '../../components/RoleSelect/RoleSelect';
import { DateTime } from 'luxon';
import { isTypename } from '../../utilities/TSUtilities';
import { formatNumberDoubleDigits } from '../../utilities/Utilities';
import { encodeQueryParams } from 'serialize-query-params';
import {
  EventQueryParams,
  EventsQueryParams,
} from 'src/config/Enums/QueryParams';
import Routes from 'src/config/Routes/Routes';
import { objectToSearchString, SetQuery } from 'use-query-params';
import LanguageService from 'src/services/LanguageService';

interface TeamWithRoles {
  roles: { roleId: string }[];
}

export function getJoinableTeams<T extends TeamWithRoles>(teams: T[]): T[] {
  return teams.filter((team) =>
    team.roles.some((role) =>
      [Roles.OWNER, Roles.ADMIN].includes(role.roleId as Roles)
    )
  );
}

export const getEventEntryRulesString = (
  event: EventFragment | AllEventsEventFragment
) => {
  const i18n = getI18n();
  let format = '--';

  if (
    event.eventLimitations?.eventEntryRules?.__typename !==
    'TeamEventEntryRules'
  ) {
    return format;
  }

  const min = event.eventLimitations.eventEntryRules.minimumTeamMembers;
  const max = event.eventLimitations.eventEntryRules.maximumTeamMembers;

  if (min && max) {
    if (min === max) {
      format = i18n.t('team-event-entry-rules.same-min-max', { teamSize: min });
    } else {
      format = i18n.t('team-event-entry-rules.different-min-max', { min, max });
    }
  } else if (min) {
    format = i18n.t('team-event-entry-rules.min-only', { min });
  } else if (max) {
    format = i18n.t('team-event-entry-rules.max-only', {
      max,
    });
  }

  return format;
};

/**
 * Checks if an event has a full participant list.
 * TODO: Allow this to check AllEventsEventFragment
 */
export function eventIsFull(event: {
  __typename?: 'Event';
  eventLimitations: { maximumParticipants?: number | null };
  participantCount: number;
}) {
  return (
    event.eventLimitations.maximumParticipants !== undefined &&
    event.participantCount === event.eventLimitations.maximumParticipants
  );
}

interface IParticipantWithTeamMemberIDs {
  __typename: 'TeamParticipant';
  teamMembers: { person: { id: string } | null }[];
  id: string;
}
/**
 * Checks if given accountId is a participant in an Event.
 */
export function accountIsParticipant<T extends IParticipantWithTeamMemberIDs[]>(
  participants: T,
  accountId: string
): boolean {
  return Boolean(getTeamParticipantOfUser(participants, accountId));
}

/**
 * Returns the Participant that the give user's accountId is a member of, if
 * they are the member of a team that has joined an event.
 *
 * Otherwise returns null.
 */
export function getTeamParticipantOfUser<
  T extends IParticipantWithTeamMemberIDs[]
>(participants: T, accountId: string): T[number] | null {
  return (
    participants.find((participant) =>
      participant.teamMembers.find(
        (teamMember) => teamMember.person?.id === accountId
      )
    ) ?? null
  );
}

export function eventHasStarted(event: {
  __typename?: 'Event';
  eventTiming: { startTime: string };
}) {
  const startTime = DateTime.fromISO(event.eventTiming.startTime, {
    zone: 'utc',
  }).toLocal();

  return DateTime.local() > startTime;
}

export function eventInProgress(eventState: EventElementState) {
  return [
    EventElementState.PendingStart,
    EventElementState.InProgress,
    EventElementState.PendingFinalResults,
  ].includes(eventState);
}

export function eventHasEnded(eventState: EventElementState) {
  return [
    EventElementState.Archived,
    EventElementState.Cancelled,
    EventElementState.Complete,
    EventElementState.CompleteAndPaid,
  ].includes(eventState);
}

export function matchHasStarted(match: {
  __typename?: 'PlayableMatchUpMatch' | 'PlayableTournamentMatch';
  startTime?: string | null;
}) {
  const startTime = match.startTime
    ? DateTime.fromISO(match.startTime, { zone: 'utc' }).toLocal()
    : match.startTime;

  return startTime ? DateTime.local() > startTime : false;
}

export function matchHasResults(match: {
  __typename?: 'PlayableMatchUpMatch' | 'PlayableTournamentMatch';
  rounds?: { participants: RoundParticipantReferenceFragment[] }[];
}) {
  let matchHasResults = false;

  if (
    match.rounds?.length &&
    isTypename(
      'TeamParticipant',
      match.rounds[0].participants[0].participant
    ) &&
    match.rounds[0].participants[0].participant.result?.placement !==
      undefined &&
    match.rounds[0].participants[0].participant.result.placement !== null
  ) {
    matchHasResults = true;
  }

  return matchHasResults;
}

export function roundHasResults(round: {
  __typename?: 'TournamentRound' | 'MatchUpRound';
  participants?: RoundParticipantReferenceFragment[];
}) {
  let roundHasResults = false;

  if (
    round.participants?.length &&
    isTypename('TeamParticipant', round.participants[0].participant) &&
    round.participants[0].participant.result?.placement !== undefined &&
    round.participants[0].participant.result.placement !== null
  ) {
    roundHasResults = true;
  }

  return roundHasResults;
}

/**
 * Filters the participants on an array of ParticipantReference and returns an array
 * of the TeamParticipant objects.
 *
 * This lets us (hopefully) pass anything that extends the ParticipantReference class, and get back
 * a narrowed array of T's participants of type TeamParticipant. Very useful when
 * we only care about TeamParticipants but don't want to type guard every single use.
 */
export function getTeamParticipantsFromParticipantReference<
  T extends {
    participant: { __typename: string };
  }
>(participants: Array<T>) {
  return participants
    .map((participant) => participant.participant)
    .filter(
      (
        participant
      ): participant is T['participant'] & {
        __typename: 'TeamParticipant';
      } => isTypename('TeamParticipant', participant)
    );
}

/**
 * Filters the participants on an array of ParticipantReference and returns an array
 * of the TeamParticipant/IndividualParticipant objects.
 */
export function getIndividualParticipantsFromParticipantReference<
  T extends {
    participant: { __typename: string };
  }
>(participants: Array<T>) {
  return participants
    .map((participant) => participant.participant)
    .filter(
      (
        participant
      ): participant is T['participant'] & {
        __typename:
          | 'ReadyIndividualParticipant'
          | 'UnreadyIndividualParticipant';
      } =>
        isTypename('ReadyIndividualParticipant', participant) ||
        isTypename('UnreadyIndividualParticipant', participant)
    );
}

interface ParticipantWithPlacement {
  __typename:
    | 'TeamParticipant'
    | 'ReadyIndividualParticipant'
    | 'UnreadyIndividualParticipant';
  result?: ResultFragment | null;
}
/**
 * Assumes result.placement will never be 0.
 */
export const sortParticipantsByPlacement = (
  participantA: ParticipantWithPlacement,
  participantB: ParticipantWithPlacement
) => {
  // If neither participant has a result, or their placement is the same:
  if (participantA.result?.placement === participantB.result?.placement) {
    return 0;
  }

  // sort nulls to the bottom
  if (!participantA.result?.placement) {
    return 1;
  }

  if (!participantB.result?.placement) {
    return -1;
  }

  // sort lowest to highest
  return participantA.result.placement - participantB.result.placement;
};

// Code below is to trigger actions and show specific tabs in the challenge & tournament details pages
export enum EventDetailsURLActions {
  dashboard = 'dashboard',
  overview = 'overview',
  rules = 'rules',
  prizes = 'prizes',
  participants = 'participants',
  streams = 'streams',
  schedule = 'schedule', // challenge specific
  brackets = 'brackets', // tournament specific
  stages = 'stages', // Battle Royale specific
  match = 'match',
  confirm = 'confirm',
  imageTab0 = 'imageTab0', // a bit gross, but easier than trying to rework the whole url actions code
  imageTab1 = 'imageTab1', // admin limits image tabs to 10, if that gets increased it'll have to be reflected here
  imageTab2 = 'imageTab2',
  imageTab3 = 'imageTab3',
  imageTab4 = 'imageTab4',
  imageTab5 = 'imageTab5',
  imageTab6 = 'imageTab6',
  imageTab7 = 'imageTab7',
  imageTab8 = 'imageTab8',
  imageTab9 = 'imageTab9',
}
export const findUserCompetitionInfo = (
  event: EventFragment,
  userId: string
) => {
  for (const stage of event.eventStages) {
    for (const competition of stage.competitions) {
      const matchingParticipant = competition.participants.find(
        ({ participant }) => {
          if (isTypename('TeamParticipant', participant)) {
            return participant.teamMembers.some(
              (teamMember) => teamMember.accountId === userId
            );
          }
          return participant.accountId === userId;
        }
      );
      if (
        matchingParticipant &&
        competition.competitionState === EventElementState.InProgress
      ) {
        return {
          competitionId: competition.id,
          stageId: stage.stageNumber,
        };
      }
    }
  }
  return null;
};

export const getMultiStageEventDetailsURLActions = (event: EventFragment) =>
  event.eventStages
    .flatMap((eventStage) =>
      eventStage.competitions.map((comp) => ({
        stageNumber: eventStage.stageNumber,
        ...comp,
      }))
    )
    .map((competitionWithStageNumber) => {
      switch (competitionWithStageNumber.competitionType) {
        case CompetitionType.Tournament:
          return `stage-${competitionWithStageNumber.stageNumber}-${EventDetailsURLActions.brackets}`;
        case CompetitionType.MatchUp:
          if (
            event.participantCount &&
            isTypename('MatchUp', competitionWithStageNumber)
          ) {
            return `stage-${competitionWithStageNumber.stageNumber}-${EventDetailsURLActions.schedule}`;
          }

          if (
            isTypename('MatchUp', competitionWithStageNumber) &&
            competitionWithStageNumber.matchUpFormatSpecificDetails &&
            isTypename(
              'BattleRoyaleSpecificDetails',
              competitionWithStageNumber.matchUpFormatSpecificDetails
            )
          ) {
            return `stage-${competitionWithStageNumber.stageNumber}-${EventDetailsURLActions.stages}`;
          }
          break;
      }

      return null;
    });

export const generateEventURL = (
  id: string,
  action?: EventDetailsURLActions
) => {
  const eventURL = generatePath(Routes.eventOverview, {
    id: id,
  });

  if (action) {
    const encodedQuery = encodeQueryParams(EventQueryParams, { show: action });

    return `${eventURL}/?${objectToSearchString(encodedQuery)}`;
  } else {
    return eventURL;
  }
};

export const generateEventsURL = (category: EventCategory) => {
  const encodedQuery = encodeQueryParams(EventsQueryParams, {
    category: category,
  });

  return `${Routes.events}/?${objectToSearchString(encodedQuery)}`;
};

export const actionIsTab = (
  action: EventDetailsURLActions,
  tabEventKeys: string[]
) => tabEventKeys.some((tab) => tab === action);

/**
 * Checks that the requested activeTab is one of the tabs that we're rendering.
 * If it is, simply return the activeTab passed in. Otherwise return the defaultTab.
 */
export const getTabToSetActive = (
  activeTab: EventDetailsURLActions,
  defaultTab: EventDetailsURLActions,
  tabEventKeys: string[]
) => {
  if (actionIsTab(activeTab, tabEventKeys)) {
    return activeTab;
  } else {
    return defaultTab;
  }
};

export const updateActiveTab = (
  activeTab: string,
  defaultTab: EventDetailsURLActions,
  setQueryParams: SetQuery<typeof EventQueryParams>
) => {
  const newActiveTab =
    activeTab in EventDetailsURLActions ? activeTab : defaultTab;

  setQueryParams({ show: newActiveTab, match: undefined });
};

// advanceFraction must be a value between 0 and 1
export const determineExpectedNumberOfPhasesForBattleRoyale = (
  numberOfParticipants: number,
  details: { maxRoundParticipants: number; advanceFraction: number }
) => {
  const maxRoundParticipants = details.maxRoundParticipants;
  const advanceFraction = details.advanceFraction;

  function recursivelyFindExpectedNumberOfPhases(
    currentPhaseSize: number,
    phasesSoFar: number
  ): number {
    if (currentPhaseSize >= numberOfParticipants) {
      return phasesSoFar;
    } else {
      const newPhasesSoFar = phasesSoFar + 1;
      const newCurrentPhaseSize = Math.ceil(currentPhaseSize / advanceFraction);

      return recursivelyFindExpectedNumberOfPhases(
        newCurrentPhaseSize,
        newPhasesSoFar
      );
    }
  }

  return recursivelyFindExpectedNumberOfPhases(maxRoundParticipants, 1);
};

export const getBattleRoyaleEventStages = (
  phases: MatchUpPhaseFragment[],
  participantCount: number,
  details: {
    maxRoundParticipants: number;
    advanceFraction: number;
  },
  localizations: {
    stageNamePrefix: string;
    finalsName: string;
    semifinals: string;
  }
) => {
  const copyOfPhases = [...phases];
  const expectedNumberOfPhases = determineExpectedNumberOfPhasesForBattleRoyale(
    participantCount,
    details
  );
  let stages: { name: string; phaseNumber: number; isComplete: boolean }[] = [];

  // fill phases that have yet to be generated so we can show stages in the UI
  if (expectedNumberOfPhases > copyOfPhases.length) {
    for (let i = copyOfPhases.length; i < expectedNumberOfPhases; i++) {
      copyOfPhases.push({
        id: 'fillerId',
        phaseNumber: i + 1,
        isComplete: false,
        matches: [],
        __typename: 'MatchUpPhase',
      });
    }
  }

  stages = copyOfPhases.map((phase) => ({
    name: `${localizations.stageNamePrefix} ${formatNumberDoubleDigits(
      phase.phaseNumber
    )}`,
    phaseNumber: phase.phaseNumber,
    isComplete: phase.isComplete,
  }));

  // the last stage regardless if there is only one will be called the "finals"
  if (stages.length > 0) {
    stages[stages.length - 1] = {
      ...stages[stages.length - 1],
      name: localizations.finalsName,
    };
  }

  // if a second last stage exists whether or not there are only two it will be called the "semifinals"
  if (stages.length > 2) {
    stages[stages.length - 2] = {
      ...stages[stages.length - 2],
      name: localizations.semifinals,
    };
  }

  return stages;
};

const searchMatchUpForMatchID = (
  matchId: string,
  competitionId: string,
  competition: MatchUpFragment
): string | null => {
  const phases = competition.phases;

  for (let phaseIdx = 0; phaseIdx < phases.length; phaseIdx++) {
    const matches = phases[phaseIdx].matches;
    for (let matchIdx = 0; matchIdx < matches.length; matchIdx++) {
      const match = matches[matchIdx];
      if (match.id === matchId) {
        return competitionId;
      }
    }
  }

  return null;
};

export const findCompetitionIdMatchBelongsToInMatchUp = (
  event: EventFragment,
  matchId: string
): string | null => {
  let competitionId = null;
  const stages = event.eventStages;

  for (let stageIdx = 0; stageIdx < stages.length; stageIdx++) {
    const competitions = stages[stageIdx].competitions;
    for (
      let competitionIdx = 0;
      competitionIdx < competitions.length;
      competitionIdx++
    ) {
      const competition = competitions[competitionIdx];
      if (isTypename('MatchUp', competition)) {
        competitionId = searchMatchUpForMatchID(
          matchId,
          competition.id,
          competition
        );

        return competitionId;
      }
    }
  }

  return competitionId;
};

export const verifyAccountHasEventGameAndAccount = (
  gameAccounts: GameAccountOnGroupMemberForEventJoinFragment[],
  gameAccountTypeIds: string[],
  gameId: string
) => {
  const gameAccount = gameAccounts.find((gameAccount) =>
    gameAccountTypeIds.includes(gameAccount.gameAccountTypeId)
  );

  const game = gameAccounts.find((gameAccount) =>
    gameAccount.gameIds.includes(gameId)
  );

  return Boolean(gameAccount && game);
};

export const getSeriesTag = (tags: TagFragment[]) =>
  tags.filter((tag) => tag.name === 'series')[0]?.localizations[0].value;

export enum EventAsset {
  teaser = 'teaserImg',
  banner = 'bannerImg',
  listBanner = 'listBannerImg',
  mobileBanner = 'mobileBannerImg',
  hero = 'heroImg',
  description = 'descriptionImg',
  featuredTeaser = 'featuredTeaserImg',
  featuredBanner = 'featuredBannerImg',
  mobileFeaturedBanner = 'mobileFeaturedBannerImg',
}

const getTagForDesiredEventAsset = (
  tags: TagFragment[],
  orderOfDesiredAssets: EventAsset[]
) => {
  const filteredTags = tags
    .filter(
      (tag) => orderOfDesiredAssets.indexOf(tag.name as EventAsset) !== -1
    )
    .sort(
      (tagA, tagB) =>
        orderOfDesiredAssets.indexOf(tagA.name as EventAsset) -
        orderOfDesiredAssets.indexOf(tagB.name as EventAsset)
    );

  return filteredTags.length > 0 ? filteredTags[0] : undefined;
};

export const getAssetWithFallbacks = (
  tags: TagFragment[],
  asset: EventAsset
) => {
  let foundTag: undefined | TagFragment = undefined;

  switch (asset) {
    case EventAsset.listBanner:
      foundTag = getTagForDesiredEventAsset(tags, [
        EventAsset.listBanner,
        EventAsset.banner,
      ]);

      break;
    case EventAsset.mobileBanner:
      foundTag = getTagForDesiredEventAsset(tags, [
        EventAsset.mobileBanner,
        EventAsset.banner,
      ]);

      break;
    case EventAsset.featuredTeaser:
      foundTag = getTagForDesiredEventAsset(tags, [
        EventAsset.featuredTeaser,
        EventAsset.teaser,
      ]);

      break;
    case EventAsset.featuredBanner:
      foundTag = getTagForDesiredEventAsset(tags, [
        EventAsset.featuredBanner,
        EventAsset.banner,
      ]);

      break;
    case EventAsset.mobileFeaturedBanner:
      foundTag = getTagForDesiredEventAsset(tags, [
        EventAsset.mobileFeaturedBanner,
        EventAsset.featuredBanner,
        EventAsset.banner,
      ]);

      break;
    default:
      foundTag = tags.find((tag) => tag.name === asset);
      break;
  }

  return foundTag
    ? LanguageService.getLocaleObject(foundTag.localizations) || undefined
    : undefined;
};

export const getImageTabs = (tags: TagFragment[]) => {
  const imageTabs: { number: number; name: string; image: string }[] = [];

  const tagCount = tags.length || 0;

  let index = 0;
  for (index = 0; index <= tagCount; index++) {
    const tab = `tab${index}`;
    const tabNameTag = tags.find((tag: any) => tag.name === `${tab}name`);
    const tabImageTag = tags.find((tag: any) => tag.name === `${tab}image`);

    if (tabNameTag && tabImageTag) {
      const tabName = LanguageService.getLocaleObject(tabNameTag.localizations);
      const tabImage = LanguageService.getLocaleObject(
        tabImageTag.localizations
      );

      if (tabName && tabImage) {
        imageTabs.push({
          number: index,
          name: tabName,
          image: tabImage,
        });
      }
    }
  }

  return imageTabs;
};

export const getInProgressCompetition = (event: EventFragment) =>
  event.eventStages
    .flatMap((eventStage) => eventStage.competitions)
    .find(
      (competition) =>
        competition.competitionState === EventElementState.InProgress
    );

export const getInProgressOrNextCompetition = (event: EventFragment) => {
  const eventStages = event.eventStages.flatMap(
    (eventStage) => eventStage.competitions
  );
  const inProgressCompetition = eventStages.find(
    (competition) =>
      competition.competitionState === EventElementState.InProgress
  );

  if (inProgressCompetition) return inProgressCompetition;

  return eventStages.find(
    (competition) => competition.competitionState === EventElementState.Created
  );
};
