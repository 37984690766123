import { faImages } from '@fortawesome/pro-regular-svg-icons';
import { AvatarSizes, convertREMToPixelValue, getSize } from './Avatar';
import styles from './ProfileAvatar.module.scss';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import FallbackIcon from './FallbackIcon/FallbackIcon';

export type Props = {
  photo?: string | null;
  altText?: string;
  size?: AvatarSizes;
  className?: string;
  fallbackIcon?: IconDefinition;
  borderClassName?: string;
};

const roundToNearestEven = (num: number) => 2 * Math.round(num / 2);

const ProfileAvatar = ({
  photo,
  altText = 'profile avatar',
  size = AvatarSizes.lg,
  className,
  fallbackIcon,
  borderClassName,
}: Props) => {
  const calculatedSize = convertREMToPixelValue(getSize(size));
  // Makes the border width a ratio of the size of the ProfileAvatar,
  // so really tiny avatars don't have a huge border. Min border size of 4px
  // regardless of size, as we need to divide it by 2 and don't want subpixel fractions.
  const borderWidth =
    roundToNearestEven(calculatedSize * 0.04) < 4
      ? 4
      : roundToNearestEven(calculatedSize * 0.04);

  return (
    <div
      style={{
        width: calculatedSize,
        height: calculatedSize,
      }}
      className={[className, styles.container].join(' ')}
    >
      {photo ? (
        <img
          className={[styles.image, 'rounded-circle'].join(' ')}
          src={photo}
          alt={altText}
          style={{
            width: `calc(100% - ${borderWidth}px)`,
            height: `calc(100% - ${borderWidth}px)`,
          }}
          data-cy={'profileAvatar'}
        />
      ) : (
        <FallbackIcon
          size={getSize(size) * 0.3}
          icon={fallbackIcon ?? faImages}
          className={styles.avatarIcon}
        />
      )}
      <svg
        className={[styles.borderCircle, borderClassName].join(' ')}
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'
        style={{
          width: calculatedSize,
          height: calculatedSize,
        }}
      >
        <circle cx='50' cy='50' r='50' />
      </svg>
      <svg
        className={styles.innerCircle}
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'
        style={{
          width: `calc(100% - ${borderWidth}px)`,
          height: `calc(100% - ${borderWidth}px)`,
          top: `calc(${borderWidth}px / 2)`,
          left: `calc(${borderWidth}px / 2)`,
        }}
      >
        <circle cx='50' cy='50' r='50' />
      </svg>
    </div>
  );
};

export default ProfileAvatar;
