import { useState, useEffect } from 'react';

type ScreenSize = number | 'mobile' | 'tablet' | 'laptop' | 'large-desktop';

/**
 * Provides a hook, useScreenSizeCheck, that monitors the window size against predefined size thresholds.
 * Dynamically updates the boolean value based on window resizing.
 * @param size - Size criteria to check against the window width: a number or strings ('mobile', 'tablet', 'small-laptop').
 * @returns A boolean value indicating whether the current window size meets the specified criteria.
 */
export const useScreenSizeCheck = (size: ScreenSize): boolean => {
  const [isSizeMatch, setIsSizeMatch] = useState(false);

  useEffect(() => {
    const sizeMap = {
      mobile: 480,
      tablet: 768,
      laptop: 992,
      'large-desktop': 1200,
    };

    const handleResize = () => {
      if (typeof size === 'number') {
        setIsSizeMatch(window.innerWidth < size);
      } else if (size in sizeMap) {
        setIsSizeMatch(window.innerWidth < sizeMap[size]);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size]);

  return isSizeMatch;
};
