import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { MsisdnFragment } from '../generated/graphql';
import { isTypename } from '../utilities/TSUtilities';
import { MsisdnRequiredness } from '../config/Enums/MsisdnRequiredness';

interface IMsisdnService {
  isRequired: () => boolean;
  isNonVerified: () => boolean;
  isOptional: () => boolean;
  isOptionalOrRequired: () => boolean;
  isAbsent: () => boolean;
  shouldRedirectToMsisdnForm: (
    msisdn: string | null,
    msisdnVerified: boolean
  ) => boolean;
  getMsisdnValue: (msisdn: MsisdnFragment | null) => string | null;
  mustRedirectToMsisdnForm: (msisdnVerified: boolean) => boolean;
  shouldRedirectToVerifySMS: (
    msisdn: string | null,
    msisdnVerified: boolean
  ) => boolean;
  mustRedirectToVerifySMS: (
    msisdn: string | null,
    msisdnVerified: boolean
  ) => boolean;
}

export const isRequired = () =>
  GlobalClientConfig.msisdn.requiredness === MsisdnRequiredness.REQUIRED;

export const isNonVerified = () =>
  GlobalClientConfig.msisdn.requiredness === MsisdnRequiredness.NON_VERIFIED;

export const isOptional = () =>
  GlobalClientConfig.msisdn.requiredness === MsisdnRequiredness.OPTIONAL;

export const isAbsent = () =>
  GlobalClientConfig.msisdn.requiredness === MsisdnRequiredness.ABSENT;

export const isOptionalOrRequired = () => isRequired() || isOptional();

export const getMsisdnValue = (msisdn: MsisdnFragment | null) =>
  msisdn && isTypename('StringAttribute', msisdn) ? msisdn.value : null;

export const shouldRedirectToMsisdnForm = (
  msisdn: string | null,
  msisdnVerified: boolean
) => {
  const mustSetMsisdn = MsisdnService.mustRedirectToMsisdnForm(msisdnVerified);

  const shouldSetMsisdn = MsisdnService.isOptionalOrRequired() && !msisdn;

  const redirectToMsisdnPage = mustSetMsisdn || shouldSetMsisdn;

  return redirectToMsisdnPage;
};

export const mustRedirectToMsisdnForm = (msisdnVerified: boolean) => {
  const mustSetMsisdn = MsisdnService.isRequired() && !msisdnVerified;

  return mustSetMsisdn;
};

export const shouldRedirectToVerifySMS = (
  msisdn: string | null,
  msisdnVerified: boolean
) => {
  const mustRedirectToVerifySMS = MsisdnService.mustRedirectToVerifySMS(
    msisdn,
    msisdnVerified
  );

  const shouldRedirectToVerifySMS = Boolean(
    MsisdnService.isOptionalOrRequired() && msisdn && !msisdnVerified
  );

  const redirectToVerifySMSPage =
    mustRedirectToVerifySMS || shouldRedirectToVerifySMS;

  return redirectToVerifySMSPage;
};

export const mustRedirectToVerifySMS = (
  msisdn: string | null,
  msisdnVerified: boolean
) => {
  const mustRedirectToVerifySMS = Boolean(
    msisdn && MsisdnService.isRequired() && !msisdnVerified
  );

  return mustRedirectToVerifySMS;
};

const MsisdnService: IMsisdnService = {
  isRequired,
  isOptional,
  isAbsent,
  isNonVerified,
  isOptionalOrRequired,
  shouldRedirectToMsisdnForm,
  getMsisdnValue,
  mustRedirectToMsisdnForm,
  shouldRedirectToVerifySMS,
  mustRedirectToVerifySMS,
};

export default MsisdnService;
