import { createContext, useContext, useState } from 'react';

export interface NotificationsContextInterface {
  numberOfNotifications: number;
  setNumberOfNotifications: (numberOfNotifications: number) => void;
}

const NotificationsContext = createContext<
  NotificationsContextInterface | undefined
>(undefined);

export const NotificationsContextProvider = ({ children }: any) => {
  const [numberOfNotifications, setNumberOfNotifications] = useState<number>(0);

  return (
    <NotificationsContext.Provider
      value={{ numberOfNotifications, setNumberOfNotifications }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotificationsContext must be used within a <NotificationsContextProvider />'
    );
  }

  return context;
};
