const supportedLanguages = [
  {
    id: '1',
    code: 'en',
    backendCode: 'en_US',
    name: 'English',
  },
  {
    id: '2',
    code: 'ar-AE',
    backendCode: 'ar_AE',
    name: 'عربي',
  },
];

export default supportedLanguages;
