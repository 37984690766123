import { InMemoryCacheConfig } from '@apollo/client/cache';
import introspectionQueryResultData from '../../generated/fragmentTypes';
import { StrictTypedTypePolicies } from '../../generated/graphql';

/**
 * __typenames of entities we don't want to cache.
 *
 * This is useful for deeply nested entities where their uniqueness is
 * determined by their parent entity's ID, not their own ID / __typename combo.
 */
const disallowedTypeNames = [
  /*
   Event Participants are deeply nested under Rounds, and the results
   object on a participant is unique to the round for that participant.
   Because participant has the same ID for every round they're in,
   their result object was being overwritten by the last returned round they were in.
   */
  'TeamParticipant',
  'IndividualParticipant',
];

const disallowedTypePolicies = disallowedTypeNames.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: {
      keyFields: false,
    },
  }),
  {}
);

const typePolicies: StrictTypedTypePolicies = {
  ...disallowedTypePolicies,
};

const cacheConfig: InMemoryCacheConfig = {
  possibleTypes: introspectionQueryResultData.possibleTypes,
  typePolicies,
};

export default cacheConfig;
