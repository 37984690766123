import { ApolloClient } from '@apollo/client/core';
import {
  FeatureFlagFragment,
  FeatureFlagState,
  GetApplicationConfigByNameDocument,
  GetApplicationConfigByNameQuery,
} from '../../generated/graphql';
import { FeatureFlagsConfig } from '../../config/FeatureFlags/FeatureFlagsContext';
import FeatureFlags from 'HiveClient/config/FeatureFlags/FeatureFlags';
import { FeaturesEnum } from '../../config/FeatureFlags/FeaturesEnum';
import EnvVariables from '../../config/EnvVariables/EnvVariables';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

const reduceFeatureFlags = (
  featureFlags: FeatureFlagFragment[]
): FeatureFlagsConfig => featureFlags.reduce<FeatureFlagsConfig>((acc, flag) => {
    const featureFlagKeyTyped = flag.feature.name as FeaturesEnum;
    acc[featureFlagKeyTyped] = flag.state === FeatureFlagState.Enabled;

    return acc;
  }, {} as FeatureFlagsConfig);

const init = async (
  client: ApolloClient<object>
): Promise<FeatureFlagsConfig | void> => {
  if (GlobalClientConfig.storeOnly) {
    const responseEnvVariable =
      await client.query<GetApplicationConfigByNameQuery>({
        query: GetApplicationConfigByNameDocument,
        variables: {
          applicationConfigName: 'storeOnly',
        },
      });

    if (responseEnvVariable.data.applicationConfigByName) {
      return reduceFeatureFlags(
        responseEnvVariable.data.applicationConfigByName.featureFlags
      );
    }
  }

  if (EnvVariables.applicationConfigName) {
    const responseEnvVariable =
      await client.query<GetApplicationConfigByNameQuery>({
        query: GetApplicationConfigByNameDocument,
        variables: {
          applicationConfigName: EnvVariables.applicationConfigName,
        },
      });

    if (responseEnvVariable.data.applicationConfigByName) {
      return reduceFeatureFlags(
        responseEnvVariable.data.applicationConfigByName.featureFlags
      );
    }
  }

  const responseClientName =
    await client.query<GetApplicationConfigByNameQuery>({
      query: GetApplicationConfigByNameDocument,
      variables: {
        applicationConfigName: import.meta.env.VITE_APP_CLIENT_NAME,
      },
    });

  if (responseClientName.data.applicationConfigByName) {
    return reduceFeatureFlags(
      responseClientName.data.applicationConfigByName.featureFlags
    );
  }

  return FeatureFlags;
};

const FeatureFlagConfigService = {
  init,
};

export default FeatureFlagConfigService;
