import useGetNotificationsQuery from './useGetNotificationsQuery';
import { sortUnexpiredNotifications } from '../components/Notifications/NotificationHelpers';
import {
  GetNotificationsDocument,
  useMarkNotificationsAsReadMutation,
} from '../generated/graphql';
import { CONTEXT_OPTIONS } from '../config/Client/createHttpLink';

interface IProps {
  userId: string;
}

const NOTIFICATIONS_READ_DELAY = 3000;

const useNotificationsToggle = (props: IProps) => {
  const notificationsQuery = useGetNotificationsQuery(props.userId);
  const notifications = notificationsQuery.data?.getNotifications ?? [];
  const unexpiredNotifications = sortUnexpiredNotifications(notifications);
  const unreadNotifications = unexpiredNotifications.filter(
    (notification) => !notification.read
  );

  // Purposefully not handling errors.
  // If the markNotificationsAsRead mutation fails, the notifications will simply
  // stay marked as unread.
  const [markNotificationsAsReadMutation] = useMarkNotificationsAsReadMutation({
    refetchQueries: [
      {
        query: GetNotificationsDocument,
        variables: {
          id: props.userId,
        },
        context: { client: CONTEXT_OPTIONS.notificationClient },
      },
    ],
    context: { client: CONTEXT_OPTIONS.notificationClient },
    awaitRefetchQueries: true,
  });

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      if (unreadNotifications.length) {
        markNotificationsAsReadMutation({
          variables: {
            accountId: props.userId,
            notificationIds: unreadNotifications.map(
              (unreadNotification) => unreadNotification.id
            ),
          },
        });
      }
    }, NOTIFICATIONS_READ_DELAY);
  };

  return {
    unreadNotifications,
    markNotificationsAsRead,
  };
};

export default useNotificationsToggle;
