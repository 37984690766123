import {
  GetTeamProfileDocument,
  GetTeamProfileQuery,
  GetTeamProfileQueryVariables,
} from '../generated/graphql';
import Routes from '../config/Routes/Routes';
import { ApolloClient } from '@apollo/client';
import { TeamAdminParam } from '../pages/ManageTeam/ManageTeam';
import { isTypename } from './TSUtilities';
import { canManageTeam } from './Roles';

export const isAllowedToManageTeam = (
  client: ApolloClient<any>,
  params: TeamAdminParam,
  accountId?: string
) => {
  if (!accountId) {
    return Promise.resolve(Routes.signIn);
  }

  return client
    .query<GetTeamProfileQuery, GetTeamProfileQueryVariables>({
      query: GetTeamProfileDocument,
      variables: {
        id: params.id,
      },
    })
    .then((res) => {
      const team = res.data.teamById;

      return team && isTypename('Team', team) && canManageTeam(team, accountId)
        ? Promise.resolve(null)
        : Promise.reject();
    })
    .catch(() => Promise.resolve(Routes.myTeams));
};
