import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

export interface Props {
  title: string;
  show: boolean;
  onHide: () => void;
  confirmAction: () => void;
  confirmText?: string;
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
}

const ConfirmationModal = ({
  title,
  show,
  onHide,
  confirmAction,
  confirmText,
  children,
  loading,
}: Props) => {
  // purposefully no namespace
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <Button
          variant='outline-primary'
          onClick={onHide}
          disabled={loading}
          semantic={'confirmation-modal-cancel'}
        >
          Cancel
        </Button>
        <Button
          semantic={'confirmation-modal-confirm'}
          variant='primary'
          data-testid={'confirmation-modal-confirm-button'}
          data-cy={'confirmationModalConfirm'}
          onClick={confirmAction}
          loading={loading}
        >
          {confirmText ?? t('confirmation-modal.confirm.label')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
