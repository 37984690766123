import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  GetMinimalUserDocument,
  GetMinimalUserQuery,
  GetMinimalUserQueryVariables,
} from '../generated/graphql';
import { getFromlocalStorage } from '../utilities/Utilities';
import { TokenResponse } from '../pages/Authentication/AuthenticationService';
import { StorageKey } from '../config/Enums/App';
import { isTypename } from '../utilities/TSUtilities';
import { usePostHog } from 'posthog-js/react';

/**
 * Watches the apollo client cache for changes to GetMinimalUserQuery and calls
 * and updates the Logger with changes in the user data. This is especially
 * nice if as part of their session they change their display name
 * or anything else sent to logrocket as part of the session identification.
 */
const useLogger = () => {
  const client = useApolloClient();
  const token = getFromlocalStorage<TokenResponse>(StorageKey.AuthToken);
  const accountId = token?.accountId;
  const postHog = usePostHog()

  useEffect(() => {
    if (accountId) {
      client
        .watchQuery<GetMinimalUserQuery, GetMinimalUserQueryVariables>({
          query: GetMinimalUserDocument,
          variables: { id: accountId },
        })
        .subscribe((response) => {
          if (response.data.user && isTypename('Person', response.data.user)) {
            if (
              response.data.user.email &&
              isTypename('StringAttribute', response.data.user.email)
            ) {
              postHog.identify(response.data.user.id, { email: response.data.user.email.value });
            }
            if (response.data.user.displayName) {
              postHog.identify(response.data.user.id, { username: response.data.user.displayName });
            }
          }
        });
    }
  }, [accountId, client, postHog]);
};

export default useLogger;
