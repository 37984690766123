import {
  Toast as BootstrapToast,
  ToastContainer as BoostrapToastContainer,
} from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';
import { Link } from 'react-router-dom';
import { useToasts } from './ToastContext';

export interface IToastProps {
  heading: string;
  body: string | JSX.Element;
  id: number;
  type: Variant;
  navigation?: {
    label: string;
    url: string;
  };
}

// when running tests, we sometimes want to wait until a notification
// is removed from the dom before triggering a subsequent action.
// A long autoClose will cause the test to timeout.
const AUTO_CLOSE = import.meta.env.MODE === 'test' ? 500 : 5000;

export const Toast = ({ heading, body, type, id, navigation }: IToastProps) => {
  const { removeToast } = useToasts();

  return (
    <BootstrapToast
      autohide={true}
      delay={AUTO_CLOSE}
      bg={type}
      onClose={() => {
        removeToast(id);
      }}
    >
      <BootstrapToast.Header>
        <strong className='me-auto'>{heading}</strong>
      </BootstrapToast.Header>
      <BootstrapToast.Body>
        {body}
        {navigation && (
          <div className={'d-flex w-100'}>
            <br />
            <Link
              to={navigation.url}
              className={'btn btn-outline-primary ms-auto'}
              data-semantic={'toast-navigation'}
            >
              {navigation.label}
            </Link>
          </div>
        )}
      </BootstrapToast.Body>
    </BootstrapToast>
  );
};

export const ToastContainer = () => {
  const { state } = useToasts();
  return (
    <BoostrapToastContainer
      position={'top-end'}
      className={state.toasts.length ? 'm-3' : ''}
      containerPosition={'fixed'}
      style={{ zIndex: 2147483646 }}
    >
      {state.toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </BoostrapToastContainer>
  );
};
